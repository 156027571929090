/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
 .col-form-label,
 ol ol,
 ol ul,
 ul ol,
 ul ul {
   margin-bottom: 0
 }
 
 dl,
 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 ol,
 p,
 pre,
 ul {
   margin-top: 0
 }
 
 address,
 dl,
 ol,
 p,
 pre,
 ul {
   margin-bottom: 1rem
 }
 
 img,
 svg {
   vertical-align: middle
 }
 
 body,
 caption {
   text-align: left;
   color: #000;
 }
 
 dd,
 label {
   margin-bottom: .5rem
 }
 
 button,
 hr,
 input {
   overflow: visible
 }
 
 pre,
 textarea {
   overflow: auto
 }
 
 address,
 legend {
   line-height: inherit
 }
 
 article,
 aside,
 figcaption,
 figure,
 footer,
 header,
 hgroup,
 legend,
 main,
 nav,
 section {
   display: block
 }
 
 a:not([href]):not([tabindex]),
 a:not([href]):not([tabindex]):focus,
 a:not([href]):not([tabindex]):hover,
 legend {
   color: inherit
 }
 
 progress,
 sub,
 sup {
   vertical-align: baseline
 }
 
 label,
 output {
   display: inline-block
 }
 
 [type=button]:not(:disabled),
 [type=reset]:not(:disabled),
 [type=submit]:not(:disabled),
 button:not(:disabled),
 summary {
   cursor: pointer
 }
 
 .dropdown-menu,
 .nav,
 .navbar-nav {
   list-style: none
 }
 
 :root {
   --blue: #28afd0;
   --indigo: #6610f2;
   --purple: #6f42c1;
   --pink: #e83e8c;
   --red: #fa626b;
   --orange: #fdb901;
   --yellow: #ffc107;
   --green: #5ed84f;
   --teal: #20c997;
   --cyan: #6967ce;
   --white: #fff;
   --gray: #6b6f80;
   --gray-dark: #464855;
   --primary: #6967ce;
   --secondary: #6b6f80;
   --success: #5ed84f;
   --info: #28afd0;
   --warning: #fdb901;
   --danger: #fa626b;
   --light: #babfc7;
   --dark: #464855;
   --breakpoint-xs: 0;
   --breakpoint-sm: 576px;
   --breakpoint-md: 768px;
   --breakpoint-lg: 992px;
   --breakpoint-xl: 1200px;
   --font-family-sans-serif: 'Muli', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
   --font-family-monospace: 'Comfortaa', cursive, 'Times New Roman', Times, serif
 }
 
 *,
 ::after,
 ::before {
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box
 }
 
 html {
   font-family: sans-serif;
   line-height: 1.15;
   -webkit-text-size-adjust: 100%;
   -webkit-tap-highlight-color: transparent
 }
 
 body {
   font-family: Muli, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.45;
   margin: 0;
   background-color: #f9fafd
 }
 
 [tabindex='-1']:focus {
   outline: 0 !important
 }
 
 hr {
   -webkit-box-sizing: content-box;
   -moz-box-sizing: content-box;
   box-sizing: content-box;
   height: 0
 }
 
 .img-fluid,
 .img-thumbnail {
   max-width: 100%;
   height: auto
 }
 
 abbr[data-original-title],
 abbr[title] {
   cursor: help;
   text-decoration: underline;
   text-decoration: underline dotted;
   border-bottom: 0;
   -webkit-text-decoration: underline dotted;
   -moz-text-decoration: underline dotted;
   -webkit-text-decoration-skip-ink: none;
   text-decoration-skip-ink: none
 }
 
 .breadcrumb-item+.breadcrumb-item:hover::before,
 .btn-link,
 .btn-link.focus,
 .btn-link:focus,
 .btn-link:hover,
 .btn:hover,
 .card-link:hover,
 .dropdown-item:focus,
 .dropdown-item:hover,
 .list-group-item-action:focus,
 .list-group-item-action:hover,
 .nav-link:focus,
 .nav-link:hover,
 .navbar-brand:focus,
 .navbar-brand:hover,
 .navbar-toggler:focus,
 .navbar-toggler:hover,
 .page-link:hover,
 a,
 a.badge:focus,
 a.badge:hover,
 a:hover,
 a:not([href]):not([tabindex]),
 a:not([href]):not([tabindex]):focus,
 a:not([href]):not([tabindex]):hover {
   text-decoration: none
 }
 
 address {
   font-style: normal
 }
 
 dt {
   font-weight: 700
 }
 
 dd {
   margin-left: 0
 }
 
 blockquote,
 figure {
   margin: 0 0 1rem
 }
 
 b,
 strong {
   font-weight: bolder
 }
 
 small {
   font-size: 80%
 }
 
 sub,
 sup {
   font-size: 75%;
   line-height: 0;
   position: relative
 }
 
 sub {
   bottom: -.25em
 }
 
 sup {
   top: -.5em
 }
 
 a {
   color: #5654c8;
   background-color: transparent
 }
 
 a:hover {
   color: #4340c2
 }
 
 a:not([href]):not([tabindex]):focus {
   outline: 0
 }
 
 code,
 kbd,
 pre,
 samp {
   font-family: Comfortaa, cursive, 'Times New Roman', Times, serif;
   font-size: 1em
 }
 
 img {
   border-style: none
 }
 
 svg {
   overflow: hidden
 }
 
 table {
   border-collapse: collapse
 }
 
 caption {
   padding-top: .75rem;
   padding-bottom: .75rem;
   caption-side: bottom
 }
 
 th {
   text-align: inherit
 }
 
 button {
   border-radius: 0
 }
 
 button:focus {
   outline: dotted 1px;
   outline: -webkit-focus-ring-color auto 5px
 }
 
 button,
 input,
 optgroup,
 select,
 textarea {
   font-family: inherit;
   font-size: inherit;
   line-height: inherit;
   margin: 0
 }
 
 button,
 select {
   text-transform: none
 }
 
 select {
   word-wrap: normal
 }
 
 [type=button],
 [type=reset],
 [type=submit],
 button {
   -webkit-appearance: button
 }
 
 [type=button]::-moz-focus-inner,
 [type=reset]::-moz-focus-inner,
 [type=submit]::-moz-focus-inner,
 button::-moz-focus-inner {
   padding: 0;
   border-style: none
 }
 
 input[type=checkbox],
 input[type=radio] {
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
   padding: 0
 }
 
 input[type=date],
 input[type=time],
 input[type=datetime-local],
 input[type=month] {
   -webkit-appearance: listbox
 }
 
 textarea {
   resize: vertical
 }
 
 fieldset {
   min-width: 0;
   margin: 0;
   padding: 0;
   border: 0
 }
 
 legend {
   font-size: 1.5rem;
   width: 100%;
   max-width: 100%;
   margin-bottom: .5rem;
   padding: 0;
   white-space: normal
 }
 
 .badge,
 .dropdown-header,
 .dropdown-item,
 .dropdown-toggle,
 .input-group-text,
 .navbar-brand,
 .progress-bar {
   white-space: nowrap
 }
 
 [type=number]::-webkit-inner-spin-button,
 [type=number]::-webkit-outer-spin-button {
   height: auto
 }
 
 [type=search] {
   outline-offset: -2px;
   -webkit-appearance: none
 }
 
 [type=search]::-webkit-search-decoration {
   -webkit-appearance: none
 }
 
 ::-webkit-file-upload-button {
   font: inherit;
   -webkit-appearance: button
 }
 
 .display-1,
 .display-2,
 .display-3,
 .display-4 {
   line-height: 1.2
 }
 
 summary {
   display: list-item
 }
 
 template {
   display: none
 }
 
 [hidden] {
   display: none !important
 }
 
 .h1,
 .h2,
 .h3,
 .h4,
 .h5,
 .h6,
 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
   font-family: sans-serif, cursive, 'Times New Roman', Times, serif;
   font-weight: 400;
   line-height: 1.2;
   margin-bottom: .5rem;
   color: #464855
 }
 
 .blockquote,
 hr {
   margin-bottom: 1rem
 }
 
 .h1,
 h1 {
   font-size: 2rem
 }
 
 .h2,
 h2 {
   font-size: 1.74rem
 }
 
 .h3,
 h3 {
   font-size: 1.51rem
 }
 
 .h4,
 h4 {
   font-size: 1.32rem
 }
 
 .h5,
 h5 {
   font-size: 1.14rem
 }
 
 .h6,
 h6 {
   font-size: 1rem
 }
 
 .lead {
   font-size: 1.25rem;
   font-weight: 300
 }
 
 .display-1,
 .display-2,
 .display-3,
 .display-4,
 .small,
 small {
   font-weight: 400
 }
 
 .display-1 {
   font-size: 6rem
 }
 
 .display-2 {
   font-size: 5.5rem
 }
 
 .display-3 {
   font-size: 4.5rem
 }
 
 .display-4 {
   font-size: 3.5rem
 }
 
 hr {
   margin-top: 1rem;
   border: 0;
   border-top: 1px solid rgba(0, 0, 0, .1)
 }
 
 .small,
 small {
   font-size: 80%
 }
 
 .mark,
 mark {
   padding: .2em;
   background-color: #fcf8e3
 }
 
 .list-inline,
 .list-unstyled {
   padding-left: 0;
   list-style: none
 }
 
 .list-inline-item {
   display: inline-block
 }
 
 .list-inline-item:not(:last-child) {
   margin-right: .5rem
 }
 
 .initialism {
   font-size: 90%;
   text-transform: uppercase
 }
 
 .blockquote {
   font-size: 1.25rem
 }
 
 .blockquote-footer {
   font-size: 80%;
   display: block;
   color: #6b6f80
 }
 
 .blockquote-footer::before {
   content: '\2014\00A0'
 }
 
 .img-thumbnail {
   padding: .25rem;
   border: 1px solid #626e82;
   border-radius: .25rem;
   background-color: #f9fafd
 }
 
 .figure {
   display: inline-block
 }
 
 .figure-img {
   line-height: 1;
   margin-bottom: .5rem
 }
 
 .figure-caption {
   font-size: 90%;
   color: #6b6f80
 }
 
 code,
 kbd {
   font-size: 87.5%
 }
 
 a>code,
 pre code {
   color: inherit
 }
 
 code {
   word-break: break-word;
   color: #f74b5c
 }
 
 kbd {
   padding: .2rem .4rem;
   color: #fff;
   border-radius: .21rem;
   background-color: #f2f3f5
 }
 
 kbd kbd {
   font-size: 100%;
   font-weight: 700;
   padding: 0
 }
 
 .btn,
 .btn-link,
 .custom-select,
 .dropdown-item,
 .form-control,
 .input-group-text {
   font-weight: 400
 }
 
 .container,
 .container-fluid {
   margin-right: auto;
   margin-left: auto;
   padding-right: 15px;
   padding-left: 15px;
   width: 100%
 }
 
 pre {
   font-size: 87.5%;
   display: block;
   color: #6b6f80
 }
 
 pre code {
   font-size: inherit;
   word-break: normal
 }
 
 .table,
 .table-hover tbody tr:hover {
   color: #6b6f80
 }
 
 .pre-scrollable {
   overflow-y: scroll;
   max-height: 340px
 }
 
 @media (min-width:576px) {
   .container {
     max-width: 540px
   }
 }
 
 @media (min-width:768px) {
   .container {
     max-width: 720px
   }
 }
 
 @media (min-width:992px) {
   .container {
     max-width: 960px
   }
 }
 
 @media (min-width:1200px) {
   .container {
     max-width: 1140px
   }
 }
 
 .col,
 .col-auto {
   max-width: 100%
 }
 
 .row {
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: -ms-flexbox;
   display: flex;
   margin-right: -15px;
   margin-left: -15px;
   -webkit-flex-wrap: wrap;
   -ms-flex-wrap: wrap;
   flex-wrap: wrap
 }
 
 .no-gutters {
   margin-right: 0;
   margin-left: 0
 }
 
 .no-gutters>.col,
 .no-gutters>[class*=col-] {
   padding-right: 0;
   padding-left: 0
 }
 
 .col,
 .col-1,
 .col-10,
 .col-11,
 .col-12,
 .col-2,
 .col-3,
 .col-4,
 .col-5,
 .col-6,
 .col-7,
 .col-8,
 .col-9,
 .col-auto,
 .col-lg,
 .col-lg-1,
 .col-lg-10,
 .col-lg-11,
 .col-lg-12,
 .col-lg-2,
 .col-lg-3,
 .col-lg-4,
 .col-lg-5,
 .col-lg-6,
 .col-lg-7,
 .col-lg-8,
 .col-lg-9,
 .col-lg-auto,
 .col-md,
 .col-md-1,
 .col-md-10,
 .col-md-11,
 .col-md-12,
 .col-md-2,
 .col-md-3,
 .col-md-4,
 .col-md-5,
 .col-md-6,
 .col-md-7,
 .col-md-8,
 .col-md-9,
 .col-md-auto,
 .col-sm,
 .col-sm-1,
 .col-sm-10,
 .col-sm-11,
 .col-sm-12,
 .col-sm-2,
 .col-sm-3,
 .col-sm-4,
 .col-sm-5,
 .col-sm-6,
 .col-sm-7,
 .col-sm-8,
 .col-sm-9,
 .col-sm-auto,
 .col-xl,
 .col-xl-1,
 .col-xl-10,
 .col-xl-11,
 .col-xl-12,
 .col-xl-2,
 .col-xl-3,
 .col-xl-4,
 .col-xl-5,
 .col-xl-6,
 .col-xl-7,
 .col-xl-8,
 .col-xl-9,
 .col-xl-auto {
   position: relative;
   width: 100%;
   padding-right: 15px;
   padding-left: 15px
 }
 
 .col {
   -webkit-flex-basis: 0;
   -ms-flex-preferred-size: 0;
   flex-basis: 0;
   -webkit-box-flex: 1;
   -webkit-flex-grow: 1;
   -moz-box-flex: 1;
   -ms-flex-positive: 1;
   flex-grow: 1
 }
 
 .col-1,
 .col-auto {
   -webkit-box-flex: 0
 }
 
 .col-auto {
   width: auto;
   -webkit-flex: 0 0 auto;
   -moz-box-flex: 0;
   -ms-flex: 0 0 auto;
   flex: 0 0 auto
 }
 
 .col-1 {
   max-width: 8.33333%;
   -webkit-flex: 0 0 8.33333%;
   -moz-box-flex: 0;
   -ms-flex: 0 0 8.33333%;
   flex: 0 0 8.33333%
 }
 
 .col-2,
 .col-3 {
   -webkit-box-flex: 0
 }
 
 .col-2 {
   max-width: 16.66667%;
   -webkit-flex: 0 0 16.66667%;
   -moz-box-flex: 0;
   -ms-flex: 0 0 16.66667%;
   flex: 0 0 16.66667%
 }
 
 .col-3 {
   max-width: 25%;
   -webkit-flex: 0 0 25%;
   -moz-box-flex: 0;
   -ms-flex: 0 0 25%;
   flex: 0 0 25%
 }
 
 .col-4,
 .col-5 {
   -webkit-box-flex: 0
 }
 
 .col-4 {
   max-width: 33.33333%;
   -webkit-flex: 0 0 33.33333%;
   -moz-box-flex: 0;
   -ms-flex: 0 0 33.33333%;
   flex: 0 0 33.33333%
 }
 
 .col-5 {
   max-width: 41.66667%;
   -webkit-flex: 0 0 41.66667%;
   -moz-box-flex: 0;
   -ms-flex: 0 0 41.66667%;
   flex: 0 0 41.66667%
 }
 
 .col-6,
 .col-7 {
   -webkit-box-flex: 0
 }
 
 .col-6 {
   max-width: 50%;
   -webkit-flex: 0 0 50%;
   -moz-box-flex: 0;
   -ms-flex: 0 0 50%;
   flex: 0 0 50%
 }
 
 .col-7 {
   max-width: 58.33333%;
   -webkit-flex: 0 0 58.33333%;
   -moz-box-flex: 0;
   -ms-flex: 0 0 58.33333%;
   flex: 0 0 58.33333%
 }
 
 .col-8,
 .col-9 {
   -webkit-box-flex: 0
 }
 
 .col-8 {
   max-width: 66.66667%;
   -webkit-flex: 0 0 66.66667%;
   -moz-box-flex: 0;
   -ms-flex: 0 0 66.66667%;
   flex: 0 0 66.66667%
 }
 
 .col-9 {
   max-width: 75%;
   -webkit-flex: 0 0 75%;
   -moz-box-flex: 0;
   -ms-flex: 0 0 75%;
   flex: 0 0 75%
 }
 
 .col-10,
 .col-11 {
   -webkit-box-flex: 0
 }
 
 .col-10 {
   max-width: 83.33333%;
   -webkit-flex: 0 0 83.33333%;
   -moz-box-flex: 0;
   -ms-flex: 0 0 83.33333%;
   flex: 0 0 83.33333%
 }
 
 .col-11 {
   max-width: 91.66667%;
   -webkit-flex: 0 0 91.66667%;
   -moz-box-flex: 0;
   -ms-flex: 0 0 91.66667%;
   flex: 0 0 91.66667%
 }
 
 .col-12 {
   max-width: 100%;
   -webkit-box-flex: 0;
   -webkit-flex: 0 0 100%;
   -moz-box-flex: 0;
   -ms-flex: 0 0 100%;
   flex: 0 0 100%
 }
 
 .order-first {
   -webkit-box-ordinal-group: 0;
   -webkit-order: -1;
   -moz-box-ordinal-group: 0;
   -ms-flex-order: -1;
   order: -1
 }
 
 .order-last {
   -webkit-box-ordinal-group: 14;
   -webkit-order: 13;
   -moz-box-ordinal-group: 14;
   -ms-flex-order: 13;
   order: 13
 }
 
 .order-0 {
   -webkit-box-ordinal-group: 1;
   -webkit-order: 0;
   -moz-box-ordinal-group: 1;
   -ms-flex-order: 0;
   order: 0
 }
 
 .order-1 {
   -webkit-box-ordinal-group: 2;
   -webkit-order: 1;
   -moz-box-ordinal-group: 2;
   -ms-flex-order: 1;
   order: 1
 }
 
 .order-2 {
   -webkit-box-ordinal-group: 3;
   -webkit-order: 2;
   -moz-box-ordinal-group: 3;
   -ms-flex-order: 2;
   order: 2
 }
 
 .order-3 {
   -webkit-box-ordinal-group: 4;
   -webkit-order: 3;
   -moz-box-ordinal-group: 4;
   -ms-flex-order: 3;
   order: 3
 }
 
 .order-4 {
   -webkit-box-ordinal-group: 5;
   -webkit-order: 4;
   -moz-box-ordinal-group: 5;
   -ms-flex-order: 4;
   order: 4
 }
 
 .order-5 {
   -webkit-box-ordinal-group: 6;
   -webkit-order: 5;
   -moz-box-ordinal-group: 6;
   -ms-flex-order: 5;
   order: 5
 }
 
 .order-6 {
   -webkit-box-ordinal-group: 7;
   -webkit-order: 6;
   -moz-box-ordinal-group: 7;
   -ms-flex-order: 6;
   order: 6
 }
 
 .order-7 {
   -webkit-box-ordinal-group: 8;
   -webkit-order: 7;
   -moz-box-ordinal-group: 8;
   -ms-flex-order: 7;
   order: 7
 }
 
 .order-8 {
   -webkit-box-ordinal-group: 9;
   -webkit-order: 8;
   -moz-box-ordinal-group: 9;
   -ms-flex-order: 8;
   order: 8
 }
 
 .order-9 {
   -webkit-box-ordinal-group: 10;
   -webkit-order: 9;
   -moz-box-ordinal-group: 10;
   -ms-flex-order: 9;
   order: 9
 }
 
 .order-10 {
   -webkit-box-ordinal-group: 11;
   -webkit-order: 10;
   -moz-box-ordinal-group: 11;
   -ms-flex-order: 10;
   order: 10
 }
 
 .order-11 {
   -webkit-box-ordinal-group: 12;
   -webkit-order: 11;
   -moz-box-ordinal-group: 12;
   -ms-flex-order: 11;
   order: 11
 }
 
 .order-12 {
   -webkit-box-ordinal-group: 13;
   -webkit-order: 12;
   -moz-box-ordinal-group: 13;
   -ms-flex-order: 12;
   order: 12
 }
 
 .offset-1 {
   margin-left: 8.33333%
 }
 
 .offset-2 {
   margin-left: 16.66667%
 }
 
 .offset-3 {
   margin-left: 25%
 }
 
 .offset-4 {
   margin-left: 33.33333%
 }
 
 .offset-5 {
   margin-left: 41.66667%
 }
 
 .offset-6 {
   margin-left: 50%
 }
 
 .offset-7 {
   margin-left: 58.33333%
 }
 
 .offset-8 {
   margin-left: 66.66667%
 }
 
 .offset-9 {
   margin-left: 75%
 }
 
 .offset-10 {
   margin-left: 83.33333%
 }
 
 .offset-11 {
   margin-left: 91.66667%
 }
 
 @media (min-width:576px) {
   .col-sm {
     max-width: 100%;
     -webkit-flex-basis: 0;
     -ms-flex-preferred-size: 0;
     flex-basis: 0;
     -webkit-box-flex: 1;
     -webkit-flex-grow: 1;
     -moz-box-flex: 1;
     -ms-flex-positive: 1;
     flex-grow: 1
   }
 
   .col-sm-auto {
     width: auto;
     max-width: 100%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 auto;
     -moz-box-flex: 0;
     -ms-flex: 0 0 auto;
     flex: 0 0 auto
   }
 
   .col-sm-1 {
     max-width: 8.33333%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 8.33333%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 8.33333%;
     flex: 0 0 8.33333%
   }
 
   .col-sm-2 {
     max-width: 16.66667%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 16.66667%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 16.66667%;
     flex: 0 0 16.66667%
   }
 
   .col-sm-3 {
     max-width: 25%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 25%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 25%;
     flex: 0 0 25%
   }
 
   .col-sm-4 {
     max-width: 33.33333%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 33.33333%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 33.33333%;
     flex: 0 0 33.33333%
   }
 
   .col-sm-5 {
     max-width: 41.66667%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 41.66667%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 41.66667%;
     flex: 0 0 41.66667%
   }
 
   .col-sm-6 {
     max-width: 50%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 50%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 50%;
     flex: 0 0 50%
   }
 
   .col-sm-7 {
     max-width: 58.33333%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 58.33333%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 58.33333%;
     flex: 0 0 58.33333%
   }
 
   .col-sm-8 {
     max-width: 66.66667%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 66.66667%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 66.66667%;
     flex: 0 0 66.66667%
   }
 
   .col-sm-9 {
     max-width: 75%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 75%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 75%;
     flex: 0 0 75%
   }
 
   .col-sm-10 {
     max-width: 83.33333%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 83.33333%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 83.33333%;
     flex: 0 0 83.33333%
   }
 
   .col-sm-11 {
     max-width: 91.66667%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 91.66667%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 91.66667%;
     flex: 0 0 91.66667%
   }
 
   .col-sm-12 {
     max-width: 100%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 100%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 100%;
     flex: 0 0 100%
   }
 
   .order-sm-first {
     -webkit-box-ordinal-group: 0;
     -webkit-order: -1;
     -moz-box-ordinal-group: 0;
     -ms-flex-order: -1;
     order: -1
   }
 
   .order-sm-last {
     -webkit-box-ordinal-group: 14;
     -webkit-order: 13;
     -moz-box-ordinal-group: 14;
     -ms-flex-order: 13;
     order: 13
   }
 
   .order-sm-0 {
     -webkit-box-ordinal-group: 1;
     -webkit-order: 0;
     -moz-box-ordinal-group: 1;
     -ms-flex-order: 0;
     order: 0
   }
 
   .order-sm-1 {
     -webkit-box-ordinal-group: 2;
     -webkit-order: 1;
     -moz-box-ordinal-group: 2;
     -ms-flex-order: 1;
     order: 1
   }
 
   .order-sm-2 {
     -webkit-box-ordinal-group: 3;
     -webkit-order: 2;
     -moz-box-ordinal-group: 3;
     -ms-flex-order: 2;
     order: 2
   }
 
   .order-sm-3 {
     -webkit-box-ordinal-group: 4;
     -webkit-order: 3;
     -moz-box-ordinal-group: 4;
     -ms-flex-order: 3;
     order: 3
   }
 
   .order-sm-4 {
     -webkit-box-ordinal-group: 5;
     -webkit-order: 4;
     -moz-box-ordinal-group: 5;
     -ms-flex-order: 4;
     order: 4
   }
 
   .order-sm-5 {
     -webkit-box-ordinal-group: 6;
     -webkit-order: 5;
     -moz-box-ordinal-group: 6;
     -ms-flex-order: 5;
     order: 5
   }
 
   .order-sm-6 {
     -webkit-box-ordinal-group: 7;
     -webkit-order: 6;
     -moz-box-ordinal-group: 7;
     -ms-flex-order: 6;
     order: 6
   }
 
   .order-sm-7 {
     -webkit-box-ordinal-group: 8;
     -webkit-order: 7;
     -moz-box-ordinal-group: 8;
     -ms-flex-order: 7;
     order: 7
   }
 
   .order-sm-8 {
     -webkit-box-ordinal-group: 9;
     -webkit-order: 8;
     -moz-box-ordinal-group: 9;
     -ms-flex-order: 8;
     order: 8
   }
 
   .order-sm-9 {
     -webkit-box-ordinal-group: 10;
     -webkit-order: 9;
     -moz-box-ordinal-group: 10;
     -ms-flex-order: 9;
     order: 9
   }
 
   .order-sm-10 {
     -webkit-box-ordinal-group: 11;
     -webkit-order: 10;
     -moz-box-ordinal-group: 11;
     -ms-flex-order: 10;
     order: 10
   }
 
   .order-sm-11 {
     -webkit-box-ordinal-group: 12;
     -webkit-order: 11;
     -moz-box-ordinal-group: 12;
     -ms-flex-order: 11;
     order: 11
   }
 
   .order-sm-12 {
     -webkit-box-ordinal-group: 13;
     -webkit-order: 12;
     -moz-box-ordinal-group: 13;
     -ms-flex-order: 12;
     order: 12
   }
 
   .offset-sm-0 {
     margin-left: 0
   }
 
   .offset-sm-1 {
     margin-left: 8.33333%
   }
 
   .offset-sm-2 {
     margin-left: 16.66667%
   }
 
   .offset-sm-3 {
     margin-left: 25%
   }
 
   .offset-sm-4 {
     margin-left: 33.33333%
   }
 
   .offset-sm-5 {
     margin-left: 41.66667%
   }
 
   .offset-sm-6 {
     margin-left: 50%
   }
 
   .offset-sm-7 {
     margin-left: 58.33333%
   }
 
   .offset-sm-8 {
     margin-left: 66.66667%
   }
 
   .offset-sm-9 {
     margin-left: 75%
   }
 
   .offset-sm-10 {
     margin-left: 83.33333%
   }
 
   .offset-sm-11 {
     margin-left: 91.66667%
   }
 }
 
 @media (min-width:768px) {
   .col-md {
     max-width: 100%;
     -webkit-flex-basis: 0;
     -ms-flex-preferred-size: 0;
     flex-basis: 0;
     -webkit-box-flex: 1;
     -webkit-flex-grow: 1;
     -moz-box-flex: 1;
     -ms-flex-positive: 1;
     flex-grow: 1
   }
 
   .col-md-auto {
     width: auto;
     max-width: 100%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 auto;
     -moz-box-flex: 0;
     -ms-flex: 0 0 auto;
     flex: 0 0 auto
   }
 
   .col-md-1 {
     max-width: 8.33333%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 8.33333%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 8.33333%;
     flex: 0 0 8.33333%
   }
 
   .col-md-2 {
     max-width: 16.66667%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 16.66667%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 16.66667%;
     flex: 0 0 16.66667%
   }
 
   .col-md-3 {
     max-width: 25%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 25%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 25%;
     flex: 0 0 25%
   }
 
   .col-md-4 {
     max-width: 33.33333%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 33.33333%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 33.33333%;
     flex: 0 0 33.33333%
   }
 
   .col-md-5 {
     max-width: 41.66667%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 41.66667%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 41.66667%;
     flex: 0 0 41.66667%
   }
 
   .col-md-6 {
     max-width: 50%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 50%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 50%;
     flex: 0 0 50%
   }
 
   .col-md-7 {
     max-width: 58.33333%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 58.33333%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 58.33333%;
     flex: 0 0 58.33333%
   }
 
   .col-md-8 {
     max-width: 66.66667%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 66.66667%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 66.66667%;
     flex: 0 0 66.66667%
   }
 
   .col-md-9 {
     max-width: 75%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 75%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 75%;
     flex: 0 0 75%
   }
 
   .col-md-10 {
     max-width: 83.33333%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 83.33333%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 83.33333%;
     flex: 0 0 83.33333%
   }
 
   .col-md-11 {
     max-width: 91.66667%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 91.66667%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 91.66667%;
     flex: 0 0 91.66667%
   }
 
   .col-md-12 {
     max-width: 100%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 100%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 100%;
     flex: 0 0 100%
   }
 
   .order-md-first {
     -webkit-box-ordinal-group: 0;
     -webkit-order: -1;
     -moz-box-ordinal-group: 0;
     -ms-flex-order: -1;
     order: -1
   }
 
   .order-md-last {
     -webkit-box-ordinal-group: 14;
     -webkit-order: 13;
     -moz-box-ordinal-group: 14;
     -ms-flex-order: 13;
     order: 13
   }
 
   .order-md-0 {
     -webkit-box-ordinal-group: 1;
     -webkit-order: 0;
     -moz-box-ordinal-group: 1;
     -ms-flex-order: 0;
     order: 0
   }
 
   .order-md-1 {
     -webkit-box-ordinal-group: 2;
     -webkit-order: 1;
     -moz-box-ordinal-group: 2;
     -ms-flex-order: 1;
     order: 1
   }
 
   .order-md-2 {
     -webkit-box-ordinal-group: 3;
     -webkit-order: 2;
     -moz-box-ordinal-group: 3;
     -ms-flex-order: 2;
     order: 2
   }
 
   .order-md-3 {
     -webkit-box-ordinal-group: 4;
     -webkit-order: 3;
     -moz-box-ordinal-group: 4;
     -ms-flex-order: 3;
     order: 3
   }
 
   .order-md-4 {
     -webkit-box-ordinal-group: 5;
     -webkit-order: 4;
     -moz-box-ordinal-group: 5;
     -ms-flex-order: 4;
     order: 4
   }
 
   .order-md-5 {
     -webkit-box-ordinal-group: 6;
     -webkit-order: 5;
     -moz-box-ordinal-group: 6;
     -ms-flex-order: 5;
     order: 5
   }
 
   .order-md-6 {
     -webkit-box-ordinal-group: 7;
     -webkit-order: 6;
     -moz-box-ordinal-group: 7;
     -ms-flex-order: 6;
     order: 6
   }
 
   .order-md-7 {
     -webkit-box-ordinal-group: 8;
     -webkit-order: 7;
     -moz-box-ordinal-group: 8;
     -ms-flex-order: 7;
     order: 7
   }
 
   .order-md-8 {
     -webkit-box-ordinal-group: 9;
     -webkit-order: 8;
     -moz-box-ordinal-group: 9;
     -ms-flex-order: 8;
     order: 8
   }
 
   .order-md-9 {
     -webkit-box-ordinal-group: 10;
     -webkit-order: 9;
     -moz-box-ordinal-group: 10;
     -ms-flex-order: 9;
     order: 9
   }
 
   .order-md-10 {
     -webkit-box-ordinal-group: 11;
     -webkit-order: 10;
     -moz-box-ordinal-group: 11;
     -ms-flex-order: 10;
     order: 10
   }
 
   .order-md-11 {
     -webkit-box-ordinal-group: 12;
     -webkit-order: 11;
     -moz-box-ordinal-group: 12;
     -ms-flex-order: 11;
     order: 11
   }
 
   .order-md-12 {
     -webkit-box-ordinal-group: 13;
     -webkit-order: 12;
     -moz-box-ordinal-group: 13;
     -ms-flex-order: 12;
     order: 12
   }
 
   .offset-md-0 {
     margin-left: 0
   }
 
   .offset-md-1 {
     margin-left: 8.33333%
   }
 
   .offset-md-2 {
     margin-left: 16.66667%
   }
 
   .offset-md-3 {
     margin-left: 25%
   }
 
   .offset-md-4 {
     margin-left: 33.33333%
   }
 
   .offset-md-5 {
     margin-left: 41.66667%
   }
 
   .offset-md-6 {
     margin-left: 50%
   }
 
   .offset-md-7 {
     margin-left: 58.33333%
   }
 
   .offset-md-8 {
     margin-left: 66.66667%
   }
 
   .offset-md-9 {
     margin-left: 75%
   }
 
   .offset-md-10 {
     margin-left: 83.33333%
   }
 
   .offset-md-11 {
     margin-left: 91.66667%
   }
 }
 
 @media (min-width:992px) {
   .col-lg {
     max-width: 100%;
     -webkit-flex-basis: 0;
     -ms-flex-preferred-size: 0;
     flex-basis: 0;
     -webkit-box-flex: 1;
     -webkit-flex-grow: 1;
     -moz-box-flex: 1;
     -ms-flex-positive: 1;
     flex-grow: 1
   }
 
   .col-lg-auto {
     width: auto;
     max-width: 100%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 auto;
     -moz-box-flex: 0;
     -ms-flex: 0 0 auto;
     flex: 0 0 auto
   }
 
   .col-lg-1 {
     max-width: 8.33333%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 8.33333%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 8.33333%;
     flex: 0 0 8.33333%
   }
 
   .col-lg-2 {
     max-width: 16.66667%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 16.66667%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 16.66667%;
     flex: 0 0 16.66667%
   }
 
   .col-lg-3 {
     max-width: 25%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 25%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 25%;
     flex: 0 0 25%
   }
 
   .col-lg-4 {
     max-width: 33.33333%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 33.33333%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 33.33333%;
     flex: 0 0 33.33333%
   }
 
   .col-lg-5 {
     max-width: 41.66667%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 41.66667%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 41.66667%;
     flex: 0 0 41.66667%
   }
 
   .col-lg-6 {
     max-width: 50%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 50%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 50%;
     flex: 0 0 50%
   }
 
   .col-lg-7 {
     max-width: 58.33333%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 58.33333%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 58.33333%;
     flex: 0 0 58.33333%
   }
 
   .col-lg-8 {
     max-width: 66.66667%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 66.66667%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 66.66667%;
     flex: 0 0 66.66667%
   }
 
   .col-lg-9 {
     max-width: 75%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 75%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 75%;
     flex: 0 0 75%
   }
 
   .col-lg-10 {
     max-width: 83.33333%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 83.33333%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 83.33333%;
     flex: 0 0 83.33333%
   }
 
   .col-lg-11 {
     max-width: 91.66667%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 91.66667%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 91.66667%;
     flex: 0 0 91.66667%
   }
 
   .col-lg-12 {
     max-width: 100%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 100%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 100%;
     flex: 0 0 100%
   }
 
   .order-lg-first {
     -webkit-box-ordinal-group: 0;
     -webkit-order: -1;
     -moz-box-ordinal-group: 0;
     -ms-flex-order: -1;
     order: -1
   }
 
   .order-lg-last {
     -webkit-box-ordinal-group: 14;
     -webkit-order: 13;
     -moz-box-ordinal-group: 14;
     -ms-flex-order: 13;
     order: 13
   }
 
   .order-lg-0 {
     -webkit-box-ordinal-group: 1;
     -webkit-order: 0;
     -moz-box-ordinal-group: 1;
     -ms-flex-order: 0;
     order: 0
   }
 
   .order-lg-1 {
     -webkit-box-ordinal-group: 2;
     -webkit-order: 1;
     -moz-box-ordinal-group: 2;
     -ms-flex-order: 1;
     order: 1
   }
 
   .order-lg-2 {
     -webkit-box-ordinal-group: 3;
     -webkit-order: 2;
     -moz-box-ordinal-group: 3;
     -ms-flex-order: 2;
     order: 2
   }
 
   .order-lg-3 {
     -webkit-box-ordinal-group: 4;
     -webkit-order: 3;
     -moz-box-ordinal-group: 4;
     -ms-flex-order: 3;
     order: 3
   }
 
   .order-lg-4 {
     -webkit-box-ordinal-group: 5;
     -webkit-order: 4;
     -moz-box-ordinal-group: 5;
     -ms-flex-order: 4;
     order: 4
   }
 
   .order-lg-5 {
     -webkit-box-ordinal-group: 6;
     -webkit-order: 5;
     -moz-box-ordinal-group: 6;
     -ms-flex-order: 5;
     order: 5
   }
 
   .order-lg-6 {
     -webkit-box-ordinal-group: 7;
     -webkit-order: 6;
     -moz-box-ordinal-group: 7;
     -ms-flex-order: 6;
     order: 6
   }
 
   .order-lg-7 {
     -webkit-box-ordinal-group: 8;
     -webkit-order: 7;
     -moz-box-ordinal-group: 8;
     -ms-flex-order: 7;
     order: 7
   }
 
   .order-lg-8 {
     -webkit-box-ordinal-group: 9;
     -webkit-order: 8;
     -moz-box-ordinal-group: 9;
     -ms-flex-order: 8;
     order: 8
   }
 
   .order-lg-9 {
     -webkit-box-ordinal-group: 10;
     -webkit-order: 9;
     -moz-box-ordinal-group: 10;
     -ms-flex-order: 9;
     order: 9
   }
 
   .order-lg-10 {
     -webkit-box-ordinal-group: 11;
     -webkit-order: 10;
     -moz-box-ordinal-group: 11;
     -ms-flex-order: 10;
     order: 10
   }
 
   .order-lg-11 {
     -webkit-box-ordinal-group: 12;
     -webkit-order: 11;
     -moz-box-ordinal-group: 12;
     -ms-flex-order: 11;
     order: 11
   }
 
   .order-lg-12 {
     -webkit-box-ordinal-group: 13;
     -webkit-order: 12;
     -moz-box-ordinal-group: 13;
     -ms-flex-order: 12;
     order: 12
   }
 
   .offset-lg-0 {
     margin-left: 0
   }
 
   .offset-lg-1 {
     margin-left: 8.33333%
   }
 
   .offset-lg-2 {
     margin-left: 16.66667%
   }
 
   .offset-lg-3 {
     margin-left: 25%
   }
 
   .offset-lg-4 {
     margin-left: 33.33333%
   }
 
   .offset-lg-5 {
     margin-left: 41.66667%
   }
 
   .offset-lg-6 {
     margin-left: 50%
   }
 
   .offset-lg-7 {
     margin-left: 58.33333%
   }
 
   .offset-lg-8 {
     margin-left: 66.66667%
   }
 
   .offset-lg-9 {
     margin-left: 75%
   }
 
   .offset-lg-10 {
     margin-left: 83.33333%
   }
 
   .offset-lg-11 {
     margin-left: 91.66667%
   }
 }
 
 @media (min-width:1200px) {
   .col-xl {
     max-width: 100%;
     -webkit-flex-basis: 0;
     -ms-flex-preferred-size: 0;
     flex-basis: 0;
     -webkit-box-flex: 1;
     -webkit-flex-grow: 1;
     -moz-box-flex: 1;
     -ms-flex-positive: 1;
     flex-grow: 1
   }
 
   .col-xl-auto {
     width: auto;
     max-width: 100%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 auto;
     -moz-box-flex: 0;
     -ms-flex: 0 0 auto;
     flex: 0 0 auto
   }
 
   .col-xl-1 {
     max-width: 8.33333%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 8.33333%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 8.33333%;
     flex: 0 0 8.33333%
   }
 
   .col-xl-2 {
     max-width: 16.66667%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 16.66667%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 16.66667%;
     flex: 0 0 16.66667%
   }
 
   .col-xl-3 {
     max-width: 25%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 25%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 25%;
     flex: 0 0 25%
   }
 
   .col-xl-4 {
     max-width: 33.33333%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 33.33333%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 33.33333%;
     flex: 0 0 33.33333%
   }
 
   .col-xl-5 {
     max-width: 41.66667%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 41.66667%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 41.66667%;
     flex: 0 0 41.66667%
   }
 
   .col-xl-6 {
     max-width: 50%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 50%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 50%;
     flex: 0 0 50%
   }
 
   .col-xl-7 {
     max-width: 58.33333%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 58.33333%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 58.33333%;
     flex: 0 0 58.33333%
   }
 
   .col-xl-8 {
     max-width: 66.66667%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 66.66667%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 66.66667%;
     flex: 0 0 66.66667%
   }
 
   .col-xl-9 {
     max-width: 75%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 75%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 75%;
     flex: 0 0 75%
   }
 
   .col-xl-10 {
     max-width: 83.33333%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 83.33333%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 83.33333%;
     flex: 0 0 83.33333%
   }
 
   .col-xl-11 {
     max-width: 91.66667%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 91.66667%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 91.66667%;
     flex: 0 0 91.66667%
   }
 
   .col-xl-12 {
     max-width: 100%;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 100%;
     -moz-box-flex: 0;
     -ms-flex: 0 0 100%;
     flex: 0 0 100%
   }
 
   .order-xl-first {
     -webkit-box-ordinal-group: 0;
     -webkit-order: -1;
     -moz-box-ordinal-group: 0;
     -ms-flex-order: -1;
     order: -1
   }
 
   .order-xl-last {
     -webkit-box-ordinal-group: 14;
     -webkit-order: 13;
     -moz-box-ordinal-group: 14;
     -ms-flex-order: 13;
     order: 13
   }
 
   .order-xl-0 {
     -webkit-box-ordinal-group: 1;
     -webkit-order: 0;
     -moz-box-ordinal-group: 1;
     -ms-flex-order: 0;
     order: 0
   }
 
   .order-xl-1 {
     -webkit-box-ordinal-group: 2;
     -webkit-order: 1;
     -moz-box-ordinal-group: 2;
     -ms-flex-order: 1;
     order: 1
   }
 
   .order-xl-2 {
     -webkit-box-ordinal-group: 3;
     -webkit-order: 2;
     -moz-box-ordinal-group: 3;
     -ms-flex-order: 2;
     order: 2
   }
 
   .order-xl-3 {
     -webkit-box-ordinal-group: 4;
     -webkit-order: 3;
     -moz-box-ordinal-group: 4;
     -ms-flex-order: 3;
     order: 3
   }
 
   .order-xl-4 {
     -webkit-box-ordinal-group: 5;
     -webkit-order: 4;
     -moz-box-ordinal-group: 5;
     -ms-flex-order: 4;
     order: 4
   }
 
   .order-xl-5 {
     -webkit-box-ordinal-group: 6;
     -webkit-order: 5;
     -moz-box-ordinal-group: 6;
     -ms-flex-order: 5;
     order: 5
   }
 
   .order-xl-6 {
     -webkit-box-ordinal-group: 7;
     -webkit-order: 6;
     -moz-box-ordinal-group: 7;
     -ms-flex-order: 6;
     order: 6
   }
 
   .order-xl-7 {
     -webkit-box-ordinal-group: 8;
     -webkit-order: 7;
     -moz-box-ordinal-group: 8;
     -ms-flex-order: 7;
     order: 7
   }
 
   .order-xl-8 {
     -webkit-box-ordinal-group: 9;
     -webkit-order: 8;
     -moz-box-ordinal-group: 9;
     -ms-flex-order: 8;
     order: 8
   }
 
   .order-xl-9 {
     -webkit-box-ordinal-group: 10;
     -webkit-order: 9;
     -moz-box-ordinal-group: 10;
     -ms-flex-order: 9;
     order: 9
   }
 
   .order-xl-10 {
     -webkit-box-ordinal-group: 11;
     -webkit-order: 10;
     -moz-box-ordinal-group: 11;
     -ms-flex-order: 10;
     order: 10
   }
 
   .order-xl-11 {
     -webkit-box-ordinal-group: 12;
     -webkit-order: 11;
     -moz-box-ordinal-group: 12;
     -ms-flex-order: 11;
     order: 11
   }
 
   .order-xl-12 {
     -webkit-box-ordinal-group: 13;
     -webkit-order: 12;
     -moz-box-ordinal-group: 13;
     -ms-flex-order: 12;
     order: 12
   }
 
   .offset-xl-0 {
     margin-left: 0
   }
 
   .offset-xl-1 {
     margin-left: 8.33333%
   }
 
   .offset-xl-2 {
     margin-left: 16.66667%
   }
 
   .offset-xl-3 {
     margin-left: 25%
   }
 
   .offset-xl-4 {
     margin-left: 33.33333%
   }
 
   .offset-xl-5 {
     margin-left: 41.66667%
   }
 
   .offset-xl-6 {
     margin-left: 50%
   }
 
   .offset-xl-7 {
     margin-left: 58.33333%
   }
 
   .offset-xl-8 {
     margin-left: 66.66667%
   }
 
   .offset-xl-9 {
     margin-left: 75%
   }
 
   .offset-xl-10 {
     margin-left: 83.33333%
   }
 
   .offset-xl-11 {
     margin-left: 91.66667%
   }
 }
 
 .table {
   width: 100%;
   margin-bottom: 1rem;
   background-color: transparent
 }
 
 .table-hover tbody tr:hover,
 .table-striped tbody tr:nth-of-type(odd) {
   background-color: rgba(245, 247, 250, .5)
 }
 
 .table td,
 .table th {
   padding: .75rem;
   vertical-align: top;
   border-top: 1px solid #626e82
 }
 
 .table thead th {
   vertical-align: bottom;
   border-bottom: 2px solid #626e82
 }
 
 .table tbody+tbody {
   border-top: 2px solid #626e82
 }
 
 .table-sm td,
 .table-sm th {
   padding: .3rem
 }
 
 .table-bordered,
 .table-bordered td,
 .table-bordered th {
   border: 1px solid #626e82
 }
 
 .table-bordered thead td,
 .table-bordered thead th {
   border-bottom-width: 2px
 }
 
 .table-borderless tbody+tbody,
 .table-borderless td,
 .table-borderless th,
 .table-borderless thead th {
   border: 0
 }
 
 .table-primary,
 .table-primary>td,
 .table-primary>th {
   background-color: #d5d4f1
 }
 
 .table-primary tbody+tbody,
 .table-primary td,
 .table-primary th,
 .table-primary thead th {
   border-color: #b1b0e6
 }
 
 .table-hover .table-primary:hover,
 .table-hover .table-primary:hover>td,
 .table-hover .table-primary:hover>th {
   background-color: #c2c1eb
 }
 
 .table-secondary,
 .table-secondary>td,
 .table-secondary>th {
   background-color: #d6d7db
 }
 
 .table-secondary tbody+tbody,
 .table-secondary td,
 .table-secondary th,
 .table-secondary thead th {
   border-color: #b2b4bd
 }
 
 .table-hover .table-secondary:hover,
 .table-hover .table-secondary:hover>td,
 .table-hover .table-secondary:hover>th {
   background-color: #c8cacf
 }
 
 .table-success,
 .table-success>td,
 .table-success>th {
   background-color: #d2f4ce
 }
 
 .table-success tbody+tbody,
 .table-success td,
 .table-success th,
 .table-success thead th {
   border-color: #abeba3
 }
 
 .table-hover .table-success:hover,
 .table-hover .table-success:hover>td,
 .table-hover .table-success:hover>th {
   background-color: #bfefb9
 }
 
 .table-info,
 .table-info>td,
 .table-info>th {
   background-color: #c3e9f2
 }
 
 .table-info tbody+tbody,
 .table-info td,
 .table-info th,
 .table-info thead th {
   border-color: #8fd5e7
 }
 
 .table-hover .table-info:hover,
 .table-hover .table-info:hover>td,
 .table-hover .table-info:hover>th {
   background-color: #aee1ed
 }
 
 .table-warning,
 .table-warning>td,
 .table-warning>th {
   background-color: #feebb8
 }
 
 .table-warning tbody+tbody,
 .table-warning td,
 .table-warning th,
 .table-warning thead th {
   border-color: #fedb7b
 }
 
 .table-hover .table-warning:hover,
 .table-hover .table-warning:hover>td,
 .table-hover .table-warning:hover>th {
   background-color: #fee49f
 }
 
 .table-danger,
 .table-danger>td,
 .table-danger>th {
   background-color: #fed3d6
 }
 
 .table-danger tbody+tbody,
 .table-danger td,
 .table-danger th,
 .table-danger thead th {
   border-color: #fcadb2
 }
 
 .table-hover .table-danger:hover,
 .table-hover .table-danger:hover>td,
 .table-hover .table-danger:hover>th {
   background-color: #fdbabf
 }
 
 .table-light,
 .table-light>td,
 .table-light>th {
   background-color: #ecedef
 }
 
 .table-light tbody+tbody,
 .table-light td,
 .table-light th,
 .table-light thead th {
   border-color: #dbdee2
 }
 
 .table-hover .table-light:hover,
 .table-hover .table-light:hover>td,
 .table-hover .table-light:hover>th {
   background-color: #dee0e3
 }
 
 .table-dark,
 .table-dark>td,
 .table-dark>th {
   background-color: #cbcccf
 }
 
 .table-dark tbody+tbody,
 .table-dark td,
 .table-dark th,
 .table-dark thead th {
   border-color: #9fa0a7
 }
 
 .table-hover .table-dark:hover,
 .table-hover .table-dark:hover>td,
 .table-hover .table-dark:hover>th {
   background-color: #bebfc3
 }
 
 .table-active,
 .table-active>td,
 .table-active>th {
   background-color: rgba(245, 247, 250, .5)
 }
 
 .table-hover .table-active:hover,
 .table-hover .table-active:hover>td,
 .table-hover .table-active:hover>th {
   background-color: rgba(228, 233, 242, .5)
 }
 
 .table .thead-dark th {
   color: #f9fafd;
   border-color: #3c4244;
   background-color: #2a2e30
 }
 
 .table .thead-light th {
   color: #4e5154;
   border-color: #626e82;
   background-color: #98a4b8
 }
 
 .table-dark {
   color: #f9fafd;
   background-color: #2a2e30
 }
 
 .table-dark td,
 .table-dark th,
 .table-dark thead th {
   border-color: #3c4244
 }
 
 .table-dark.table-bordered,
 .table-responsive>.table-bordered {
   border: 0
 }
 
 .table-dark.table-striped tbody tr:nth-of-type(odd) {
   background-color: rgba(255, 255, 255, .05)
 }
 
 .table-dark.table-hover tbody tr:hover {
   color: #f9fafd;
   background-color: rgba(255, 255, 255, .075)
 }
 
 @media (max-width:575.98px) {
   .table-responsive-sm {
     display: block;
     overflow-x: auto;
     width: 100%;
     -webkit-overflow-scrolling: touch
   }
 
   .table-responsive-sm>.table-bordered {
     border: 0
   }
 }
 
 @media (max-width:767.98px) {
   .table-responsive-md {
     display: block;
     overflow-x: auto;
     width: 100%;
     -webkit-overflow-scrolling: touch
   }
 
   .table-responsive-md>.table-bordered {
     border: 0
   }
 }
 
 @media (max-width:991.98px) {
   .table-responsive-lg {
     display: block;
     overflow-x: auto;
     width: 100%;
     -webkit-overflow-scrolling: touch
   }
 
   .table-responsive-lg>.table-bordered {
     border: 0
   }
 }
 
 @media (max-width:1199.98px) {
   .table-responsive-xl {
     display: block;
     overflow-x: auto;
     width: 100%;
     -webkit-overflow-scrolling: touch
   }
 
   .table-responsive-xl>.table-bordered {
     border: 0
   }
 }
 
 .table-responsive {
   display: block;
   overflow-x: auto;
   width: 100%;
   -webkit-overflow-scrolling: touch
 }
 
 .accordion>.card,
 .collapsing,
 .modal-open,
 .progress,
 .toast {
   overflow: hidden
 }
 
 .form-control {
   font-size: 1rem;
   line-height: 1.25;
   display: block;
   width: 100%;
   height: -webkit-calc(2.75rem + 2px);
   height: -moz-calc(2.75rem + 2px);
   height: calc(2.75rem + 2px);
   padding: .75rem 1.5rem;
   -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   color: #4e5154;
   border: 1px solid #babfc7;
   border-radius: .25rem;
   background-color: #fff;
   background-clip: padding-box
 }
 
 @media (prefers-reduced-motion:reduce) {
   .form-control {
     -webkit-transition: none;
     -moz-transition: none;
     -o-transition: none;
     transition: none
   }
 }
 
 .form-control::-ms-expand {
   border: 0;
   background-color: transparent
 }
 
 .form-control:focus {
   color: #4e5154;
   border-color: #6967ce;
   outline: 0;
   background-color: #fff;
   -webkit-box-shadow: none;
   box-shadow: none
 }
 
 .form-control::-webkit-input-placeholder {
   opacity: 1;
   color: #babfc7
 }
 
 .form-control:-moz-placeholder {
   opacity: 1;
   color: #babfc7
 }
 
 .form-control::-moz-placeholder {
   opacity: 1;
   color: #babfc7
 }
 
 .form-control:-ms-input-placeholder {
   opacity: 1;
   color: #babfc7
 }
 
 .form-control::placeholder {
   opacity: 1;
   color: #babfc7
 }
 
 .form-control:disabled,
 .form-control[readonly] {
   opacity: 1;
   background-color: #98a4b8
 }
 
 select.form-control:focus::-ms-value {
   color: #4e5154;
   background-color: #fff
 }
 
 .form-control-file,
 .form-control-range {
   display: block;
   width: 100%
 }
 
 .col-form-label {
   font-size: inherit;
   line-height: 1.25;
   padding-top: -webkit-calc(.75rem + 1px);
   padding-top: -moz-calc(.75rem + 1px);
   padding-top: calc(.75rem + 1px);
   padding-bottom: -webkit-calc(.75rem + 1px);
   padding-bottom: -moz-calc(.75rem + 1px);
   padding-bottom: calc(.75rem + 1px)
 }
 
 .col-form-label-lg {
   font-size: 1.25rem;
   line-height: 1.25;
   padding-top: -webkit-calc(1rem + 1px);
   padding-top: -moz-calc(1rem + 1px);
   padding-top: calc(1rem + 1px);
   padding-bottom: -webkit-calc(1rem + 1px);
   padding-bottom: -moz-calc(1rem + 1px);
   padding-bottom: calc(1rem + 1px)
 }
 
 .col-form-label-sm {
   font-size: .875rem;
   line-height: 1;
   padding-top: -webkit-calc(.5rem + 1px);
   padding-top: -moz-calc(.5rem + 1px);
   padding-top: calc(.5rem + 1px);
   padding-bottom: -webkit-calc(.5rem + 1px);
   padding-bottom: -moz-calc(.5rem + 1px);
   padding-bottom: calc(.5rem + 1px)
 }
 
 .form-control-plaintext {
   line-height: 1.25;
   display: block;
   width: 100%;
   margin-bottom: 0;
   padding-top: .75rem;
   padding-bottom: .75rem;
   color: #6b6f80;
   border: solid transparent;
   border-width: 1px 0;
   background-color: transparent
 }
 
 .form-control-plaintext.form-control-lg,
 .form-control-plaintext.form-control-sm {
   padding-right: 0;
   padding-left: 0
 }
 
 .form-control-sm {
   font-size: .875rem;
   line-height: 1;
   height: -webkit-calc(1.875rem + 2px + 10);
   height: -moz-calc(1.875rem + 2px + 10);
   height: calc(1.875rem + 2px + 10);
   padding: .5rem 1rem;
   border-radius: .21rem
 }
 
 .form-control-lg {
   font-size: 1.25rem;
   line-height: 1.25;
   height: -webkit-calc(3.5625rem + 2px);
   height: -moz-calc(3.5625rem + 2px);
   height: calc(3.5625rem + 2px);
   padding: 1rem 2rem;
   border-radius: .35rem
 }
 
 select.form-control[multiple],
 select.form-control[size],
 textarea.form-control {
   height: auto
 }
 
 .form-group {
   margin-bottom: 1rem
 }
 
 .form-text {
   display: block;
   margin-top: .25rem
 }
 
 .form-row {
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: -ms-flexbox;
   display: flex;
   margin-right: -5px;
   margin-left: -5px;
   -webkit-flex-wrap: wrap;
   -ms-flex-wrap: wrap;
   flex-wrap: wrap
 }
 
 .form-row>.col,
 .form-row>[class*=col-] {
   padding-right: 5px;
   padding-left: 5px
 }
 
 .form-check {
   position: relative;
   display: block;
   padding-left: 1.25rem
 }
 
 .form-check-input {
   position: absolute;
   margin-top: .3rem;
   margin-left: -1.25rem
 }
 
 .form-check-input:disabled~.form-check-label {
   color: #6b6f80
 }
 
 .form-check-label {
   margin-bottom: 0
 }
 
 .form-check-inline {
   display: -webkit-inline-box;
   display: -webkit-inline-flex;
   display: -moz-inline-box;
   display: -ms-inline-flexbox;
   display: inline-flex;
   margin-right: .75rem;
   padding-left: 0;
   -webkit-box-align: center;
   -webkit-align-items: center;
   -moz-box-align: center;
   -ms-flex-align: center;
   align-items: center
 }
 
 .form-check-inline .form-check-input {
   position: static;
   margin-top: 0;
   margin-right: .3125rem;
   margin-left: 0
 }
 
 .invalid-tooltip,
 .valid-tooltip {
   line-height: 1.45;
   position: absolute;
   z-index: 5;
   max-width: 100%;
   border-radius: .25rem;
   top: 100%
 }
 
 .valid-feedback {
   font-size: 80%;
   display: none;
   width: 100%;
   margin-top: .25rem;
   color: #5ed84f
 }
 
 .valid-tooltip {
   font-size: .875rem;
   display: none;
   margin-top: .1rem;
   padding: .25rem .5rem;
   color: #2a2e30;
   background-color: rgba(94, 216, 79, .8)
 }
 
 .custom-control-input.is-valid~.valid-feedback,
 .custom-control-input.is-valid~.valid-tooltip,
 .custom-file-input.is-valid~.valid-feedback,
 .custom-file-input.is-valid~.valid-tooltip,
 .custom-select.is-valid~.valid-feedback,
 .custom-select.is-valid~.valid-tooltip,
 .form-check-input.is-valid~.valid-feedback,
 .form-check-input.is-valid~.valid-tooltip,
 .form-control-file.is-valid~.valid-feedback,
 .form-control-file.is-valid~.valid-tooltip,
 .form-control.is-valid~.valid-feedback,
 .form-control.is-valid~.valid-tooltip,
 .was-validated .custom-control-input:valid~.valid-feedback,
 .was-validated .custom-control-input:valid~.valid-tooltip,
 .was-validated .custom-file-input:valid~.valid-feedback,
 .was-validated .custom-file-input:valid~.valid-tooltip,
 .was-validated .custom-select:valid~.valid-feedback,
 .was-validated .custom-select:valid~.valid-tooltip,
 .was-validated .form-check-input:valid~.valid-feedback,
 .was-validated .form-check-input:valid~.valid-tooltip,
 .was-validated .form-control-file:valid~.valid-feedback,
 .was-validated .form-control-file:valid~.valid-tooltip,
 .was-validated .form-control:valid~.valid-feedback,
 .was-validated .form-control:valid~.valid-tooltip {
   display: block
 }
 
 .custom-control-input.is-valid~.custom-control-label,
 .form-check-input.is-valid~.form-check-label,
 .was-validated .custom-control-input:valid~.custom-control-label,
 .was-validated .form-check-input:valid~.form-check-label {
   color: #5ed84f
 }
 
 .form-control.is-valid,
 .was-validated .form-control:valid {
   padding-right: 2.75rem;
   border-color: #5ed84f;
   background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 8 8\'%3e%3cpath fill=\'%235ed84f\' d=\'M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z\'/%3e%3c/svg%3e');
   background-repeat: no-repeat;
   background-position: center right -webkit-calc(.3125em + .375rem);
   background-position: center right -moz-calc(.3125em + .375rem);
   background-position: center right calc(.3125em + .375rem);
   -webkit-background-size: -webkit-calc(.625em + .75rem) -webkit-calc(.625em + .75rem);
   background-size: -moz-calc(.625em + .75rem) -moz-calc(.625em + .75rem);
   background-size: calc(.625em + .75rem) calc(.625em + .75rem)
 }
 
 .form-control.is-valid:focus,
 .was-validated .form-control:valid:focus {
   border-color: #5ed84f;
   -webkit-box-shadow: 0 0 0 .2rem rgba(94, 216, 79, .25);
   box-shadow: 0 0 0 .2rem rgba(94, 216, 79, .25)
 }
 
 .was-validated textarea.form-control:valid,
 textarea.form-control.is-valid {
   padding-right: 2.75rem;
   background-position: top -webkit-calc(.3125em + .375rem) right -webkit-calc(.3125em + .375rem);
   background-position: top -moz-calc(.3125em + .375rem) right -moz-calc(.3125em + .375rem);
   background-position: top calc(.3125em + .375rem) right calc(.3125em + .375rem)
 }
 
 .custom-select.is-valid,
 .was-validated .custom-select:valid {
   padding-right: -webkit-calc((1em + .75rem) * 3 / 4 + 1.75rem);
   padding-right: -moz-calc((1em + .75rem) * 3 / 4 + 1.75rem);
   padding-right: calc((1em + .75rem) * 3 / 4 + 1.75rem);
   border-color: #5ed84f;
   background: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 5\'%3e%3cpath fill=\'%23464855\' d=\'M2 0L0 2h4zm0 5L0 3h4z\'/%3e%3c/svg%3e') right .75rem center/8px 10px no-repeat, url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 8 8\'%3e%3cpath fill=\'%235ed84f\' d=\'M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z\'/%3e%3c/svg%3e') center right 1.75rem/calc(.625em + .75rem) -webkit-calc(.625em + .75rem) no-repeat #fff;
   background: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 5\'%3e%3cpath fill=\'%23464855\' d=\'M2 0L0 2h4zm0 5L0 3h4z\'/%3e%3c/svg%3e') right .75rem center/8px 10px no-repeat, url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 8 8\'%3e%3cpath fill=\'%235ed84f\' d=\'M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z\'/%3e%3c/svg%3e') center right 1.75rem/calc(.625em + .75rem) -moz-calc(.625em + .75rem) no-repeat #fff;
   background: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 5\'%3e%3cpath fill=\'%23464855\' d=\'M2 0L0 2h4zm0 5L0 3h4z\'/%3e%3c/svg%3e') right .75rem center/8px 10px no-repeat, url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 8 8\'%3e%3cpath fill=\'%235ed84f\' d=\'M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z\'/%3e%3c/svg%3e') center right 1.75rem/calc(.625em + .75rem) calc(.625em + .75rem) no-repeat #fff
 }
 
 .custom-select.is-valid:focus,
 .was-validated .custom-select:valid:focus {
   border-color: #5ed84f;
   -webkit-box-shadow: 0 0 0 .2rem rgba(94, 216, 79, .25);
   box-shadow: 0 0 0 .2rem rgba(94, 216, 79, .25)
 }
 
 .custom-control-input.is-valid~.custom-control-label::before,
 .was-validated .custom-control-input:valid~.custom-control-label::before {
   border-color: #5ed84f
 }
 
 .custom-control-input.is-valid:checked~.custom-control-label::before,
 .was-validated .custom-control-input:valid:checked~.custom-control-label::before {
   border-color: #84e179;
   background-color: #84e179
 }
 
 .custom-control-input.is-valid:focus~.custom-control-label::before,
 .was-validated .custom-control-input:valid:focus~.custom-control-label::before {
   -webkit-box-shadow: 0 0 0 .2rem rgba(94, 216, 79, .25);
   box-shadow: 0 0 0 .2rem rgba(94, 216, 79, .25)
 }
 
 .custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,
 .custom-file-input.is-valid~.custom-file-label,
 .was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before,
 .was-validated .custom-file-input:valid~.custom-file-label {
   border-color: #5ed84f
 }
 
 .custom-file-input.is-valid:focus~.custom-file-label,
 .was-validated .custom-file-input:valid:focus~.custom-file-label {
   border-color: #5ed84f;
   -webkit-box-shadow: 0 0 0 .2rem rgba(94, 216, 79, .25);
   box-shadow: 0 0 0 .2rem rgba(94, 216, 79, .25)
 }
 
 .invalid-feedback {
   font-size: 80%;
   display: none;
   width: 100%;
   margin-top: .25rem;
   color: #fa626b
 }
 
 .invalid-tooltip {
   font-size: .875rem;
   display: none;
   margin-top: .1rem;
   padding: .25rem .5rem;
   color: #fff;
   background-color: rgba(250, 98, 107, .8)
 }
 
 .collapsing,
 .dropdown,
 .dropleft,
 .dropright,
 .dropup {
   position: relative
 }
 
 .custom-control-input.is-invalid~.invalid-feedback,
 .custom-control-input.is-invalid~.invalid-tooltip,
 .custom-file-input.is-invalid~.invalid-feedback,
 .custom-file-input.is-invalid~.invalid-tooltip,
 .custom-select.is-invalid~.invalid-feedback,
 .custom-select.is-invalid~.invalid-tooltip,
 .form-check-input.is-invalid~.invalid-feedback,
 .form-check-input.is-invalid~.invalid-tooltip,
 .form-control-file.is-invalid~.invalid-feedback,
 .form-control-file.is-invalid~.invalid-tooltip,
 .form-control.is-invalid~.invalid-feedback,
 .form-control.is-invalid~.invalid-tooltip,
 .was-validated .custom-control-input:invalid~.invalid-feedback,
 .was-validated .custom-control-input:invalid~.invalid-tooltip,
 .was-validated .custom-file-input:invalid~.invalid-feedback,
 .was-validated .custom-file-input:invalid~.invalid-tooltip,
 .was-validated .custom-select:invalid~.invalid-feedback,
 .was-validated .custom-select:invalid~.invalid-tooltip,
 .was-validated .form-check-input:invalid~.invalid-feedback,
 .was-validated .form-check-input:invalid~.invalid-tooltip,
 .was-validated .form-control-file:invalid~.invalid-feedback,
 .was-validated .form-control-file:invalid~.invalid-tooltip,
 .was-validated .form-control:invalid~.invalid-feedback,
 .was-validated .form-control:invalid~.invalid-tooltip {
   display: block
 }
 
 .custom-control-input.is-invalid~.custom-control-label,
 .form-check-input.is-invalid~.form-check-label,
 .was-validated .custom-control-input:invalid~.custom-control-label,
 .was-validated .form-check-input:invalid~.form-check-label {
   color: #fa626b
 }
 
 .form-control.is-invalid,
 .was-validated .form-control:invalid {
   padding-right: 2.75rem;
   border-color: #fa626b;
   background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23fa626b\' viewBox=\'-2 -2 7 7\'%3e%3cpath stroke=\'%23fa626b\' d=\'M0 0l3 3m0-3L0 3\'/%3e%3ccircle r=\'.5\'/%3e%3ccircle cx=\'3\' r=\'.5\'/%3e%3ccircle cy=\'3\' r=\'.5\'/%3e%3ccircle cx=\'3\' cy=\'3\' r=\'.5\'/%3e%3c/svg%3E');
   background-repeat: no-repeat;
   background-position: center right -webkit-calc(.3125em + .375rem);
   background-position: center right -moz-calc(.3125em + .375rem);
   background-position: center right calc(.3125em + .375rem);
   -webkit-background-size: -webkit-calc(.625em + .75rem) -webkit-calc(.625em + .75rem);
   background-size: -moz-calc(.625em + .75rem) -moz-calc(.625em + .75rem);
   background-size: calc(.625em + .75rem) calc(.625em + .75rem)
 }
 
 .form-control.is-invalid:focus,
 .was-validated .form-control:invalid:focus {
   border-color: #fa626b;
   -webkit-box-shadow: 0 0 0 .2rem rgba(250, 98, 107, .25);
   box-shadow: 0 0 0 .2rem rgba(250, 98, 107, .25)
 }
 
 .was-validated textarea.form-control:invalid,
 textarea.form-control.is-invalid {
   padding-right: 2.75rem;
   background-position: top -webkit-calc(.3125em + .375rem) right -webkit-calc(.3125em + .375rem);
   background-position: top -moz-calc(.3125em + .375rem) right -moz-calc(.3125em + .375rem);
   background-position: top calc(.3125em + .375rem) right calc(.3125em + .375rem)
 }
 
 .custom-select.is-invalid,
 .was-validated .custom-select:invalid {
   padding-right: -webkit-calc((1em + .75rem) * 3 / 4 + 1.75rem);
   padding-right: -moz-calc((1em + .75rem) * 3 / 4 + 1.75rem);
   padding-right: calc((1em + .75rem) * 3 / 4 + 1.75rem);
   border-color: #fa626b;
   background: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 5\'%3e%3cpath fill=\'%23464855\' d=\'M2 0L0 2h4zm0 5L0 3h4z\'/%3e%3c/svg%3e') right .75rem center/8px 10px no-repeat, url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23fa626b\' viewBox=\'-2 -2 7 7\'%3e%3cpath stroke=\'%23fa626b\' d=\'M0 0l3 3m0-3L0 3\'/%3e%3ccircle r=\'.5\'/%3e%3ccircle cx=\'3\' r=\'.5\'/%3e%3ccircle cy=\'3\' r=\'.5\'/%3e%3ccircle cx=\'3\' cy=\'3\' r=\'.5\'/%3e%3c/svg%3E') center right 1.75rem/calc(.625em + .75rem) -webkit-calc(.625em + .75rem) no-repeat #fff;
   background: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 5\'%3e%3cpath fill=\'%23464855\' d=\'M2 0L0 2h4zm0 5L0 3h4z\'/%3e%3c/svg%3e') right .75rem center/8px 10px no-repeat, url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23fa626b\' viewBox=\'-2 -2 7 7\'%3e%3cpath stroke=\'%23fa626b\' d=\'M0 0l3 3m0-3L0 3\'/%3e%3ccircle r=\'.5\'/%3e%3ccircle cx=\'3\' r=\'.5\'/%3e%3ccircle cy=\'3\' r=\'.5\'/%3e%3ccircle cx=\'3\' cy=\'3\' r=\'.5\'/%3e%3c/svg%3E') center right 1.75rem/calc(.625em + .75rem) -moz-calc(.625em + .75rem) no-repeat #fff;
   background: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 5\'%3e%3cpath fill=\'%23464855\' d=\'M2 0L0 2h4zm0 5L0 3h4z\'/%3e%3c/svg%3e') right .75rem center/8px 10px no-repeat, url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23fa626b\' viewBox=\'-2 -2 7 7\'%3e%3cpath stroke=\'%23fa626b\' d=\'M0 0l3 3m0-3L0 3\'/%3e%3ccircle r=\'.5\'/%3e%3ccircle cx=\'3\' r=\'.5\'/%3e%3ccircle cy=\'3\' r=\'.5\'/%3e%3ccircle cx=\'3\' cy=\'3\' r=\'.5\'/%3e%3c/svg%3E') center right 1.75rem/calc(.625em + .75rem) calc(.625em + .75rem) no-repeat #fff
 }
 
 .custom-select.is-invalid:focus,
 .was-validated .custom-select:invalid:focus {
   border-color: #fa626b;
   -webkit-box-shadow: 0 0 0 .2rem rgba(250, 98, 107, .25);
   box-shadow: 0 0 0 .2rem rgba(250, 98, 107, .25)
 }
 
 .custom-control-input.is-invalid~.custom-control-label::before,
 .was-validated .custom-control-input:invalid~.custom-control-label::before {
   border-color: #fa626b
 }
 
 .custom-control-input.is-invalid:checked~.custom-control-label::before,
 .was-validated .custom-control-input:invalid:checked~.custom-control-label::before {
   border-color: #fc939a;
   background-color: #fc939a
 }
 
 .custom-control-input.is-invalid:focus~.custom-control-label::before,
 .was-validated .custom-control-input:invalid:focus~.custom-control-label::before {
   -webkit-box-shadow: 0 0 0 .2rem rgba(250, 98, 107, .25);
   box-shadow: 0 0 0 .2rem rgba(250, 98, 107, .25)
 }
 
 .custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before,
 .custom-file-input.is-invalid~.custom-file-label,
 .was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before,
 .was-validated .custom-file-input:invalid~.custom-file-label {
   border-color: #fa626b
 }
 
 .custom-file-input.is-invalid:focus~.custom-file-label,
 .was-validated .custom-file-input:invalid:focus~.custom-file-label {
   border-color: #fa626b;
   -webkit-box-shadow: 0 0 0 .2rem rgba(250, 98, 107, .25);
   box-shadow: 0 0 0 .2rem rgba(250, 98, 107, .25)
 }
 
 .form-inline {
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: horizontal;
   -webkit-box-direction: normal;
   -webkit-flex-flow: row wrap;
   -moz-box-orient: horizontal;
   -moz-box-direction: normal;
   -ms-flex-flow: row wrap;
   flex-flow: row wrap;
   -webkit-box-align: center;
   -webkit-align-items: center;
   -moz-box-align: center;
   -ms-flex-align: center;
   align-items: center
 }
 
 .form-inline .form-check {
   width: 100%
 }
 
 @media (min-width:576px) {
   .form-inline label {
     display: -webkit-box;
     display: -webkit-flex;
     display: -moz-box;
     display: -ms-flexbox;
     display: flex;
     margin-bottom: 0;
     -webkit-box-align: center;
     -webkit-align-items: center;
     -moz-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     -webkit-box-pack: center;
     -webkit-justify-content: center;
     -moz-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center
   }
 
   .form-inline .form-group {
     display: -webkit-box;
     display: -webkit-flex;
     display: -moz-box;
     display: -ms-flexbox;
     display: flex;
     margin-bottom: 0;
     -webkit-box-flex: 0;
     -webkit-flex: 0 0 auto;
     -moz-box-flex: 0;
     -ms-flex: 0 0 auto;
     flex: 0 0 auto;
     -webkit-box-orient: horizontal;
     -webkit-box-direction: normal;
     -webkit-flex-flow: row wrap;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
     -ms-flex-flow: row wrap;
     flex-flow: row wrap;
     -webkit-box-align: center;
     -webkit-align-items: center;
     -moz-box-align: center;
     -ms-flex-align: center;
     align-items: center
   }
 
   .form-inline .form-control {
     display: inline-block;
     width: auto;
     vertical-align: middle
   }
 
   .form-inline .form-control-plaintext {
     display: inline-block
   }
 
   .form-inline .custom-select,
   .form-inline .input-group {
     width: auto
   }
 
   .form-inline .form-check {
     display: -webkit-box;
     display: -webkit-flex;
     display: -moz-box;
     display: -ms-flexbox;
     display: flex;
     width: auto;
     padding-left: 0;
     -webkit-box-align: center;
     -webkit-align-items: center;
     -moz-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     -webkit-box-pack: center;
     -webkit-justify-content: center;
     -moz-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center
   }
 
   .form-inline .form-check-input {
     position: relative;
     margin-top: 0;
     margin-right: .25rem;
     margin-left: 0;
     -webkit-flex-shrink: 0;
     -ms-flex-negative: 0;
     flex-shrink: 0
   }
 
   .form-inline .custom-control {
     -webkit-box-align: center;
     -webkit-align-items: center;
     -moz-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     -webkit-box-pack: center;
     -webkit-justify-content: center;
     -moz-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center
   }
 
   .form-inline .custom-control-label {
     margin-bottom: 0
   }
 }
 
 .btn-group-vertical,
 .navbar-nav {
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -moz-box-direction: normal
 }
 
 .btn-block,
 input[type=button].btn-block,
 input[type=reset].btn-block,
 input[type=submit].btn-block {
   width: 100%
 }
 
 .btn {
   font-size: 1rem;
   line-height: 1.25;
   display: inline-block;
   padding: .75rem 1.5rem;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   -moz-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   -o-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   text-align: center;
   vertical-align: middle;
   color: #6b6f80;
   border: 1px solid transparent;
   border-radius: .25rem;
   background-color: transparent
 }
 
 .dropdown-toggle::after,
 .dropup .dropdown-toggle::after {
   content: '';
   vertical-align: .255em
 }
 
 @media (prefers-reduced-motion:reduce) {
   .btn {
     -webkit-transition: none;
     -moz-transition: none;
     -o-transition: none;
     transition: none
   }
 }
 
 .btn:hover {
   color: #6b6f80
 }
 
 .btn.focus,
 .btn:focus {
   outline: 0;
   -webkit-box-shadow: none;
   box-shadow: none
 }
 
 .btn.disabled,
 .btn:disabled {
   opacity: .65
 }
 
 a.btn.disabled,
 fieldset:disabled a.btn {
   pointer-events: none
 }
 
 .btn-primary {
   color: #fff;
   border-color: #6967ce;
   background-color: #6967ce
 }
 
 .btn-primary:hover {
   color: #fff;
   border-color: #4340c2;
   background-color: #4c4ac5
 }
 
 .btn-primary.focus,
 .btn-primary:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(128, 126, 213, .5);
   box-shadow: 0 0 0 .2rem rgba(128, 126, 213, .5)
 }
 
 .btn-primary.disabled,
 .btn-primary:disabled {
   color: #fff;
   border-color: #6967ce;
   background-color: #6967ce
 }
 
 .btn-primary:not(:disabled):not(.disabled).active,
 .btn-primary:not(:disabled):not(.disabled):active,
 .show>.btn-primary.dropdown-toggle {
   color: #fff;
   border-color: #3e3cb9;
   background-color: #4340c2
 }
 
 .btn-primary:not(:disabled):not(.disabled).active:focus,
 .btn-primary:not(:disabled):not(.disabled):active:focus,
 .show>.btn-primary.dropdown-toggle:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(128, 126, 213, .5);
   box-shadow: 0 0 0 .2rem rgba(128, 126, 213, .5)
 }
 
 .btn-secondary {
   color: #fff;
   border-color: #6b6f80;
   background-color: #6b6f80
 }
 
 .btn-secondary:hover {
   color: #fff;
   border-color: #545764;
   background-color: #5a5d6b
 }
 
 .btn-secondary.focus,
 .btn-secondary:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(129, 133, 147, .5);
   box-shadow: 0 0 0 .2rem rgba(129, 133, 147, .5)
 }
 
 .btn-secondary.disabled,
 .btn-secondary:disabled {
   color: #fff;
   border-color: #6b6f80;
   background-color: #6b6f80
 }
 
 .btn-secondary:not(:disabled):not(.disabled).active,
 .btn-secondary:not(:disabled):not(.disabled):active,
 .show>.btn-secondary.dropdown-toggle {
   color: #fff;
   border-color: #4e515d;
   background-color: #545764
 }
 
 .btn-secondary:not(:disabled):not(.disabled).active:focus,
 .btn-secondary:not(:disabled):not(.disabled):active:focus,
 .show>.btn-secondary.dropdown-toggle:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(129, 133, 147, .5);
   box-shadow: 0 0 0 .2rem rgba(129, 133, 147, .5)
 }
 
 .btn-success {
   color: #2a2e30;
   border-color: #5ed84f;
   background-color: #5ed84f
 }
 
 .btn-success:hover {
   color: #fff;
   border-color: #3dc82c;
   background-color: #41d130
 }
 
 .btn-success.focus,
 .btn-success:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(86, 191, 74, .5);
   box-shadow: 0 0 0 .2rem rgba(86, 191, 74, .5)
 }
 
 .btn-success.disabled,
 .btn-success:disabled {
   color: #2a2e30;
   border-color: #5ed84f;
   background-color: #5ed84f
 }
 
 .btn-success:not(:disabled):not(.disabled).active,
 .btn-success:not(:disabled):not(.disabled):active,
 .show>.btn-success.dropdown-toggle {
   color: #fff;
   border-color: #3abd2a;
   background-color: #3dc82c
 }
 
 .btn-success:not(:disabled):not(.disabled).active:focus,
 .btn-success:not(:disabled):not(.disabled):active:focus,
 .show>.btn-success.dropdown-toggle:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(86, 191, 74, .5);
   box-shadow: 0 0 0 .2rem rgba(86, 191, 74, .5)
 }
 
 .btn-info {
   color: #fff;
   border-color: #28afd0;
   background-color: #28afd0
 }
 
 .btn-info:hover {
   color: #fff;
   border-color: #208ba5;
   background-color: #2294b0
 }
 
 .btn-info.focus,
 .btn-info:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(72, 187, 215, .5);
   box-shadow: 0 0 0 .2rem rgba(72, 187, 215, .5)
 }
 
 .btn-info.disabled,
 .btn-info:disabled {
   color: #fff;
   border-color: #28afd0;
   background-color: #28afd0
 }
 
 .btn-info:not(:disabled):not(.disabled).active,
 .btn-info:not(:disabled):not(.disabled):active,
 .show>.btn-info.dropdown-toggle {
   color: #fff;
   border-color: #1e829b;
   background-color: #208ba5
 }
 
 .btn-info:not(:disabled):not(.disabled).active:focus,
 .btn-info:not(:disabled):not(.disabled):active:focus,
 .show>.btn-info.dropdown-toggle:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(72, 187, 215, .5);
   box-shadow: 0 0 0 .2rem rgba(72, 187, 215, .5)
 }
 
 .btn-warning {
   color: #2a2e30;
   border-color: #fdb901;
   background-color: #fdb901
 }
 
 .btn-warning:hover {
   color: #2a2e30;
   border-color: #ca9401;
   background-color: #d79d01
 }
 
 .btn-warning.focus,
 .btn-warning:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(221, 164, 8, .5);
   box-shadow: 0 0 0 .2rem rgba(221, 164, 8, .5)
 }
 
 .btn-warning.disabled,
 .btn-warning:disabled {
   color: #2a2e30;
   border-color: #fdb901;
   background-color: #fdb901
 }
 
 .btn-warning:not(:disabled):not(.disabled).active,
 .btn-warning:not(:disabled):not(.disabled):active,
 .show>.btn-warning.dropdown-toggle {
   color: #fff;
   border-color: #be8b01;
   background-color: #ca9401
 }
 
 .btn-warning:not(:disabled):not(.disabled).active:focus,
 .btn-warning:not(:disabled):not(.disabled):active:focus,
 .show>.btn-warning.dropdown-toggle:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(221, 164, 8, .5);
   box-shadow: 0 0 0 .2rem rgba(221, 164, 8, .5)
 }
 
 .btn-danger {
   color: #fff;
   border-color: #fa626b;
   background-color: #fa626b
 }
 
 .btn-danger:hover {
   color: #fff;
   border-color: #f8313c;
   background-color: #f93d48
 }
 
 .btn-danger.focus,
 .btn-danger:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(251, 122, 129, .5);
   box-shadow: 0 0 0 .2rem rgba(251, 122, 129, .5)
 }
 
 .btn-danger.disabled,
 .btn-danger:disabled {
   color: #fff;
   border-color: #fa626b;
   background-color: #fa626b
 }
 
 .btn-danger:not(:disabled):not(.disabled).active,
 .btn-danger:not(:disabled):not(.disabled):active,
 .show>.btn-danger.dropdown-toggle {
   color: #fff;
   border-color: #f82431;
   background-color: #f8313c
 }
 
 .btn-danger:not(:disabled):not(.disabled).active:focus,
 .btn-danger:not(:disabled):not(.disabled):active:focus,
 .show>.btn-danger.dropdown-toggle:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(251, 122, 129, .5);
   box-shadow: 0 0 0 .2rem rgba(251, 122, 129, .5)
 }
 
 .btn-light {
   color: #2a2e30;
   border-color: #babfc7;
   background-color: #babfc7
 }
 
 .btn-light:hover {
   color: #2a2e30;
   border-color: #9ea5b0;
   background-color: #a5abb6
 }
 
 .btn-light.focus,
 .btn-light:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(164, 169, 176, .5);
   box-shadow: 0 0 0 .2rem rgba(164, 169, 176, .5)
 }
 
 .btn-light.disabled,
 .btn-light:disabled {
   color: #2a2e30;
   border-color: #babfc7;
   background-color: #babfc7
 }
 
 .btn-light:not(:disabled):not(.disabled).active,
 .btn-light:not(:disabled):not(.disabled):active,
 .show>.btn-light.dropdown-toggle {
   color: #2a2e30;
   border-color: #979eaa;
   background-color: #9ea5b0
 }
 
 .btn-light:not(:disabled):not(.disabled).active:focus,
 .btn-light:not(:disabled):not(.disabled):active:focus,
 .show>.btn-light.dropdown-toggle:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(164, 169, 176, .5);
   box-shadow: 0 0 0 .2rem rgba(164, 169, 176, .5)
 }
 
 .btn-dark {
   color: #fff;
   border-color: #464855;
   background-color: #464855
 }
 
 .btn-dark:hover {
   color: #fff;
   border-color: #2f3039;
   background-color: #353640
 }
 
 .btn-dark.focus,
 .btn-dark:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(98, 99, 111, .5);
   box-shadow: 0 0 0 .2rem rgba(98, 99, 111, .5)
 }
 
 .btn-dark.disabled,
 .btn-dark:disabled {
   color: #fff;
   border-color: #464855;
   background-color: #464855
 }
 
 .btn-dark:not(:disabled):not(.disabled).active,
 .btn-dark:not(:disabled):not(.disabled):active,
 .show>.btn-dark.dropdown-toggle {
   color: #fff;
   border-color: #292a32;
   background-color: #2f3039
 }
 
 .btn-dark:not(:disabled):not(.disabled).active:focus,
 .btn-dark:not(:disabled):not(.disabled):active:focus,
 .show>.btn-dark.dropdown-toggle:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(98, 99, 111, .5);
   box-shadow: 0 0 0 .2rem rgba(98, 99, 111, .5)
 }
 
 .btn-outline-primary {
   color: #6967ce;
   border-color: #6967ce
 }
 
 .btn-outline-primary:hover {
   color: #fff;
   border-color: #6967ce;
   background-color: #6967ce
 }
 
 .btn-outline-primary.focus,
 .btn-outline-primary:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(105, 103, 206, .5);
   box-shadow: 0 0 0 .2rem rgba(105, 103, 206, .5)
 }
 
 .btn-outline-primary.disabled,
 .btn-outline-primary:disabled {
   color: #6967ce;
   background-color: transparent
 }
 
 .btn-outline-primary:not(:disabled):not(.disabled).active,
 .btn-outline-primary:not(:disabled):not(.disabled):active,
 .show>.btn-outline-primary.dropdown-toggle {
   color: #fff;
   border-color: #6967ce;
   background-color: #6967ce
 }
 
 .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
 .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
 .show>.btn-outline-primary.dropdown-toggle:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(105, 103, 206, .5);
   box-shadow: 0 0 0 .2rem rgba(105, 103, 206, .5)
 }
 
 .btn-outline-secondary {
   color: #6b6f80;
   border-color: #6b6f80
 }
 
 .btn-outline-secondary:hover {
   color: #fff;
   border-color: #6b6f80;
   background-color: #6b6f80
 }
 
 .btn-outline-secondary.focus,
 .btn-outline-secondary:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(107, 111, 128, .5);
   box-shadow: 0 0 0 .2rem rgba(107, 111, 128, .5)
 }
 
 .btn-outline-secondary.disabled,
 .btn-outline-secondary:disabled {
   color: #6b6f80;
   background-color: transparent
 }
 
 .btn-outline-secondary:not(:disabled):not(.disabled).active,
 .btn-outline-secondary:not(:disabled):not(.disabled):active,
 .show>.btn-outline-secondary.dropdown-toggle {
   color: #fff;
   border-color: #6b6f80;
   background-color: #6b6f80
 }
 
 .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
 .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
 .show>.btn-outline-secondary.dropdown-toggle:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(107, 111, 128, .5);
   box-shadow: 0 0 0 .2rem rgba(107, 111, 128, .5)
 }
 
 .btn-outline-success {
   color: #5ed84f;
   border-color: #5ed84f
 }
 
 .btn-outline-success:hover {
   color: #2a2e30;
   border-color: #5ed84f;
   background-color: #5ed84f
 }
 
 .btn-outline-success.focus,
 .btn-outline-success:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(94, 216, 79, .5);
   box-shadow: 0 0 0 .2rem rgba(94, 216, 79, .5)
 }
 
 .btn-outline-success.disabled,
 .btn-outline-success:disabled {
   color: #5ed84f;
   background-color: transparent
 }
 
 .btn-outline-success:not(:disabled):not(.disabled).active,
 .btn-outline-success:not(:disabled):not(.disabled):active,
 .show>.btn-outline-success.dropdown-toggle {
   color: #2a2e30;
   border-color: #5ed84f;
   background-color: #5ed84f
 }
 
 .btn-outline-success:not(:disabled):not(.disabled).active:focus,
 .btn-outline-success:not(:disabled):not(.disabled):active:focus,
 .show>.btn-outline-success.dropdown-toggle:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(94, 216, 79, .5);
   box-shadow: 0 0 0 .2rem rgba(94, 216, 79, .5)
 }
 
 .btn-outline-info {
   color: #28afd0;
   border-color: #28afd0
 }
 
 .btn-outline-info:hover {
   color: #fff;
   border-color: #28afd0;
   background-color: #28afd0
 }
 
 .btn-outline-info.focus,
 .btn-outline-info:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(40, 175, 208, .5);
   box-shadow: 0 0 0 .2rem rgba(40, 175, 208, .5)
 }
 
 .btn-outline-info.disabled,
 .btn-outline-info:disabled {
   color: #28afd0;
   background-color: transparent
 }
 
 .btn-outline-info:not(:disabled):not(.disabled).active,
 .btn-outline-info:not(:disabled):not(.disabled):active,
 .show>.btn-outline-info.dropdown-toggle {
   color: #fff;
   border-color: #28afd0;
   background-color: #28afd0
 }
 
 .btn-outline-info:not(:disabled):not(.disabled).active:focus,
 .btn-outline-info:not(:disabled):not(.disabled):active:focus,
 .show>.btn-outline-info.dropdown-toggle:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(40, 175, 208, .5);
   box-shadow: 0 0 0 .2rem rgba(40, 175, 208, .5)
 }
 
 .btn-outline-warning {
   color: #fdb901;
   border-color: #fdb901
 }
 
 .btn-outline-warning:hover {
   color: #2a2e30;
   border-color: #fdb901;
   background-color: #fdb901
 }
 
 .btn-outline-warning.focus,
 .btn-outline-warning:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(253, 185, 1, .5);
   box-shadow: 0 0 0 .2rem rgba(253, 185, 1, .5)
 }
 
 .btn-outline-warning.disabled,
 .btn-outline-warning:disabled {
   color: #fdb901;
   background-color: transparent
 }
 
 .btn-outline-warning:not(:disabled):not(.disabled).active,
 .btn-outline-warning:not(:disabled):not(.disabled):active,
 .show>.btn-outline-warning.dropdown-toggle {
   color: #2a2e30;
   border-color: #fdb901;
   background-color: #fdb901
 }
 
 .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
 .btn-outline-warning:not(:disabled):not(.disabled):active:focus,
 .show>.btn-outline-warning.dropdown-toggle:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(253, 185, 1, .5);
   box-shadow: 0 0 0 .2rem rgba(253, 185, 1, .5)
 }
 
 .btn-outline-danger {
   color: #fa626b;
   border-color: #fa626b
 }
 
 .btn-outline-danger:hover {
   color: #fff;
   border-color: #fa626b;
   background-color: #fa626b
 }
 
 .btn-outline-danger.focus,
 .btn-outline-danger:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(250, 98, 107, .5);
   box-shadow: 0 0 0 .2rem rgba(250, 98, 107, .5)
 }
 
 .btn-outline-danger.disabled,
 .btn-outline-danger:disabled {
   color: #fa626b;
   background-color: transparent
 }
 
 .btn-outline-danger:not(:disabled):not(.disabled).active,
 .btn-outline-danger:not(:disabled):not(.disabled):active,
 .show>.btn-outline-danger.dropdown-toggle {
   color: #fff;
   border-color: #fa626b;
   background-color: #fa626b
 }
 
 .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
 .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
 .show>.btn-outline-danger.dropdown-toggle:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(250, 98, 107, .5);
   box-shadow: 0 0 0 .2rem rgba(250, 98, 107, .5)
 }
 
 .btn-outline-light {
   color: #babfc7;
   border-color: #babfc7
 }
 
 .btn-outline-light:hover {
   color: #2a2e30;
   border-color: #babfc7;
   background-color: #babfc7
 }
 
 .btn-outline-light.focus,
 .btn-outline-light:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(186, 191, 199, .5);
   box-shadow: 0 0 0 .2rem rgba(186, 191, 199, .5)
 }
 
 .btn-outline-light.disabled,
 .btn-outline-light:disabled {
   color: #babfc7;
   background-color: transparent
 }
 
 .btn-outline-light:not(:disabled):not(.disabled).active,
 .btn-outline-light:not(:disabled):not(.disabled):active,
 .show>.btn-outline-light.dropdown-toggle {
   color: #2a2e30;
   border-color: #babfc7;
   background-color: #babfc7
 }
 
 .btn-outline-light:not(:disabled):not(.disabled).active:focus,
 .btn-outline-light:not(:disabled):not(.disabled):active:focus,
 .show>.btn-outline-light.dropdown-toggle:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(186, 191, 199, .5);
   box-shadow: 0 0 0 .2rem rgba(186, 191, 199, .5)
 }
 
 .btn-outline-dark {
   color: #464855;
   border-color: #464855
 }
 
 .btn-outline-dark:hover {
   color: #fff;
   border-color: #464855;
   background-color: #464855
 }
 
 .btn-outline-dark.focus,
 .btn-outline-dark:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(70, 72, 85, .5);
   box-shadow: 0 0 0 .2rem rgba(70, 72, 85, .5)
 }
 
 .btn-outline-dark.disabled,
 .btn-outline-dark:disabled {
   color: #464855;
   background-color: transparent
 }
 
 .btn-outline-dark:not(:disabled):not(.disabled).active,
 .btn-outline-dark:not(:disabled):not(.disabled):active,
 .show>.btn-outline-dark.dropdown-toggle {
   color: #fff;
   border-color: #464855;
   background-color: #464855
 }
 
 .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
 .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
 .show>.btn-outline-dark.dropdown-toggle:focus {
   -webkit-box-shadow: 0 0 0 .2rem rgba(70, 72, 85, .5);
   box-shadow: 0 0 0 .2rem rgba(70, 72, 85, .5)
 }
 
 .btn-link {
   color: #5654c8
 }
 
 .btn-link:hover {
   color: #4340c2
 }
 
 .btn-link.focus,
 .btn-link:focus {
   -webkit-box-shadow: none;
   box-shadow: none
 }
 
 .btn-link.disabled,
 .btn-link:disabled {
   pointer-events: none;
   color: #6b6f80
 }
 
 .btn-group-lg>.btn,
 .btn-lg {
   font-size: 1.25rem;
   line-height: 1.25;
   padding: 1rem 2rem;
   border-radius: .35rem
 }
 
 .btn-group-sm>.btn,
 .btn-sm {
   font-size: .875rem;
   line-height: 1;
   padding: .5rem 1rem;
   border-radius: .21rem
 }
 
 .btn-block {
   display: block
 }
 
 .btn-block+.btn-block {
   margin-top: .5rem
 }
 
 .fade {
   -webkit-transition: opacity .15s linear;
   -moz-transition: opacity .15s linear;
   -o-transition: opacity .15s linear;
   transition: opacity .15s linear
 }
 
 @media (prefers-reduced-motion:reduce) {
   .fade {
     -webkit-transition: none;
     -moz-transition: none;
     -o-transition: none;
     transition: none
   }
 }
 
 .fade:not(.show) {
   opacity: 0
 }
 
 .collapse:not(.show) {
   display: none
 }
 
 .collapsing {
   height: 0;
   -webkit-transition: height .35s ease;
   -moz-transition: height .35s ease;
   -o-transition: height .35s ease;
   transition: height .35s ease
 }
 
 @media (prefers-reduced-motion:reduce) {
   .collapsing {
     -webkit-transition: none;
     -moz-transition: none;
     -o-transition: none;
     transition: none
   }
 }
 
 .dropdown-toggle::after {
   display: inline-block;
   margin-left: .255em;
   border-top: .3em solid;
   border-right: .3em solid transparent;
   border-bottom: 0;
   border-left: .3em solid transparent
 }
 
 .dropdown-toggle:empty::after {
   margin-left: 0
 }
 
 .dropdown-menu {
   font-size: 1rem;
   position: absolute;
   z-index: 1000;
   top: 100%;
   left: 0;
   display: none;
   float: left;
   min-width: 10rem;
   margin: .125rem 0 0;
   padding: .5rem 0;
   text-align: left;
   color: #6b6f80;
   border: 1px solid rgba(0, 0, 0, .15);
   border-radius: .25rem;
   background-color: #fff;
   background-clip: padding-box
 }
 
 .dropdown-menu-left {
   right: auto;
   left: 0
 }
 
 .dropdown-menu-right {
   right: 0;
   left: auto
 }
 
 @media (min-width:576px) {
   .dropdown-menu-sm-left {
     right: auto;
     left: 0
   }
 
   .dropdown-menu-sm-right {
     right: 0;
     left: auto
   }
 }
 
 @media (min-width:768px) {
   .dropdown-menu-md-left {
     right: auto;
     left: 0
   }
 
   .dropdown-menu-md-right {
     right: 0;
     left: auto
   }
 }
 
 @media (min-width:992px) {
   .dropdown-menu-lg-left {
     right: auto;
     left: 0
   }
 
   .dropdown-menu-lg-right {
     right: 0;
     left: auto
   }
 }
 
 @media (min-width:1200px) {
   .dropdown-menu-xl-left {
     right: auto;
     left: 0
   }
 
   .dropdown-menu-xl-right {
     right: 0;
     left: auto
   }
 }
 
 .dropup .dropdown-menu {
   top: auto;
   bottom: 100%;
   margin-top: 0;
   margin-bottom: .125rem
 }
 
 .dropup .dropdown-toggle::after {
   display: inline-block;
   margin-left: .255em;
   border-top: 0;
   border-right: .3em solid transparent;
   border-bottom: .3em solid;
   border-left: .3em solid transparent
 }
 
 .dropleft .dropdown-toggle::before,
 .dropright .dropdown-toggle::after {
   content: '';
   border-top: .3em solid transparent;
   border-bottom: .3em solid transparent
 }
 
 .dropup .dropdown-toggle:empty::after {
   margin-left: 0
 }
 
 .dropright .dropdown-menu {
   top: 0;
   right: auto;
   left: 100%;
   margin-top: 0;
   margin-left: .125rem
 }
 
 .dropright .dropdown-toggle::after {
   display: inline-block;
   margin-left: .255em;
   border-right: 0;
   border-left: .3em solid;
   vertical-align: 0
 }
 
 .dropright .dropdown-toggle:empty::after {
   margin-left: 0
 }
 
 .dropleft .dropdown-menu {
   top: 0;
   right: 100%;
   left: auto;
   margin-top: 0;
   margin-right: .125rem
 }
 
 .dropleft .dropdown-toggle::after {
   margin-left: .255em;
   content: '';
   vertical-align: .255em;
   display: none
 }
 
 .dropleft .dropdown-toggle::before {
   display: inline-block;
   margin-right: .255em;
   border-right: .3em solid;
   vertical-align: 0
 }
 
 .dropleft .dropdown-toggle:empty::after {
   margin-left: 0
 }
 
 .dropdown-menu[x-placement^=top],
 .dropdown-menu[x-placement^=right],
 .dropdown-menu[x-placement^=bottom],
 .dropdown-menu[x-placement^=left] {
   right: auto;
   bottom: auto
 }
 
 .dropdown-divider {
   overflow: hidden;
   height: 0;
   margin: .5rem 0;
   border-top: 1px solid #98a4b8
 }
 
 .btn-group-toggle>.btn,
 .btn-group-toggle>.btn-group>.btn,
 .custom-control-label,
 .custom-file,
 .dropdown-header,
 .input-group-text,
 .nav,
 .nav-tabs .nav-item,
 .navbar-nav {
   margin-bottom: 0
 }
 
 .dropdown-item {
   display: block;
   clear: both;
   width: 100%;
   padding: .25rem 1.5rem;
   text-align: inherit;
   color: #2a2e30;
   border: 0;
   background-color: transparent
 }
 
 .btn-group>.btn-group:not(:last-child)>.btn,
 .btn-group>.btn:not(:last-child):not(.dropdown-toggle),
 .input-group>.custom-file:not(:last-child) .custom-file-label,
 .input-group>.custom-file:not(:last-child) .custom-file-label::after,
 .input-group>.custom-select:not(:last-child),
 .input-group>.form-control:not(:last-child) {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0
 }
 
 .btn-group>.btn-group:not(:first-child)>.btn,
 .btn-group>.btn:not(:first-child),
 .input-group>.custom-file:not(:first-child) .custom-file-label,
 .input-group>.custom-select:not(:first-child),
 .input-group>.form-control:not(:first-child) {
   border-top-left-radius: 0;
   border-bottom-left-radius: 0
 }
 
 .dropdown-item:focus,
 .dropdown-item:hover {
   color: #1e2122;
   background-color: #f9fafd
 }
 
 .dropdown-item.active,
 .dropdown-item:active {
   text-decoration: none;
   color: #fff;
   background-color: #6967ce
 }
 
 .dropdown-item.disabled,
 .dropdown-item:disabled {
   pointer-events: none;
   color: #6b6f80;
   background-color: transparent
 }
 
 .dropdown-menu.show {
   display: block
 }
 
 .dropdown-header {
   font-size: .875rem;
   display: block;
   padding: .5rem 1.5rem;
   color: #6b6f80
 }
 
 .dropdown-item-text {
   display: block;
   padding: .25rem 1.5rem;
   color: #2a2e30
 }
 
 .btn-group,
 .btn-group-vertical {
   position: relative;
   display: -webkit-inline-box;
   display: -webkit-inline-flex;
   display: -moz-inline-box;
   display: -ms-inline-flexbox;
   display: inline-flex;
   vertical-align: middle
 }
 
 .btn-toolbar,
 .input-group {
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: -ms-flexbox
 }
 
 .btn-group-vertical>.btn,
 .btn-group>.btn {
   position: relative;
   -webkit-box-flex: 1;
   -webkit-flex: 1 1 auto;
   -moz-box-flex: 1;
   -ms-flex: 1 1 auto;
   flex: 1 1 auto
 }
 
 .btn-group-vertical>.btn.active,
 .btn-group-vertical>.btn:active,
 .btn-group-vertical>.btn:focus,
 .btn-group-vertical>.btn:hover,
 .btn-group>.btn.active,
 .btn-group>.btn:active,
 .btn-group>.btn:focus,
 .btn-group>.btn:hover {
   z-index: 1
 }
 
 .btn-toolbar {
   display: flex;
   -webkit-flex-wrap: wrap;
   -ms-flex-wrap: wrap;
   flex-wrap: wrap;
   -webkit-box-pack: start;
   -webkit-justify-content: flex-start;
   -moz-box-pack: start;
   -ms-flex-pack: start;
   justify-content: flex-start
 }
 
 .btn-toolbar .input-group {
   width: auto
 }
 
 .btn-group>.btn-group:not(:first-child),
 .btn-group>.btn:not(:first-child) {
   margin-left: -1px
 }
 
 .dropdown-toggle-split {
   padding-right: 1.125rem;
   padding-left: 1.125rem
 }
 
 .dropdown-toggle-split::after,
 .dropright .dropdown-toggle-split::after,
 .dropup .dropdown-toggle-split::after {
   margin-left: 0
 }
 
 .input-group-append,
 .input-group-append .btn+.btn,
 .input-group-append .btn+.input-group-text,
 .input-group-append .input-group-text+.btn,
 .input-group-append .input-group-text+.input-group-text,
 .input-group-prepend .btn+.btn,
 .input-group-prepend .btn+.input-group-text,
 .input-group-prepend .input-group-text+.btn,
 .input-group-prepend .input-group-text+.input-group-text,
 .input-group>.custom-file+.custom-file,
 .input-group>.custom-file+.custom-select,
 .input-group>.custom-file+.form-control,
 .input-group>.custom-select+.custom-file,
 .input-group>.custom-select+.custom-select,
 .input-group>.custom-select+.form-control,
 .input-group>.form-control+.custom-file,
 .input-group>.form-control+.custom-select,
 .input-group>.form-control+.form-control,
 .input-group>.form-control-plaintext+.custom-file,
 .input-group>.form-control-plaintext+.custom-select,
 .input-group>.form-control-plaintext+.form-control {
   margin-left: -1px
 }
 
 .dropleft .dropdown-toggle-split::before {
   margin-right: 0
 }
 
 .btn-group-sm>.btn+.dropdown-toggle-split,
 .btn-sm+.dropdown-toggle-split {
   padding-right: .75rem;
   padding-left: .75rem
 }
 
 .btn-group-lg>.btn+.dropdown-toggle-split,
 .btn-lg+.dropdown-toggle-split {
   padding-right: 1.5rem;
   padding-left: 1.5rem
 }
 
 .btn-group-vertical {
   flex-direction: column;
   -webkit-flex-direction: column;
   -moz-box-orient: vertical;
   -ms-flex-direction: column;
   -webkit-box-align: start;
   -webkit-align-items: flex-start;
   -moz-box-align: start;
   -ms-flex-align: start;
   align-items: flex-start;
   -webkit-box-pack: center;
   -webkit-justify-content: center;
   -moz-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center
 }
 
 .btn-group-vertical>.btn,
 .btn-group-vertical>.btn-group {
   width: 100%
 }
 
 .btn-group-vertical>.btn-group:not(:first-child),
 .btn-group-vertical>.btn:not(:first-child) {
   margin-top: -1px
 }
 
 .btn-group-vertical>.btn-group:not(:last-child)>.btn,
 .btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
   border-bottom-right-radius: 0;
   border-bottom-left-radius: 0
 }
 
 .btn-group-vertical>.btn-group:not(:first-child)>.btn,
 .btn-group-vertical>.btn:not(:first-child) {
   border-top-left-radius: 0;
   border-top-right-radius: 0
 }
 
 .btn-group-toggle>.btn input[type=checkbox],
 .btn-group-toggle>.btn input[type=radio],
 .btn-group-toggle>.btn-group>.btn input[type=checkbox],
 .btn-group-toggle>.btn-group>.btn input[type=radio] {
   position: absolute;
   clip: rect(0, 0, 0, 0);
   pointer-events: none
 }
 
 .input-group {
   position: relative;
   display: flex;
   width: 100%;
   -webkit-flex-wrap: wrap;
   -ms-flex-wrap: wrap;
   flex-wrap: wrap;
   -webkit-box-align: stretch;
   -webkit-align-items: stretch;
   -moz-box-align: stretch;
   -ms-flex-align: stretch;
   align-items: stretch
 }
 
 .input-group-append,
 .input-group-prepend,
 .input-group>.custom-file {
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: -ms-flexbox
 }
 
 .input-group>.custom-file,
 .input-group>.custom-select,
 .input-group>.form-control,
 .input-group>.form-control-plaintext {
   position: relative;
   width: 1%;
   margin-bottom: 0;
   -webkit-box-flex: 1;
   -webkit-flex: 1 1 auto;
   -moz-box-flex: 1;
   -ms-flex: 1 1 auto;
   flex: 1 1 auto
 }
 
 .input-group>.custom-file .custom-file-input:focus~.custom-file-label,
 .input-group>.custom-select:focus,
 .input-group>.form-control:focus {
   z-index: 3
 }
 
 .input-group>.custom-file .custom-file-input:focus {
   z-index: 4
 }
 
 .input-group>.custom-file {
   display: flex;
   -webkit-box-align: center;
   -webkit-align-items: center;
   -moz-box-align: center;
   -ms-flex-align: center;
   align-items: center
 }
 
 .input-group-append,
 .input-group-prepend {
   display: flex
 }
 
 .input-group-append .btn,
 .input-group-prepend .btn {
   position: relative;
   z-index: 2
 }
 
 .input-group-append .btn:focus,
 .input-group-prepend .btn:focus {
   z-index: 3
 }
 
 .input-group-prepend {
   margin-right: -1px
 }
 
 .input-group-text {
   font-size: 1rem;
   line-height: 1.25;
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: -ms-flexbox;
   display: flex;
   padding: .75rem 1.5rem;
   text-align: center;
   color: #4e5154;
   border: 1px solid #babfc7;
   border-radius: .25rem;
   background-color: #f9fafd;
   -webkit-box-align: center;
   -webkit-align-items: center;
   -moz-box-align: center;
   -ms-flex-align: center;
   align-items: center
 }
 
 .input-group-text input[type=checkbox],
 .input-group-text input[type=radio] {
   margin-top: 0
 }
 
 .input-group-lg>.custom-select,
 .input-group-lg>.form-control:not(textarea) {
   height: -webkit-calc(3.5625rem + 2px);
   height: -moz-calc(3.5625rem + 2px);
   height: calc(3.5625rem + 2px)
 }
 
 .input-group-lg>.custom-select,
 .input-group-lg>.form-control,
 .input-group-lg>.input-group-append>.btn,
 .input-group-lg>.input-group-append>.input-group-text,
 .input-group-lg>.input-group-prepend>.btn,
 .input-group-lg>.input-group-prepend>.input-group-text {
   font-size: 1.25rem;
   line-height: 1.25;
   padding: 1rem 2rem;
   border-radius: .35rem
 }
 
 .input-group-sm>.custom-select,
 .input-group-sm>.form-control:not(textarea) {
   height: -webkit-calc(1.875rem + 2px + 10);
   height: -moz-calc(1.875rem + 2px + 10);
   height: calc(1.875rem + 2px + 10)
 }
 
 .input-group-sm>.custom-select,
 .input-group-sm>.form-control,
 .input-group-sm>.input-group-append>.btn,
 .input-group-sm>.input-group-append>.input-group-text,
 .input-group-sm>.input-group-prepend>.btn,
 .input-group-sm>.input-group-prepend>.input-group-text {
   font-size: .875rem;
   line-height: 1;
   padding: .5rem 1rem;
   border-radius: .21rem
 }
 
 .input-group-lg>.custom-select,
 .input-group-sm>.custom-select {
   padding-right: 1.75rem
 }
 
 .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
 .input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
 .input-group>.input-group-append:not(:last-child)>.btn,
 .input-group>.input-group-append:not(:last-child)>.input-group-text,
 .input-group>.input-group-prepend>.btn,
 .input-group>.input-group-prepend>.input-group-text {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0
 }
 
 .input-group>.input-group-append>.btn,
 .input-group>.input-group-append>.input-group-text,
 .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
 .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
 .input-group>.input-group-prepend:not(:first-child)>.btn,
 .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
   border-top-left-radius: 0;
   border-bottom-left-radius: 0
 }
 
 .custom-control {
   position: relative;
   display: block;
   min-height: 1.45rem;
   padding-left: 2.5rem
 }
 
 .custom-control-inline {
   display: -webkit-inline-box;
   display: -webkit-inline-flex;
   display: -moz-inline-box;
   display: -ms-inline-flexbox;
   display: inline-flex;
   margin-right: 1rem
 }
 
 .custom-control-input {
   position: absolute;
   z-index: -1;
   opacity: 0
 }
 
 .custom-control-input:checked~.custom-control-label::before {
   color: #fff;
   border-color: #6967ce;
   background-color: #6967ce
 }
 
 .custom-control-input:focus~.custom-control-label::before {
   -webkit-box-shadow: 0 0 0 1px #f9fafd, none;
   box-shadow: 0 0 0 1px #f9fafd, none
 }
 
 .custom-control-input:focus:not(:checked)~.custom-control-label::before {
   border-color: #6967ce
 }
 
 .custom-control-input:not(:disabled):active~.custom-control-label::before {
   color: #fff;
   border-color: #eeeefa;
   background-color: #eeeefa
 }
 
 .custom-control-input:disabled~.custom-control-label {
   color: #6b6f80
 }
 
 .custom-control-input:disabled~.custom-control-label::before {
   background-color: #98a4b8
 }
 
 .custom-control-label {
   position: relative;
   vertical-align: top
 }
 
 .custom-control-label::after,
 .custom-control-label::before {
   position: absolute;
   top: .225rem;
   left: -2.5rem;
   width: 1rem;
   height: 1rem;
   content: '';
   display: block
 }
 
 .custom-control-label::before {
   pointer-events: none;
   border: 1px solid #adb5bd;
   background-color: #626e82
 }
 
 .custom-control-label::after {
   background: 50%/50% 50% no-repeat
 }
 
 .custom-checkbox .custom-control-label::before {
   border-radius: .25rem
 }
 
 .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
   background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 8 8\'%3e%3cpath fill=\'%23fff\' d=\'M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z\'/%3e%3c/svg%3e')
 }
 
 .custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
   border-color: #6967ce;
   background-color: #6967ce
 }
 
 .custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before,
 .custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before,
 .custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
   background-color: rgba(105, 103, 206, .5)
 }
 
 .custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
   background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 4\'%3e%3cpath stroke=\'%23fff\' d=\'M0 2h4\'/%3e%3c/svg%3e')
 }
 
 .custom-radio .custom-control-label::before {
   border-radius: 50%
 }
 
 .custom-radio .custom-control-input:checked~.custom-control-label::after {
   background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'-4 -4 8 8\'%3e%3ccircle r=\'3\' fill=\'%23fff\'/%3e%3c/svg%3e')
 }
 
 .custom-switch {
   padding-left: 3.25rem
 }
 
 .custom-switch .custom-control-label::before {
   left: -3.25rem;
   width: 1.75rem;
   pointer-events: all;
   border-radius: .5rem
 }
 
 .custom-switch .custom-control-label::after {
   top: -webkit-calc(.225rem + 2px);
   top: -moz-calc(.225rem + 2px);
   top: calc(.225rem + 2px);
   left: -webkit-calc(-3.25rem + 2px);
   left: -moz-calc(-3.25rem + 2px);
   left: calc(-3.25rem + 2px);
   width: -webkit-calc(1rem - 4px);
   width: -moz-calc(1rem - 4px);
   width: calc(1rem - 4px);
   height: -webkit-calc(1rem - 4px);
   height: -moz-calc(1rem - 4px);
   height: calc(1rem - 4px);
   -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-transform .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   -moz-transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -moz-transform .15s ease-in-out;
   -o-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -o-transform .15s ease-in-out;
   transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-transform .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out, -moz-transform .15s ease-in-out, -o-transform .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   border-radius: .5rem;
   background-color: #adb5bd
 }
 
 .custom-file,
 .custom-select {
   height: -webkit-calc(2.75rem + 2px)
 }
 
 @media (prefers-reduced-motion:reduce) {
   .custom-switch .custom-control-label::after {
     -webkit-transition: none;
     -moz-transition: none;
     -o-transition: none;
     transition: none
   }
 }
 
 .custom-switch .custom-control-input:checked~.custom-control-label::after {
   -webkit-transform: translateX(.75rem);
   -moz-transform: translateX(.75rem);
   -ms-transform: translateX(.75rem);
   -o-transform: translateX(.75rem);
   transform: translateX(.75rem);
   background-color: #626e82
 }
 
 .custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
   background-color: rgba(105, 103, 206, .5)
 }
 
 .custom-select {
   font-size: 1rem;
   line-height: 1.25;
   display: inline-block;
   width: 100%;
   height: -moz-calc(2.75rem + 2px);
   height: calc(2.75rem + 2px);
   padding: .375rem 1.75rem .375rem .75rem;
   vertical-align: middle;
   color: #4e5154;
   border: 1px solid #babfc7;
   border-radius: .25rem;
   background: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 5\'%3e%3cpath fill=\'%23464855\' d=\'M2 0L0 2h4zm0 5L0 3h4z\'/%3e%3c/svg%3e') right .75rem center/8px 10px no-repeat #fff;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none
 }
 
 .custom-select:focus {
   border-color: #6967ce;
   outline: 0;
   -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075), 0 0 5px rgba(105, 103, 206, .5);
   box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075), 0 0 5px rgba(105, 103, 206, .5)
 }
 
 .custom-select:focus::-ms-value {
   color: #4e5154;
   background-color: #fff
 }
 
 .custom-file-input:disabled~.custom-file-label,
 .custom-select:disabled {
   background-color: #98a4b8
 }
 
 .custom-select[multiple],
 .custom-select[size]:not([size='1']) {
   height: auto;
   padding-right: .75rem;
   background-image: none
 }
 
 .custom-select:disabled {
   color: #6b6f80
 }
 
 .custom-select::-ms-expand {
   display: none
 }
 
 .custom-select-sm {
   font-size: 75%;
   height: -webkit-calc(1.875rem + 2px + 10);
   height: -moz-calc(1.875rem + 2px + 10);
   height: calc(1.875rem + 2px + 10);
   padding-top: .5rem;
   padding-bottom: .5rem;
   padding-left: 1rem
 }
 
 .custom-select-lg {
   font-size: 125%;
   height: -webkit-calc(3.5625rem + 2px);
   height: -moz-calc(3.5625rem + 2px);
   height: calc(3.5625rem + 2px);
   padding-top: 1rem;
   padding-bottom: 1rem;
   padding-left: 2rem
 }
 
 .custom-file,
 .custom-file-input {
   position: relative;
   height: -moz-calc(2.75rem + 2px);
   width: 100%
 }
 
 .custom-file {
   display: inline-block;
   height: calc(2.75rem + 2px)
 }
 
 .custom-file-input {
   z-index: 2;
   height: -webkit-calc(2.75rem + 2px);
   height: calc(2.75rem + 2px);
   margin: 0;
   opacity: 0
 }
 
 .custom-file-label,
 .custom-file-label::after {
   line-height: 1.25;
   position: absolute;
   padding: .75rem 1.5rem;
   color: #4e5154;
   top: 0;
   right: 0
 }
 
 .custom-file-input:focus~.custom-file-label {
   border-color: #6967ce;
   -webkit-box-shadow: none;
   box-shadow: none
 }
 
 .custom-file-input:lang(en)~.custom-file-label::after {
   content: 'Browse'
 }
 
 .custom-file-input~.custom-file-label[data-browse]::after {
   content: attr(data-browse)
 }
 
 .custom-file-label {
   font-weight: 400;
   z-index: 1;
   left: 0;
   height: -webkit-calc(2.75rem + 2px);
   height: -moz-calc(2.75rem + 2px);
   height: calc(2.75rem + 2px);
   border: 1px solid #babfc7;
   border-radius: .25rem;
   background-color: #fff
 }
 
 .alert-link,
 .badge,
 .close {
   font-weight: 700
 }
 
 .custom-file-label::after {
   z-index: 3;
   bottom: 0;
   display: block;
   height: 2.75rem;
   content: 'Browse';
   border-left: inherit;
   border-radius: 0 .25rem .25rem 0;
   background-color: #f9fafd
 }
 
 .nav,
 .navbar {
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: -ms-flexbox;
   -webkit-flex-wrap: wrap
 }
 
 .custom-range {
   width: 100%;
   height: -webkit-calc(1rem + .4rem);
   height: -moz-calc(1rem + .4rem);
   height: calc(1rem + .4rem);
   padding: 0;
   background-color: transparent;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none
 }
 
 .custom-range:focus {
   outline: 0
 }
 
 .custom-range:focus::-webkit-slider-thumb {
   -webkit-box-shadow: 0 0 0 1px #f9fafd, none;
   box-shadow: 0 0 0 1px #f9fafd, none
 }
 
 .custom-range:focus::-moz-range-thumb {
   box-shadow: 0 0 0 1px #f9fafd, none
 }
 
 .custom-range:focus::-ms-thumb {
   box-shadow: 0 0 0 1px #f9fafd, none
 }
 
 .custom-range::-moz-focus-outer {
   border: 0
 }
 
 .custom-range::-webkit-slider-thumb {
   width: 1rem;
   height: 1rem;
   margin-top: -.25rem;
   -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   -o-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   border: 0;
   border-radius: 1rem;
   background-color: #6967ce;
   -webkit-appearance: none;
   appearance: none
 }
 
 @media (prefers-reduced-motion:reduce) {
   .custom-range::-webkit-slider-thumb {
     -webkit-transition: none;
     -moz-transition: none;
     -o-transition: none;
     transition: none
   }
 }
 
 .custom-range::-webkit-slider-thumb:active {
   background-color: #eeeefa
 }
 
 .custom-range::-webkit-slider-runnable-track {
   width: 100%;
   height: .5rem;
   cursor: pointer;
   color: transparent;
   border-color: transparent;
   border-radius: 1rem;
   background-color: #626e82
 }
 
 .custom-range::-moz-range-thumb {
   width: 1rem;
   height: 1rem;
   -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   -o-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   border: 0;
   border-radius: 1rem;
   background-color: #6967ce;
   -moz-appearance: none;
   appearance: none
 }
 
 @media (prefers-reduced-motion:reduce) {
   .custom-range::-moz-range-thumb {
     -webkit-transition: none;
     -moz-transition: none;
     -o-transition: none;
     transition: none
   }
 }
 
 .custom-range::-moz-range-thumb:active {
   background-color: #eeeefa
 }
 
 .custom-range::-moz-range-track {
   width: 100%;
   height: .5rem;
   cursor: pointer;
   color: transparent;
   border-color: transparent;
   border-radius: 1rem;
   background-color: #626e82
 }
 
 .custom-range::-ms-thumb {
   width: 1rem;
   height: 1rem;
   margin-top: 0;
   margin-right: .2rem;
   margin-left: .2rem;
   -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   -o-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   border: 0;
   border-radius: 1rem;
   background-color: #6967ce;
   appearance: none
 }
 
 @media (prefers-reduced-motion:reduce) {
   .custom-range::-ms-thumb {
     -webkit-transition: none;
     -moz-transition: none;
     -o-transition: none;
     transition: none
   }
 }
 
 .custom-range::-ms-thumb:active {
   background-color: #eeeefa
 }
 
 .custom-range::-ms-track {
   width: 100%;
   height: .5rem;
   cursor: pointer;
   color: transparent;
   border-width: .5rem;
   border-color: transparent;
   background-color: transparent
 }
 
 .custom-range::-ms-fill-lower {
   border-radius: 1rem;
   background-color: #626e82
 }
 
 .custom-range::-ms-fill-upper {
   margin-right: 15px;
   border-radius: 1rem;
   background-color: #626e82
 }
 
 .custom-range:disabled::-webkit-slider-thumb {
   background-color: #adb5bd
 }
 
 .custom-range:disabled::-webkit-slider-runnable-track {
   cursor: default
 }
 
 .custom-range:disabled::-moz-range-thumb {
   background-color: #adb5bd
 }
 
 .custom-range:disabled::-moz-range-track {
   cursor: default
 }
 
 .custom-range:disabled::-ms-thumb {
   background-color: #adb5bd
 }
 
 .custom-control-label::before,
 .custom-file-label,
 .custom-select {
   -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   -o-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out
 }
 
 @media (prefers-reduced-motion:reduce) {
 
   .custom-control-label::before,
   .custom-file-label,
   .custom-select {
     -webkit-transition: none;
     -moz-transition: none;
     -o-transition: none;
     transition: none
   }
 }
 
 .nav {
   display: flex;
   padding-left: 0;
   -ms-flex-wrap: wrap;
   flex-wrap: wrap
 }
 
 .nav-link,
 .navbar {
   padding: .5rem 1rem
 }
 
 .nav-link {
   display: block
 }
 
 .nav-link.disabled {
   cursor: default;
   pointer-events: none;
   color: #6b6f80
 }
 
 .nav-tabs {
   border-bottom: 0 solid #babfc7
 }
 
 .nav-tabs .nav-link {
   border: 0 solid transparent;
   border-top-left-radius: .25rem;
   border-top-right-radius: .25rem
 }
 
 .nav-tabs .nav-link:focus,
 .nav-tabs .nav-link:hover {
   border-color: #babfc7
 }
 
 .nav-tabs .nav-link.disabled {
   color: #6b6f80;
   border-color: transparent;
   background-color: transparent
 }
 
 .nav-tabs .nav-item.show .nav-link,
 .nav-tabs .nav-link.active {
   color: #fff;
   border-color: #babfc7 #626e82 #6967ce;
   background-color: #6967ce
 }
 
 .nav-tabs .dropdown-menu {
   margin-top: 0;
   border-top-left-radius: 0;
   border-top-right-radius: 0
 }
 
 .nav-pills .nav-link {
   border-radius: .25rem
 }
 
 .nav-pills .nav-link.active,
 .nav-pills .show>.nav-link {
   color: #fff;
   background-color: #6967ce
 }
 
 .nav-fill .nav-item {
   text-align: center;
   -webkit-box-flex: 1;
   -webkit-flex: 1 1 auto;
   -moz-box-flex: 1;
   -ms-flex: 1 1 auto;
   flex: 1 1 auto
 }
 
 .nav-justified .nav-item {
   text-align: center;
   -webkit-flex-basis: 0;
   -ms-flex-preferred-size: 0;
   flex-basis: 0;
   -webkit-box-flex: 1;
   -webkit-flex-grow: 1;
   -moz-box-flex: 1;
   -ms-flex-positive: 1;
   flex-grow: 1
 }
 
 .tab-content>.tab-pane {
   display: none
 }
 
 .tab-content>.active {
   display: block
 }
 
 .navbar {
   position: relative;
   display: flex;
   -ms-flex-wrap: wrap;
   flex-wrap: wrap;
   -webkit-box-align: center;
   -webkit-align-items: center;
   -moz-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: justify;
   -webkit-justify-content: space-between;
   -moz-box-pack: justify;
   -ms-flex-pack: justify;
   justify-content: space-between
 }
 
 .navbar>.container,
 .navbar>.container-fluid {
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-flex-wrap: wrap;
   -ms-flex-wrap: wrap;
   flex-wrap: wrap;
   -webkit-box-align: center;
   -webkit-align-items: center;
   -moz-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: justify;
   -webkit-justify-content: space-between;
   -moz-box-pack: justify;
   -ms-flex-pack: justify;
   justify-content: space-between
 }
 
 .navbar-brand {
   font-size: 1.25rem;
   line-height: inherit;
   display: inline-block;
   margin-right: 1rem;
   padding-top: .31875rem;
   padding-bottom: .31875rem
 }
 
 .card,
 .navbar-nav {
   display: -webkit-flex;
   display: -ms-flexbox;
   -moz-box-orient: vertical
 }
 
 .navbar-nav {
   display: -webkit-box;
   display: -moz-box;
   display: flex;
   flex-direction: column;
   padding-left: 0;
   -webkit-flex-direction: column;
   -ms-flex-direction: column
 }
 
 .navbar-expand,
 .navbar-expand .navbar-nav {
   -webkit-box-orient: horizontal;
   -moz-box-orient: horizontal
 }
 
 .navbar-nav .nav-link {
   padding-right: 0;
   padding-left: 0
 }
 
 .navbar-nav .dropdown-menu {
   position: static;
   float: none
 }
 
 .navbar-text {
   display: inline-block;
   padding-top: .5rem;
   padding-bottom: .5rem
 }
 
 .navbar-collapse {
   -webkit-flex-basis: 100%;
   -ms-flex-preferred-size: 100%;
   flex-basis: 100%;
   -webkit-box-flex: 1;
   -webkit-flex-grow: 1;
   -moz-box-flex: 1;
   -ms-flex-positive: 1;
   flex-grow: 1;
   -webkit-box-align: center;
   -webkit-align-items: center;
   -moz-box-align: center;
   -ms-flex-align: center;
   align-items: center
 }
 
 .navbar-toggler {
   font-size: 1.25rem;
   line-height: 1;
   padding: .25rem .75rem;
   border: 1px solid transparent;
   border-radius: .25rem;
   background-color: transparent
 }
 
 .navbar-toggler-icon {
   display: inline-block;
   width: 1.5em;
   height: 1.5em;
   content: '';
   vertical-align: middle;
   background: center center no-repeat;
   -webkit-background-size: 100% 100%;
   background-size: 100% 100%
 }
 
 .breadcrumb,
 .card,
 .card-footer,
 .card-header,
 .page-link {
   background-color: #fff
 }
 
 @media (max-width:575.98px) {
 
   .navbar-expand-sm>.container,
   .navbar-expand-sm>.container-fluid {
     padding-right: 0;
     padding-left: 0
   }
 }
 
 @media (min-width:576px) {
 
   .navbar-expand-sm,
   .navbar-expand-sm .navbar-nav {
     -webkit-box-orient: horizontal;
     -webkit-box-direction: normal;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal
   }
 
   .navbar-expand-sm {
     -webkit-flex-flow: row nowrap;
     -ms-flex-flow: row nowrap;
     flex-flow: row nowrap;
     -webkit-box-pack: start;
     -webkit-justify-content: flex-start;
     -moz-box-pack: start;
     -ms-flex-pack: start;
     justify-content: flex-start
   }
 
   .navbar-expand-sm .navbar-nav {
     flex-direction: row;
     -webkit-flex-direction: row;
     -ms-flex-direction: row
   }
 
   .navbar-expand-sm .navbar-nav .dropdown-menu {
     position: absolute
   }
 
   .navbar-expand-sm .navbar-nav .nav-link {
     padding-right: .5rem;
     padding-left: .5rem
   }
 
   .navbar-expand-sm>.container,
   .navbar-expand-sm>.container-fluid {
     -webkit-flex-wrap: nowrap;
     -ms-flex-wrap: nowrap;
     flex-wrap: nowrap
   }
 
   .navbar-expand-sm .navbar-collapse {
     display: -webkit-box !important;
     display: -webkit-flex !important;
     display: -moz-box !important;
     display: -ms-flexbox !important;
     display: flex !important;
     -webkit-flex-basis: auto;
     -ms-flex-preferred-size: auto;
     flex-basis: auto
   }
 
   .navbar-expand-sm .navbar-toggler {
     display: none
   }
 }
 
 @media (max-width:767.98px) {
 
   .navbar-expand-md>.container,
   .navbar-expand-md>.container-fluid {
     padding-right: 0;
     padding-left: 0
   }
 }
 
 @media (min-width:768px) {
 
   .navbar-expand-md,
   .navbar-expand-md .navbar-nav {
     -webkit-box-orient: horizontal;
     -webkit-box-direction: normal;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal
   }
 
   .navbar-expand-md {
     -webkit-flex-flow: row nowrap;
     -ms-flex-flow: row nowrap;
     flex-flow: row nowrap;
     -webkit-box-pack: start;
     -webkit-justify-content: flex-start;
     -moz-box-pack: start;
     -ms-flex-pack: start;
     justify-content: flex-start
   }
 
   .navbar-expand-md .navbar-nav {
     flex-direction: row;
     -webkit-flex-direction: row;
     -ms-flex-direction: row
   }
 
   .navbar-expand-md .navbar-nav .dropdown-menu {
     position: absolute
   }
 
   .navbar-expand-md .navbar-nav .nav-link {
     padding-right: .5rem;
     padding-left: .5rem
   }
 
   .navbar-expand-md>.container,
   .navbar-expand-md>.container-fluid {
     -webkit-flex-wrap: nowrap;
     -ms-flex-wrap: nowrap;
     flex-wrap: nowrap
   }
 
   .navbar-expand-md .navbar-collapse {
     display: -webkit-box !important;
     display: -webkit-flex !important;
     display: -moz-box !important;
     display: -ms-flexbox !important;
     display: flex !important;
     -webkit-flex-basis: auto;
     -ms-flex-preferred-size: auto;
     flex-basis: auto
   }
 
   .navbar-expand-md .navbar-toggler {
     display: none
   }
 }
 
 @media (max-width:991.98px) {
 
   .navbar-expand-lg>.container,
   .navbar-expand-lg>.container-fluid {
     padding-right: 0;
     padding-left: 0
   }
 }
 
 @media (min-width:992px) {
 
   .navbar-expand-lg,
   .navbar-expand-lg .navbar-nav {
     -webkit-box-orient: horizontal;
     -webkit-box-direction: normal;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal
   }
 
   .navbar-expand-lg {
     -webkit-flex-flow: row nowrap;
     -ms-flex-flow: row nowrap;
     flex-flow: row nowrap;
     -webkit-box-pack: start;
     -webkit-justify-content: flex-start;
     -moz-box-pack: start;
     -ms-flex-pack: start;
     justify-content: flex-start
   }
 
   .navbar-expand-lg .navbar-nav {
     flex-direction: row;
     -webkit-flex-direction: row;
     -ms-flex-direction: row
   }
 
   .navbar-expand-lg .navbar-nav .dropdown-menu {
     position: absolute
   }
 
   .navbar-expand-lg .navbar-nav .nav-link {
     padding-right: .5rem;
     padding-left: .5rem
   }
 
   .navbar-expand-lg>.container,
   .navbar-expand-lg>.container-fluid {
     -webkit-flex-wrap: nowrap;
     -ms-flex-wrap: nowrap;
     flex-wrap: nowrap
   }
 
   .navbar-expand-lg .navbar-collapse {
     display: -webkit-box !important;
     display: -webkit-flex !important;
     display: -moz-box !important;
     display: -ms-flexbox !important;
     display: flex !important;
     -webkit-flex-basis: auto;
     -ms-flex-preferred-size: auto;
     flex-basis: auto
   }
 
   .navbar-expand-lg .navbar-toggler {
     display: none
   }
 }
 
 @media (max-width:1199.98px) {
 
   .navbar-expand-xl>.container,
   .navbar-expand-xl>.container-fluid {
     padding-right: 0;
     padding-left: 0
   }
 }
 
 @media (min-width:1200px) {
 
   .navbar-expand-xl,
   .navbar-expand-xl .navbar-nav {
     -webkit-box-orient: horizontal;
     -webkit-box-direction: normal;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal
   }
 
   .navbar-expand-xl {
     -webkit-flex-flow: row nowrap;
     -ms-flex-flow: row nowrap;
     flex-flow: row nowrap;
     -webkit-box-pack: start;
     -webkit-justify-content: flex-start;
     -moz-box-pack: start;
     -ms-flex-pack: start;
     justify-content: flex-start
   }
 
   .navbar-expand-xl .navbar-nav {
     flex-direction: row;
     -webkit-flex-direction: row;
     -ms-flex-direction: row
   }
 
   .navbar-expand-xl .navbar-nav .dropdown-menu {
     position: absolute
   }
 
   .navbar-expand-xl .navbar-nav .nav-link {
     padding-right: .5rem;
     padding-left: .5rem
   }
 
   .navbar-expand-xl>.container,
   .navbar-expand-xl>.container-fluid {
     -webkit-flex-wrap: nowrap;
     -ms-flex-wrap: nowrap;
     flex-wrap: nowrap
   }
 
   .navbar-expand-xl .navbar-collapse {
     display: -webkit-box !important;
     display: -webkit-flex !important;
     display: -moz-box !important;
     display: -ms-flexbox !important;
     display: flex !important;
     -webkit-flex-basis: auto;
     -ms-flex-preferred-size: auto;
     flex-basis: auto
   }
 
   .navbar-expand-xl .navbar-toggler {
     display: none
   }
 }
 
 .navbar-expand {
   -webkit-box-direction: normal;
   -webkit-flex-flow: row nowrap;
   -moz-box-direction: normal;
   -ms-flex-flow: row nowrap;
   flex-flow: row nowrap;
   -webkit-box-pack: start;
   -webkit-justify-content: flex-start;
   -moz-box-pack: start;
   -ms-flex-pack: start;
   justify-content: flex-start
 }
 
 .navbar-expand>.container,
 .navbar-expand>.container-fluid {
   padding-right: 0;
   padding-left: 0
 }
 
 .navbar-expand .navbar-nav {
   flex-direction: row;
   -webkit-box-direction: normal;
   -webkit-flex-direction: row;
   -moz-box-direction: normal;
   -ms-flex-direction: row
 }
 
 .card,
 .card-deck {
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -moz-box-direction: normal
 }
 
 .navbar-expand .navbar-nav .dropdown-menu {
   position: absolute
 }
 
 .navbar-expand .navbar-nav .nav-link {
   padding-right: .5rem;
   padding-left: .5rem
 }
 
 .navbar-expand>.container,
 .navbar-expand>.container-fluid {
   -webkit-flex-wrap: nowrap;
   -ms-flex-wrap: nowrap;
   flex-wrap: nowrap
 }
 
 .navbar-expand .navbar-collapse {
   display: -webkit-box !important;
   display: -webkit-flex !important;
   display: -moz-box !important;
   display: -ms-flexbox !important;
   display: flex !important;
   -webkit-flex-basis: auto;
   -ms-flex-preferred-size: auto;
   flex-basis: auto
 }
 
 .navbar-expand .navbar-toggler {
   display: none
 }
 
 .navbar-light .navbar-brand,
 .navbar-light .navbar-brand:focus,
 .navbar-light .navbar-brand:hover {
   color: #464855
 }
 
 .navbar-light .navbar-nav .nav-link {
   color: #6b6f80
 }
 
 .navbar-light .navbar-nav .nav-link:focus,
 .navbar-light .navbar-nav .nav-link:hover {
   color: #626e82
 }
 
 .navbar-light .navbar-nav .nav-link.disabled {
   color: #f9fafd
 }
 
 .navbar-light .navbar-nav .active>.nav-link,
 .navbar-light .navbar-nav .nav-link.active,
 .navbar-light .navbar-nav .nav-link.show,
 .navbar-light .navbar-nav .show>.nav-link {
   color: #464855
 }
 
 .navbar-light .navbar-toggler {
   color: #6b6f80;
   border-color: rgba(0, 0, 0, .1)
 }
 
 .navbar-light .navbar-toggler-icon {
   background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox=\'0 0 30 30\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath stroke=\'%236b6f80\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-miterlimit=\'10\' d=\'M4 7h22M4 15h22M4 23h22\'/%3E%3C/svg%3E')
 }
 
 .navbar-light .navbar-text {
   color: #6b6f80
 }
 
 .navbar-light .navbar-text a,
 .navbar-light .navbar-text a:focus,
 .navbar-light .navbar-text a:hover {
   color: #464855
 }
 
 .navbar-dark .navbar-brand,
 .navbar-dark .navbar-brand:focus,
 .navbar-dark .navbar-brand:hover,
 .navbar-dark .navbar-nav .nav-link {
   color: #fff
 }
 
 .navbar-dark .navbar-nav .nav-link:focus,
 .navbar-dark .navbar-nav .nav-link:hover {
   color: rgba(255, 255, 255, .9)
 }
 
 .navbar-dark .navbar-nav .nav-link.disabled {
   color: rgba(255, 255, 255, .5)
 }
 
 .breadcrumb-item.active,
 .navbar-dark .navbar-nav .active>.nav-link,
 .navbar-dark .navbar-nav .nav-link.active,
 .navbar-dark .navbar-nav .nav-link.show,
 .navbar-dark .navbar-nav .show>.nav-link,
 .navbar-dark .navbar-text,
 .navbar-dark .navbar-text a,
 .navbar-dark .navbar-text a:focus,
 .navbar-dark .navbar-text a:hover,
 .navbar-dark .navbar-toggler {
   color: #fff
 }
 
 .navbar-dark .navbar-toggler {
   border-color: rgba(255, 255, 255, .1)
 }
 
 .navbar-dark .navbar-toggler-icon {
   background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox=\'0 0 30 30\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath stroke=\'white\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-miterlimit=\'10\' d=\'M4 7h22M4 15h22M4 23h22\'/%3E%3C/svg%3E')
 }
 
 .card {
   position: relative;
   display: -webkit-box;
   display: -moz-box;
   display: flex;
   flex-direction: column;
   min-width: 0;
   word-wrap: break-word;
   border: 1px solid transparent;
   border-radius: .35rem;
   background-clip: border-box;
   -webkit-flex-direction: column;
   -ms-flex-direction: column
 }
 
 .card>hr {
   margin-right: 0;
   margin-left: 0
 }
 
 .card>.list-group:first-child .list-group-item:first-child {
   border-top-left-radius: .35rem;
   border-top-right-radius: .35rem
 }
 
 .card>.list-group:last-child .list-group-item:last-child {
   border-bottom-right-radius: .35rem;
   border-bottom-left-radius: .35rem
 }
 
 .card-body {
   padding: 1.5rem;
   -webkit-box-flex: 1;
   -webkit-flex: 1 1 auto;
   -moz-box-flex: 1;
   -ms-flex: 1 1 auto;
   flex: 1 1 auto
 }
 
 .card-title {
   margin-bottom: 1.5rem
 }
 
 .card-header,
 .card-subtitle,
 .card-text:last-child {
   margin-bottom: 0
 }
 
 .card-subtitle {
   margin-top: -.75rem
 }
 
 .card-link+.card-link {
   margin-left: 1.5rem
 }
 
 .card-header-pills,
 .card-header-tabs {
   margin-right: -.75rem;
   margin-left: -.75rem
 }
 
 .card-header {
   padding: 1.5rem;
   border-bottom: 1px solid transparent
 }
 
 .card-header:first-child {
   border-radius: -webkit-calc(.35rem - 1px) -webkit-calc(.35rem - 1px) 0 0;
   border-radius: -moz-calc(.35rem - 1px) -moz-calc(.35rem - 1px) 0 0;
   border-radius: calc(.35rem - 1px) calc(.35rem - 1px) 0 0
 }
 
 .card-header+.list-group .list-group-item:first-child {
   border-top: 0
 }
 
 .card-footer {
   padding: 1.5rem;
   border-top: 1px solid transparent
 }
 
 .card-footer:last-child {
   border-radius: 0 0 -webkit-calc(.35rem - 1px) -webkit-calc(.35rem - 1px);
   border-radius: 0 0 -moz-calc(.35rem - 1px) -moz-calc(.35rem - 1px);
   border-radius: 0 0 calc(.35rem - 1px) calc(.35rem - 1px)
 }
 
 .card-header-tabs {
   margin-bottom: -1.5rem;
   border-bottom: 0
 }
 
 .card-img-overlay {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   padding: 1.25rem
 }
 
 .alert,
 .btn .badge,
 .page-link {
   position: relative
 }
 
 .card-img {
   width: 100%;
   border-radius: -webkit-calc(.35rem - 1px);
   border-radius: -moz-calc(.35rem - 1px);
   border-radius: calc(.35rem - 1px)
 }
 
 .card-img-top {
   width: 100%;
   border-top-left-radius: -webkit-calc(.35rem - 1px);
   border-top-left-radius: -moz-calc(.35rem - 1px);
   border-top-left-radius: calc(.35rem - 1px);
   border-top-right-radius: -webkit-calc(.35rem - 1px);
   border-top-right-radius: -moz-calc(.35rem - 1px);
   border-top-right-radius: calc(.35rem - 1px)
 }
 
 .card-img-bottom {
   width: 100%;
   border-bottom-right-radius: -webkit-calc(.35rem - 1px);
   border-bottom-right-radius: -moz-calc(.35rem - 1px);
   border-bottom-right-radius: calc(.35rem - 1px);
   border-bottom-left-radius: -webkit-calc(.35rem - 1px);
   border-bottom-left-radius: -moz-calc(.35rem - 1px);
   border-bottom-left-radius: calc(.35rem - 1px)
 }
 
 .card-deck {
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: -ms-flexbox;
   display: flex;
   flex-direction: column;
   -webkit-flex-direction: column;
   -moz-box-orient: vertical;
   -ms-flex-direction: column
 }
 
 .card-deck .card {
   margin-bottom: 15px
 }
 
 @media (min-width:576px) {
 
   .card-deck,
   .card-deck .card {
     -webkit-box-direction: normal;
     -moz-box-direction: normal
   }
 
   .card-deck {
     margin-right: -15px;
     margin-left: -15px;
     -webkit-box-orient: horizontal;
     -webkit-flex-flow: row wrap;
     -moz-box-orient: horizontal;
     -ms-flex-flow: row wrap;
     flex-flow: row wrap
   }
 
   .card-deck .card {
     display: -webkit-box;
     display: -webkit-flex;
     display: -moz-box;
     display: -ms-flexbox;
     display: flex;
     flex-direction: column;
     margin-right: 15px;
     margin-bottom: 0;
     margin-left: 15px;
     -webkit-box-flex: 1;
     -webkit-flex: 1 0 0;
     -moz-box-flex: 1;
     -ms-flex: 1 0 0;
     flex: 1 0 0;
     -webkit-box-orient: vertical;
     -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -ms-flex-direction: column
   }
 }
 
 .card-group {
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: -ms-flexbox;
   display: flex;
   flex-direction: column;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -webkit-flex-direction: column;
   -moz-box-orient: vertical;
   -moz-box-direction: normal;
   -ms-flex-direction: column
 }
 
 .card-group>.card {
   margin-bottom: 15px
 }
 
 @media (min-width:576px) {
   .card-group {
     -webkit-box-orient: horizontal;
     -webkit-box-direction: normal;
     -webkit-flex-flow: row wrap;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
     -ms-flex-flow: row wrap;
     flex-flow: row wrap
   }
 
   .card-group>.card {
     margin-bottom: 0;
     -webkit-box-flex: 1;
     -webkit-flex: 1 0 0;
     -moz-box-flex: 1;
     -ms-flex: 1 0 0;
     flex: 1 0 0
   }
 
   .card-group>.card+.card {
     margin-left: 0;
     border-left: 0
   }
 
   .card-group>.card:not(:last-child) {
     border-top-right-radius: 0;
     border-bottom-right-radius: 0
   }
 
   .card-group>.card:not(:last-child) .card-header,
   .card-group>.card:not(:last-child) .card-img-top {
     border-top-right-radius: 0
   }
 
   .card-group>.card:not(:last-child) .card-footer,
   .card-group>.card:not(:last-child) .card-img-bottom {
     border-bottom-right-radius: 0
   }
 
   .card-group>.card:not(:first-child) {
     border-top-left-radius: 0;
     border-bottom-left-radius: 0
   }
 
   .card-group>.card:not(:first-child) .card-header,
   .card-group>.card:not(:first-child) .card-img-top {
     border-top-left-radius: 0
   }
 
   .card-group>.card:not(:first-child) .card-footer,
   .card-group>.card:not(:first-child) .card-img-bottom {
     border-bottom-left-radius: 0
   }
 
   .card-columns {
     -webkit-column-count: 3;
     -moz-column-count: 3;
     column-count: 3;
     -webkit-column-gap: 1.25rem;
     -moz-column-gap: 1.25rem;
     column-gap: 1.25rem;
     orphans: 1;
     widows: 1
   }
 
   .card-columns .card {
     display: inline-block;
     width: 100%
   }
 }
 
 .list-group,
 .progress-bar {
   -webkit-box-orient: vertical;
   -moz-box-orient: vertical;
   -webkit-box-direction: normal;
   -moz-box-direction: normal
 }
 
 .card-columns .card {
   margin-bottom: 1.5rem
 }
 
 .accordion>.card:not(:first-of-type) .card-header:first-child {
   border-radius: 0
 }
 
 .accordion>.card:not(:first-of-type):not(:last-of-type) {
   border-bottom: 0;
   border-radius: 0
 }
 
 .accordion>.card:first-of-type {
   border-bottom: 0;
   border-bottom-right-radius: 0;
   border-bottom-left-radius: 0
 }
 
 .accordion>.card:last-of-type {
   border-top-left-radius: 0;
   border-top-right-radius: 0
 }
 
 .breadcrumb,
 .pagination {
   border-radius: .25rem;
   list-style: none
 }
 
 .accordion>.card .card-header {
   margin-bottom: -1px
 }
 
 .breadcrumb {
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: -ms-flexbox;
   display: flex;
   margin-bottom: 1rem;
   padding: .75rem 0;
   -webkit-flex-wrap: wrap;
   -ms-flex-wrap: wrap;
   flex-wrap: wrap
 }
 
 .breadcrumb-item+.breadcrumb-item {
   padding-left: .5rem
 }
 
 .breadcrumb-item+.breadcrumb-item::before {
   display: inline-block;
   padding-right: .5rem;
   content: '/';
   color: #fff
 }
 
 .pagination,
 .progress {
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: -ms-flexbox
 }
 
 .carousel-inner::after,
 .clearfix::after,
 .embed-responsive::before,
 .modal-dialog-centered::before,
 .popover .arrow::after,
 .popover .arrow::before,
 .stretched-link::after,
 .tooltip .arrow::before {
   content: ''
 }
 
 .pagination {
   display: flex;
   padding-left: 0
 }
 
 .page-link {
   line-height: 1.25;
   display: block;
   margin-left: -1px;
   padding: .5rem .75rem;
   color: #5654c8;
   border: 1px solid #626e82
 }
 
 .page-link:hover {
   z-index: 2;
   color: #4340c2;
   border-color: #626e82;
   background-color: #f9fafd
 }
 
 .page-link:focus {
   z-index: 2;
   outline: 0;
   -webkit-box-shadow: none;
   box-shadow: none
 }
 
 .page-item:first-child .page-link {
   margin-left: 0;
   border-top-left-radius: .25rem;
   border-bottom-left-radius: .25rem
 }
 
 .page-item:last-child .page-link {
   border-top-right-radius: .25rem;
   border-bottom-right-radius: .25rem
 }
 
 .page-item.active .page-link {
   z-index: 1;
   color: #fff;
   border-color: #6967ce;
   background-color: #6967ce
 }
 
 .page-item.disabled .page-link {
   cursor: auto;
   pointer-events: none;
   color: #6b6f80;
   border-color: #626e82;
   background-color: #fff
 }
 
 .pagination-lg .page-link {
   font-size: 1.25rem;
   line-height: 1.25;
   padding: .75rem 1.5rem
 }
 
 .badge,
 .close {
   line-height: 1
 }
 
 .pagination-lg .page-item:first-child .page-link {
   border-top-left-radius: .35rem;
   border-bottom-left-radius: .35rem
 }
 
 .pagination-lg .page-item:last-child .page-link {
   border-top-right-radius: .35rem;
   border-bottom-right-radius: .35rem
 }
 
 .pagination-sm .page-link {
   font-size: .875rem;
   line-height: 1;
   padding: .25rem .5rem
 }
 
 .pagination-sm .page-item:first-child .page-link {
   border-top-left-radius: .21rem;
   border-bottom-left-radius: .21rem
 }
 
 .pagination-sm .page-item:last-child .page-link {
   border-top-right-radius: .21rem;
   border-bottom-right-radius: .21rem
 }
 
 .badge {
   font-size: 85%;
   display: inline-block;
   padding: .35em .4em;
   -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   -moz-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   -o-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
   text-align: center;
   vertical-align: baseline;
   border-radius: .25rem
 }
 
 @media (prefers-reduced-motion:reduce) {
   .badge {
     -webkit-transition: none;
     -moz-transition: none;
     -o-transition: none;
     transition: none
   }
 }
 
 .badge:empty {
   display: none
 }
 
 .btn .badge {
   top: -1px
 }
 
 .badge-pill {
   padding-right: .6em;
   padding-left: .6em;
   border-radius: 10rem
 }
 
 .badge-primary {
   color: #fff;
   background-color: #6967ce
 }
 
 a.badge-primary:focus,
 a.badge-primary:hover {
   color: #fff;
   background-color: #4340c2
 }
 
 a.badge-primary.focus,
 a.badge-primary:focus {
   outline: 0;
   -webkit-box-shadow: 0 0 0 .2rem rgba(105, 103, 206, .5);
   box-shadow: 0 0 0 .2rem rgba(105, 103, 206, .5)
 }
 
 .badge-secondary {
   color: #fff;
   background-color: #6b6f80
 }
 
 a.badge-secondary:focus,
 a.badge-secondary:hover {
   color: #fff;
   background-color: #545764
 }
 
 a.badge-secondary.focus,
 a.badge-secondary:focus {
   outline: 0;
   -webkit-box-shadow: 0 0 0 .2rem rgba(107, 111, 128, .5);
   box-shadow: 0 0 0 .2rem rgba(107, 111, 128, .5)
 }
 
 .badge-success {
   color: #2a2e30;
   background-color: #5ed84f
 }
 
 a.badge-success:focus,
 a.badge-success:hover {
   color: #2a2e30;
   background-color: #3dc82c
 }
 
 a.badge-success.focus,
 a.badge-success:focus {
   outline: 0;
   -webkit-box-shadow: 0 0 0 .2rem rgba(94, 216, 79, .5);
   box-shadow: 0 0 0 .2rem rgba(94, 216, 79, .5)
 }
 
 .badge-info {
   color: #fff;
   background-color: #28afd0
 }
 
 a.badge-info:focus,
 a.badge-info:hover {
   color: #fff;
   background-color: #208ba5
 }
 
 a.badge-info.focus,
 a.badge-info:focus {
   outline: 0;
   -webkit-box-shadow: 0 0 0 .2rem rgba(40, 175, 208, .5);
   box-shadow: 0 0 0 .2rem rgba(40, 175, 208, .5)
 }
 
 .badge-warning {
   color: #2a2e30;
   background-color: #fdb901
 }
 
 a.badge-warning:focus,
 a.badge-warning:hover {
   color: #2a2e30;
   background-color: #ca9401
 }
 
 a.badge-warning.focus,
 a.badge-warning:focus {
   outline: 0;
   -webkit-box-shadow: 0 0 0 .2rem rgba(253, 185, 1, .5);
   box-shadow: 0 0 0 .2rem rgba(253, 185, 1, .5)
 }
 
 .badge-danger {
   color: #fff;
   background-color: #fa626b
 }
 
 a.badge-danger:focus,
 a.badge-danger:hover {
   color: #fff;
   background-color: #f8313c
 }
 
 a.badge-danger.focus,
 a.badge-danger:focus {
   outline: 0;
   -webkit-box-shadow: 0 0 0 .2rem rgba(250, 98, 107, .5);
   box-shadow: 0 0 0 .2rem rgba(250, 98, 107, .5)
 }
 
 .badge-light {
   color: #2a2e30;
   background-color: #babfc7
 }
 
 a.badge-light:focus,
 a.badge-light:hover {
   color: #2a2e30;
   background-color: #9ea5b0
 }
 
 a.badge-light.focus,
 a.badge-light:focus {
   outline: 0;
   -webkit-box-shadow: 0 0 0 .2rem rgba(186, 191, 199, .5);
   box-shadow: 0 0 0 .2rem rgba(186, 191, 199, .5)
 }
 
 .badge-dark {
   color: #fff;
   background-color: #464855
 }
 
 a.badge-dark:focus,
 a.badge-dark:hover {
   color: #fff;
   background-color: #2f3039
 }
 
 a.badge-dark.focus,
 a.badge-dark:focus {
   outline: 0;
   -webkit-box-shadow: 0 0 0 .2rem rgba(70, 72, 85, .5);
   box-shadow: 0 0 0 .2rem rgba(70, 72, 85, .5)
 }
 
 .jumbotron {
   margin-bottom: 2rem;
   padding: 2rem 1rem;
   border-radius: .35rem;
   background-color: #98a4b8
 }
 
 @media (min-width:576px) {
   .jumbotron {
     padding: 4rem 2rem
   }
 }
 
 .jumbotron-fluid {
   padding-right: 0;
   padding-left: 0;
   border-radius: 0
 }
 
 .alert {
   margin-bottom: 1rem;
   padding: 1rem 1.25rem;
   border: 1px solid transparent;
   border-radius: .25rem
 }
 
 .alert-heading {
   color: inherit
 }
 
 .alert-dismissible {
   padding-right: 4rem
 }
 
 .alert-dismissible .close {
   position: absolute;
   top: 0;
   right: 0;
   padding: 1rem 1.25rem;
   color: inherit
 }
 
 .alert-primary {
   color: #37366b;
   border-color: #d5d4f1;
   background-color: #e1e1f5
 }
 
 .alert-primary hr {
   border-top-color: #c2c1eb
 }
 
 .alert-primary .alert-link {
   color: #262549
 }
 
 .alert-secondary {
   color: #383a43;
   border-color: #d6d7db;
   background-color: #e1e2e6
 }
 
 .alert-secondary hr {
   border-top-color: #c8cacf
 }
 
 .alert-secondary .alert-link {
   color: #212227
 }
 
 .alert-success {
   color: #317029;
   border-color: #d2f4ce;
   background-color: #dff7dc
 }
 
 .alert-success hr {
   border-top-color: #bfefb9
 }
 
 .alert-success .alert-link {
   color: #214b1b
 }
 
 .alert-info {
   color: #155b6c;
   border-color: #c3e9f2;
   background-color: #d4eff6
 }
 
 .alert-info hr {
   border-top-color: #aee1ed
 }
 
 .alert-info .alert-link {
   color: #0d3741
 }
 
 .alert-warning {
   color: #846001;
   border-color: #feebb8;
   background-color: #fff1cc
 }
 
 .alert-warning hr {
   border-top-color: #fee49f
 }
 
 .alert-warning .alert-link {
   color: #513b01
 }
 
 .alert-danger {
   color: #823338;
   border-color: #fed3d6;
   background-color: #fee0e1
 }
 
 .alert-danger hr {
   border-top-color: #fdbabf
 }
 
 .alert-danger .alert-link {
   color: #5d2528
 }
 
 .alert-light {
   color: #616367;
   border-color: #ecedef;
   background-color: #f1f2f4
 }
 
 .alert-light hr {
   border-top-color: #dee0e3
 }
 
 .alert-light .alert-link {
   color: #484a4d
 }
 
 .alert-dark {
   color: #24252c;
   border-color: #cbcccf;
   background-color: #dadadd
 }
 
 .alert-dark hr {
   border-top-color: #bebfc3
 }
 
 .alert-dark .alert-link {
   color: #0d0d10
 }
 
 @-webkit-keyframes progress-bar-stripes {
   from {
     background-position: 1rem 0
   }
 
   to {
     background-position: 0 0
   }
 }
 
 @-moz-keyframes progress-bar-stripes {
   from {
     background-position: 1rem 0
   }
 
   to {
     background-position: 0 0
   }
 }
 
 @-o-keyframes progress-bar-stripes {
   from {
     background-position: 1rem 0
   }
 
   to {
     background-position: 0 0
   }
 }
 
 @keyframes progress-bar-stripes {
   from {
     background-position: 1rem 0
   }
 
   to {
     background-position: 0 0
   }
 }
 
 .progress {
   font-size: .75rem;
   display: flex;
   height: 1rem;
   border-radius: .25rem;
   background-color: #eee
 }
 
 .media,
 .progress-bar {
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: -ms-flexbox
 }
 
 .progress-bar {
   display: flex;
   flex-direction: column;
   -webkit-transition: width .6s ease;
   -moz-transition: width .6s ease;
   -o-transition: width .6s ease;
   transition: width .6s ease;
   text-align: center;
   color: #fff;
   background-color: #6967ce;
   -webkit-flex-direction: column;
   -ms-flex-direction: column;
   -webkit-box-pack: center;
   -webkit-justify-content: center;
   -moz-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center
 }
 
 .progress-bar-striped {
   background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
   background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
   background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
   background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
   -webkit-background-size: 1rem 1rem;
   background-size: 1rem 1rem
 }
 
 .progress-bar-animated {
   -webkit-animation: progress-bar-stripes 1s linear infinite;
   -moz-animation: progress-bar-stripes 1s linear infinite;
   -o-animation: progress-bar-stripes 1s linear infinite;
   animation: progress-bar-stripes 1s linear infinite
 }
 
 @media (prefers-reduced-motion:reduce) {
   .progress-bar {
     -webkit-transition: none;
     -moz-transition: none;
     -o-transition: none;
     transition: none
   }
 
   .progress-bar-animated {
     -webkit-animation: none;
     -moz-animation: none;
     -o-animation: none;
     animation: none
   }
 }
 
 .media {
   display: flex;
   -webkit-box-align: start;
   -webkit-align-items: flex-start;
   -moz-box-align: start;
   -ms-flex-align: start;
   align-items: flex-start
 }
 
 .media-body {
   -webkit-box-flex: 1;
   -webkit-flex: 1;
   -moz-box-flex: 1;
   -ms-flex: 1;
   flex: 1
 }
 
 .list-group {
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: -ms-flexbox;
   display: flex;
   flex-direction: column;
   margin-bottom: 0;
   padding-left: 0;
   -webkit-flex-direction: column;
   -ms-flex-direction: column
 }
 
 .list-group-item-action {
   width: 100%;
   text-align: inherit;
   color: #4e5154
 }
 
 .list-group-item-action:focus,
 .list-group-item-action:hover {
   z-index: 1;
   color: #4e5154;
   background-color: #f9fafd
 }
 
 .list-group-item-action:active {
   color: #6b6f80;
   background-color: #98a4b8
 }
 
 .list-group-item {
   position: relative;
   display: block;
   margin-bottom: -1px;
   padding: 1.25rem;
   border: 1px solid #e4e7ed;
   background-color: #fff
 }
 
 .list-group-item:first-child {
   border-top-left-radius: .25rem;
   border-top-right-radius: .25rem
 }
 
 .list-group-item:last-child {
   margin-bottom: 0;
   border-bottom-right-radius: .25rem;
   border-bottom-left-radius: .25rem
 }
 
 .list-group-item.disabled,
 .list-group-item:disabled {
   pointer-events: none;
   color: #6b6f80;
   background-color: #fff
 }
 
 .list-group-item.active {
   z-index: 2;
   color: #fff;
   border-color: #6967ce;
   background-color: #6967ce
 }
 
 .list-group-horizontal {
   flex-direction: row;
   -webkit-box-orient: horizontal;
   -webkit-box-direction: normal;
   -webkit-flex-direction: row;
   -moz-box-orient: horizontal;
   -moz-box-direction: normal;
   -ms-flex-direction: row
 }
 
 .list-group-horizontal .list-group-item {
   margin-right: -1px;
   margin-bottom: 0
 }
 
 .list-group-horizontal .list-group-item:first-child {
   border-top-left-radius: .25rem;
   border-top-right-radius: 0;
   border-bottom-left-radius: .25rem
 }
 
 .list-group-horizontal .list-group-item:last-child {
   margin-right: 0;
   border-top-right-radius: .25rem;
   border-bottom-right-radius: .25rem;
   border-bottom-left-radius: 0
 }
 
 @media (min-width:576px) {
   .list-group-horizontal-sm {
     flex-direction: row;
     -webkit-box-orient: horizontal;
     -webkit-box-direction: normal;
     -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
     -ms-flex-direction: row
   }
 
   .list-group-horizontal-sm .list-group-item {
     margin-right: -1px;
     margin-bottom: 0
   }
 
   .list-group-horizontal-sm .list-group-item:first-child {
     border-top-left-radius: .25rem;
     border-top-right-radius: 0;
     border-bottom-left-radius: .25rem
   }
 
   .list-group-horizontal-sm .list-group-item:last-child {
     margin-right: 0;
     border-top-right-radius: .25rem;
     border-bottom-right-radius: .25rem;
     border-bottom-left-radius: 0
   }
 }
 
 @media (min-width:768px) {
   .list-group-horizontal-md {
     flex-direction: row;
     -webkit-box-orient: horizontal;
     -webkit-box-direction: normal;
     -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
     -ms-flex-direction: row
   }
 
   .list-group-horizontal-md .list-group-item {
     margin-right: -1px;
     margin-bottom: 0
   }
 
   .list-group-horizontal-md .list-group-item:first-child {
     border-top-left-radius: .25rem;
     border-top-right-radius: 0;
     border-bottom-left-radius: .25rem
   }
 
   .list-group-horizontal-md .list-group-item:last-child {
     margin-right: 0;
     border-top-right-radius: .25rem;
     border-bottom-right-radius: .25rem;
     border-bottom-left-radius: 0
   }
 }
 
 @media (min-width:992px) {
   .list-group-horizontal-lg {
     flex-direction: row;
     -webkit-box-orient: horizontal;
     -webkit-box-direction: normal;
     -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
     -ms-flex-direction: row
   }
 
   .list-group-horizontal-lg .list-group-item {
     margin-right: -1px;
     margin-bottom: 0
   }
 
   .list-group-horizontal-lg .list-group-item:first-child {
     border-top-left-radius: .25rem;
     border-top-right-radius: 0;
     border-bottom-left-radius: .25rem
   }
 
   .list-group-horizontal-lg .list-group-item:last-child {
     margin-right: 0;
     border-top-right-radius: .25rem;
     border-bottom-right-radius: .25rem;
     border-bottom-left-radius: 0
   }
 }
 
 @media (min-width:1200px) {
   .list-group-horizontal-xl {
     flex-direction: row;
     -webkit-box-orient: horizontal;
     -webkit-box-direction: normal;
     -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
     -ms-flex-direction: row
   }
 
   .list-group-horizontal-xl .list-group-item {
     margin-right: -1px;
     margin-bottom: 0
   }
 
   .list-group-horizontal-xl .list-group-item:first-child {
     border-top-left-radius: .25rem;
     border-top-right-radius: 0;
     border-bottom-left-radius: .25rem
   }
 
   .list-group-horizontal-xl .list-group-item:last-child {
     margin-right: 0;
     border-top-right-radius: .25rem;
     border-bottom-right-radius: .25rem;
     border-bottom-left-radius: 0
   }
 }
 
 .modal-content,
 .modal-dialog-centered.modal-dialog-scrollable {
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -moz-box-orient: vertical;
   -moz-box-direction: normal
 }
 
 .list-group-flush .list-group-item {
   border-right: 0;
   border-left: 0;
   border-radius: 0
 }
 
 .list-group-flush .list-group-item:last-child {
   margin-bottom: -1px
 }
 
 .list-group-flush:first-child .list-group-item:first-child {
   border-top: 0
 }
 
 .list-group-flush:last-child .list-group-item:last-child {
   margin-bottom: 0;
   border-bottom: 0
 }
 
 .list-group-item-primary {
   color: #37366b;
   background-color: #d5d4f1
 }
 
 .list-group-item-primary.list-group-item-action:focus,
 .list-group-item-primary.list-group-item-action:hover {
   color: #37366b;
   background-color: #c2c1eb
 }
 
 .list-group-item-primary.list-group-item-action.active {
   color: #fff;
   border-color: #37366b;
   background-color: #37366b
 }
 
 .list-group-item-secondary {
   color: #383a43;
   background-color: #d6d7db
 }
 
 .list-group-item-secondary.list-group-item-action:focus,
 .list-group-item-secondary.list-group-item-action:hover {
   color: #383a43;
   background-color: #c8cacf
 }
 
 .list-group-item-secondary.list-group-item-action.active {
   color: #fff;
   border-color: #383a43;
   background-color: #383a43
 }
 
 .list-group-item-success {
   color: #317029;
   background-color: #d2f4ce
 }
 
 .list-group-item-success.list-group-item-action:focus,
 .list-group-item-success.list-group-item-action:hover {
   color: #317029;
   background-color: #bfefb9
 }
 
 .list-group-item-success.list-group-item-action.active {
   color: #fff;
   border-color: #317029;
   background-color: #317029
 }
 
 .list-group-item-info {
   color: #155b6c;
   background-color: #c3e9f2
 }
 
 .list-group-item-info.list-group-item-action:focus,
 .list-group-item-info.list-group-item-action:hover {
   color: #155b6c;
   background-color: #aee1ed
 }
 
 .list-group-item-info.list-group-item-action.active {
   color: #fff;
   border-color: #155b6c;
   background-color: #155b6c
 }
 
 .list-group-item-warning {
   color: #846001;
   background-color: #feebb8
 }
 
 .list-group-item-warning.list-group-item-action:focus,
 .list-group-item-warning.list-group-item-action:hover {
   color: #846001;
   background-color: #fee49f
 }
 
 .list-group-item-warning.list-group-item-action.active {
   color: #fff;
   border-color: #846001;
   background-color: #846001
 }
 
 .list-group-item-danger {
   color: #823338;
   background-color: #fed3d6
 }
 
 .list-group-item-danger.list-group-item-action:focus,
 .list-group-item-danger.list-group-item-action:hover {
   color: #823338;
   background-color: #fdbabf
 }
 
 .list-group-item-danger.list-group-item-action.active {
   color: #fff;
   border-color: #823338;
   background-color: #823338
 }
 
 .list-group-item-light {
   color: #616367;
   background-color: #ecedef
 }
 
 .list-group-item-light.list-group-item-action:focus,
 .list-group-item-light.list-group-item-action:hover {
   color: #616367;
   background-color: #dee0e3
 }
 
 .list-group-item-light.list-group-item-action.active {
   color: #fff;
   border-color: #616367;
   background-color: #616367
 }
 
 .list-group-item-dark {
   color: #24252c;
   background-color: #cbcccf
 }
 
 .list-group-item-dark.list-group-item-action:focus,
 .list-group-item-dark.list-group-item-action:hover {
   color: #24252c;
   background-color: #bebfc3
 }
 
 .list-group-item-dark.list-group-item-action.active {
   color: #fff;
   border-color: #24252c;
   background-color: #24252c
 }
 
 .close {
   font-size: 1.5rem;
   float: right;
   opacity: .5;
   color: #000;
   text-shadow: 0 1px 0 #fff
 }
 
 .popover,
 .tooltip {
   font-family: Muli, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
   font-size: .875rem;
   font-weight: 400;
   font-style: normal;
   white-space: normal;
   letter-spacing: normal;
   word-spacing: normal;
   text-transform: none;
   word-wrap: break-word;
   word-break: normal;
   line-break: auto;
   text-decoration: none
 }
 
 .modal-title,
 .popover,
 .tooltip {
   line-height: 1.45
 }
 
 .popover,
 .text-hide,
 .tooltip {
   text-shadow: none
 }
 
 .close:hover {
   text-decoration: none;
   color: #000
 }
 
 .close:not(:disabled):not(.disabled):focus,
 .close:not(:disabled):not(.disabled):hover {
   opacity: .75
 }
 
 button.close {
   padding: 0;
   border: 0;
   background-color: transparent;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none
 }
 
 .toast,
 .toast-header {
   background-color: rgba(255, 255, 255, .85);
   background-clip: padding-box
 }
 
 a.close.disabled {
   pointer-events: none
 }
 
 .toast {
   font-size: .875rem;
   max-width: 350px;
   opacity: 0;
   border: 1px solid rgba(0, 0, 0, .1);
   border-radius: .25rem;
   -webkit-box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .1);
   box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .1);
   -webkit-backdrop-filter: blur(10px);
   backdrop-filter: blur(10px)
 }
 
 .toast:not(:last-child) {
   margin-bottom: .75rem
 }
 
 .toast.showing {
   opacity: 1
 }
 
 .toast.show {
   display: block;
   opacity: 1
 }
 
 .toast.hide {
   display: none
 }
 
 .modal-dialog-scrollable,
 .toast-header {
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: -ms-flexbox
 }
 
 .toast-header {
   display: flex;
   padding: .25rem .75rem;
   color: #6b6f80;
   border-bottom: 1px solid rgba(0, 0, 0, .05);
   -webkit-box-align: center;
   -webkit-align-items: center;
   -moz-box-align: center;
   -ms-flex-align: center;
   align-items: center
 }
 
 .popover-body,
 .toast-body {
   padding: .75rem
 }
 
 .modal-open .modal {
   overflow-x: hidden;
   overflow-y: auto
 }
 
 .modal {
   position: fixed;
   z-index: 1050;
   top: 0;
   left: 0;
   display: none;
   overflow: hidden;
   width: 100%;
   height: 100%;
   outline: 0
 }
 
 .modal-dialog {
   position: relative;
   width: auto;
   margin: .5rem;
   pointer-events: none
 }
 
 .modal.fade .modal-dialog {
   -webkit-transition: -webkit-transform .3s ease-out;
   -moz-transition: transform .3s ease-out, -moz-transform .3s ease-out;
   -o-transition: -o-transform .3s ease-out;
   transition: -webkit-transform .3s ease-out;
   transition: transform .3s ease-out;
   transition: transform .3s ease-out, -webkit-transform .3s ease-out, -moz-transform .3s ease-out, -o-transform .3s ease-out;
   -webkit-transform: translate(0, -50px);
   -moz-transform: translate(0, -50px);
   -ms-transform: translate(0, -50px);
   -o-transform: translate(0, -50px);
   transform: translate(0, -50px)
 }
 
 @media (prefers-reduced-motion:reduce) {
   .modal.fade .modal-dialog {
     -webkit-transition: none;
     -moz-transition: none;
     -o-transition: none;
     transition: none
   }
 }
 
 .modal.show .modal-dialog {
   -webkit-transform: none;
   -moz-transform: none;
   -ms-transform: none;
   -o-transform: none;
   transform: none
 }
 
 .modal-dialog-scrollable {
   display: flex;
   max-height: -webkit-calc(100% - 1rem);
   max-height: -moz-calc(100% - 1rem);
   max-height: calc(100% - 1rem)
 }
 
 .modal-dialog-scrollable .modal-content {
   overflow: hidden;
   max-height: -webkit-calc(100vh - 1rem);
   max-height: -moz-calc(100vh - 1rem);
   max-height: calc(100vh - 1rem)
 }
 
 .modal-dialog-scrollable .modal-footer,
 .modal-dialog-scrollable .modal-header {
   -webkit-flex-shrink: 0;
   -ms-flex-negative: 0;
   flex-shrink: 0
 }
 
 .modal-dialog-scrollable .modal-body {
   overflow-y: auto
 }
 
 .modal-dialog-centered {
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: -ms-flexbox;
   display: flex;
   min-height: -webkit-calc(100% - 1rem);
   min-height: -moz-calc(100% - 1rem);
   min-height: calc(100% - 1rem);
   -webkit-box-align: center;
   -webkit-align-items: center;
   -moz-box-align: center;
   -ms-flex-align: center;
   align-items: center
 }
 
 .modal-dialog-centered::before {
   display: block;
   height: -webkit-calc(100vh - 1rem);
   height: -moz-calc(100vh - 1rem);
   height: calc(100vh - 1rem)
 }
 
 .modal-content,
 .modal-header {
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: -ms-flexbox
 }
 
 .modal-dialog-centered.modal-dialog-scrollable {
   flex-direction: column;
   height: 100%;
   -webkit-flex-direction: column;
   -ms-flex-direction: column;
   -webkit-box-pack: center;
   -webkit-justify-content: center;
   -moz-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center
 }
 
 .modal-dialog-centered.modal-dialog-scrollable .modal-content {
   max-height: none
 }
 
 .modal-dialog-centered.modal-dialog-scrollable::before {
   content: none
 }
 
 .modal-content {
   position: relative;
   display: flex;
   flex-direction: column;
   width: 100%;
   pointer-events: auto;
   border: 1px solid transparent;
   border-radius: .35rem;
   outline: 0;
   background-color: #fff;
   background-clip: padding-box;
   -webkit-flex-direction: column;
   -ms-flex-direction: column
 }
 
 .flex-column,
 .flex-row {
   -webkit-box-direction: normal !important;
   -moz-box-direction: normal !important
 }
 
 .modal-backdrop {
   position: fixed;
   z-index: 1040;
   top: 0;
   left: 0;
   width: 100vw;
   height: 100vh;
   background-color: #464855
 }
 
 .modal-backdrop.fade {
   opacity: 0
 }
 
 .modal-backdrop.show {
   opacity: .4
 }
 
 .modal-header {
   display: flex;
   padding: 1rem;
   border-bottom: 1px solid transparent;
   border-top-left-radius: .35rem;
   border-top-right-radius: .35rem;
   -webkit-box-align: start;
   -webkit-align-items: flex-start;
   -moz-box-align: start;
   -ms-flex-align: start;
   align-items: flex-start;
   -webkit-box-pack: justify;
   -webkit-justify-content: space-between;
   -moz-box-pack: justify;
   -ms-flex-pack: justify;
   justify-content: space-between
 }
 
 .modal-header .close {
   margin: -1rem -1rem -1rem auto;
   padding: 1rem
 }
 
 .modal-title {
   margin-bottom: 0
 }
 
 .modal-body {
   position: relative;
   padding: 1rem;
   -webkit-box-flex: 1;
   -webkit-flex: 1 1 auto;
   -moz-box-flex: 1;
   -ms-flex: 1 1 auto;
   flex: 1 1 auto
 }
 
 .modal-footer {
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: -ms-flexbox;
   display: flex;
   padding: 1rem;
   border-top: 1px solid transparent;
   border-bottom-right-radius: .35rem;
   border-bottom-left-radius: .35rem;
   -webkit-box-align: center;
   -webkit-align-items: center;
   -moz-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: end;
   -webkit-justify-content: flex-end;
   -moz-box-pack: end;
   -ms-flex-pack: end;
   justify-content: flex-end
 }
 
 .popover,
 .popover .arrow,
 .popover .arrow::after,
 .popover .arrow::before,
 .tooltip,
 .tooltip .arrow {
   position: absolute;
   display: block
 }
 
 .modal-footer>:not(:first-child) {
   margin-left: .25rem
 }
 
 .modal-footer>:not(:last-child) {
   margin-right: .25rem
 }
 
 .modal-scrollbar-measure {
   position: absolute;
   top: -9999px;
   overflow: scroll;
   width: 50px;
   height: 50px
 }
 
 @media (min-width:576px) {
   .modal-dialog {
     max-width: 500px;
     margin: 1.75rem auto
   }
 
   .modal-dialog-scrollable {
     max-height: -webkit-calc(100% - 3.5rem);
     max-height: -moz-calc(100% - 3.5rem);
     max-height: calc(100% - 3.5rem)
   }
 
   .modal-dialog-scrollable .modal-content {
     max-height: -webkit-calc(100vh - 3.5rem);
     max-height: -moz-calc(100vh - 3.5rem);
     max-height: calc(100vh - 3.5rem)
   }
 
   .modal-dialog-centered {
     min-height: -webkit-calc(100% - 3.5rem);
     min-height: -moz-calc(100% - 3.5rem);
     min-height: calc(100% - 3.5rem)
   }
 
   .modal-dialog-centered::before {
     height: -webkit-calc(100vh - 3.5rem);
     height: -moz-calc(100vh - 3.5rem);
     height: calc(100vh - 3.5rem)
   }
 
   .modal-sm {
     max-width: 300px
   }
 }
 
 @media (min-width:992px) {
 
   .modal-lg,
   .modal-xl {
     max-width: 800px
   }
 }
 
 @media (min-width:1200px) {
   .modal-xl {
     max-width: 1140px
   }
 }
 
 .tooltip {
   z-index: 1070;
   margin: 0;
   text-align: left;
   text-align: start;
   opacity: 0
 }
 
 .tooltip.show {
   opacity: .8
 }
 
 .tooltip .arrow {
   width: .8rem;
   height: .4rem
 }
 
 .tooltip .arrow::before {
   position: absolute;
   border-style: solid;
   border-color: transparent
 }
 
 .bs-tooltip-auto[x-placement^=top],
 .bs-tooltip-top {
   padding: .4rem 0
 }
 
 .bs-tooltip-auto[x-placement^=top] .arrow,
 .bs-tooltip-top .arrow {
   bottom: 0
 }
 
 .bs-tooltip-auto[x-placement^=top] .arrow::before,
 .bs-tooltip-top .arrow::before {
   top: 0;
   border-width: .4rem .4rem 0;
   border-top-color: #000
 }
 
 .bs-tooltip-auto[x-placement^=right],
 .bs-tooltip-right {
   padding: 0 .4rem
 }
 
 .bs-tooltip-auto[x-placement^=right] .arrow,
 .bs-tooltip-right .arrow {
   left: 0;
   width: .4rem;
   height: .8rem
 }
 
 .bs-tooltip-auto[x-placement^=right] .arrow::before,
 .bs-tooltip-right .arrow::before {
   right: 0;
   border-width: .4rem .4rem .4rem 0;
   border-right-color: #000
 }
 
 .bs-tooltip-auto[x-placement^=bottom],
 .bs-tooltip-bottom {
   padding: .4rem 0
 }
 
 .bs-tooltip-auto[x-placement^=bottom] .arrow,
 .bs-tooltip-bottom .arrow {
   top: 0
 }
 
 .bs-tooltip-auto[x-placement^=bottom] .arrow::before,
 .bs-tooltip-bottom .arrow::before {
   bottom: 0;
   border-width: 0 .4rem .4rem;
   border-bottom-color: #000
 }
 
 .bs-tooltip-auto[x-placement^=left],
 .bs-tooltip-left {
   padding: 0 .4rem
 }
 
 .bs-tooltip-auto[x-placement^=left] .arrow,
 .bs-tooltip-left .arrow {
   right: 0;
   width: .4rem;
   height: .8rem
 }
 
 .bs-tooltip-auto[x-placement^=left] .arrow::before,
 .bs-tooltip-left .arrow::before {
   left: 0;
   border-width: .4rem 0 .4rem .4rem;
   border-left-color: #000
 }
 
 .tooltip-inner {
   max-width: 200px;
   padding: .25rem .5rem;
   text-align: center;
   color: #fff;
   border-radius: .25rem;
   background-color: #000
 }
 
 .popover {
   z-index: 1060;
   top: 0;
   left: 0;
   max-width: 276px;
   text-align: left;
   text-align: start;
   border: 1px solid rgba(0, 0, 0, .2);
   border-radius: .35rem;
   background-color: #fff;
   background-clip: padding-box
 }
 
 .popover .arrow {
   width: 1rem;
   height: 1rem;
   margin: 0 .35rem
 }
 
 .popover .arrow::after,
 .popover .arrow::before {
   border-style: solid;
   border-color: transparent
 }
 
 .bs-popover-auto[x-placement^=top],
 .bs-popover-top {
   margin-bottom: 1rem
 }
 
 .bs-popover-auto[x-placement^=top]>.arrow,
 .bs-popover-top>.arrow {
   bottom: -webkit-calc((1rem + 1px) * -1);
   bottom: -moz-calc((1rem + 1px) * -1);
   bottom: calc((1rem + 1px) * -1)
 }
 
 .bs-popover-auto[x-placement^=top]>.arrow::before,
 .bs-popover-top>.arrow::before {
   bottom: 0;
   border-width: 1rem .5rem 0;
   border-top-color: rgba(0, 0, 0, .25)
 }
 
 .bs-popover-auto[x-placement^=top]>.arrow::after,
 .bs-popover-top>.arrow::after {
   bottom: 1px;
   border-width: 1rem .5rem 0;
   border-top-color: #fff
 }
 
 .bs-popover-auto[x-placement^=right],
 .bs-popover-right {
   margin-left: 1rem
 }
 
 .bs-popover-auto[x-placement^=right]>.arrow,
 .bs-popover-right>.arrow {
   left: -webkit-calc((1rem + 1px) * -1);
   left: -moz-calc((1rem + 1px) * -1);
   left: calc((1rem + 1px) * -1);
   width: 1rem;
   height: 1rem;
   margin: .35rem 0
 }
 
 .bs-popover-auto[x-placement^=right]>.arrow::before,
 .bs-popover-right>.arrow::before {
   left: 0;
   border-width: .5rem 1rem .5rem 0;
   border-right-color: rgba(0, 0, 0, .25)
 }
 
 .bs-popover-auto[x-placement^=right]>.arrow::after,
 .bs-popover-right>.arrow::after {
   left: 1px;
   border-width: .5rem 1rem .5rem 0;
   border-right-color: #fff
 }
 
 .bs-popover-auto[x-placement^=bottom],
 .bs-popover-bottom {
   margin-top: 1rem
 }
 
 .bs-popover-auto[x-placement^=bottom]>.arrow,
 .bs-popover-bottom>.arrow {
   top: -webkit-calc((1rem + 1px) * -1);
   top: -moz-calc((1rem + 1px) * -1);
   top: calc((1rem + 1px) * -1)
 }
 
 .bs-popover-auto[x-placement^=bottom]>.arrow::before,
 .bs-popover-bottom>.arrow::before {
   top: 0;
   border-width: 0 .5rem 1rem;
   border-bottom-color: rgba(0, 0, 0, .25)
 }
 
 .bs-popover-auto[x-placement^=bottom]>.arrow::after,
 .bs-popover-bottom>.arrow::after {
   top: 1px;
   border-width: 0 .5rem 1rem;
   border-bottom-color: #fff
 }
 
 .bs-popover-auto[x-placement^=bottom] .popover-header::before,
 .bs-popover-bottom .popover-header::before {
   position: absolute;
   top: 0;
   left: 50%;
   display: block;
   width: 1rem;
   margin-left: -.5rem;
   content: '';
   border-bottom: 1px solid transparent
 }
 
 .carousel,
 .carousel-inner,
 .carousel-item {
   position: relative
 }
 
 .bs-popover-auto[x-placement^=left],
 .bs-popover-left {
   margin-right: 1rem
 }
 
 .bs-popover-auto[x-placement^=left]>.arrow,
 .bs-popover-left>.arrow {
   right: -webkit-calc((1rem + 1px) * -1);
   right: -moz-calc((1rem + 1px) * -1);
   right: calc((1rem + 1px) * -1);
   width: 1rem;
   height: 1rem;
   margin: .35rem 0
 }
 
 .bs-popover-auto[x-placement^=left]>.arrow::before,
 .bs-popover-left>.arrow::before {
   right: 0;
   border-width: .5rem 0 .5rem 1rem;
   border-left-color: rgba(0, 0, 0, .25)
 }
 
 .bs-popover-auto[x-placement^=left]>.arrow::after,
 .bs-popover-left>.arrow::after {
   right: 1px;
   border-width: .5rem 0 .5rem 1rem;
   border-left-color: #fff
 }
 
 .popover-header {
   font-size: 1rem;
   margin-bottom: 0;
   padding: .75rem;
   color: #464855;
   border-bottom: 1px solid transparent;
   border-top-left-radius: -webkit-calc(.35rem - 1px);
   border-top-left-radius: -moz-calc(.35rem - 1px);
   border-top-left-radius: calc(.35rem - 1px);
   border-top-right-radius: -webkit-calc(.35rem - 1px);
   border-top-right-radius: -moz-calc(.35rem - 1px);
   border-top-right-radius: calc(.35rem - 1px);
   background-color: transparent
 }
 
 .popover-header:empty {
   display: none
 }
 
 .popover-body {
   color: #6b6f80
 }
 
 .carousel.pointer-event {
   -ms-touch-action: pan-y;
   touch-action: pan-y
 }
 
 .carousel-inner {
   overflow: hidden;
   width: 100%
 }
 
 .carousel-inner::after {
   display: block;
   clear: both
 }
 
 .carousel-item {
   display: none;
   float: left;
   width: 100%;
   margin-right: -100%;
   -webkit-transition: -webkit-transform .6s ease;
   -moz-transition: transform .6s ease, -moz-transform .6s ease;
   -o-transition: -o-transform .6s ease;
   transition: -webkit-transform .6s ease;
   transition: transform .6s ease;
   transition: transform .6s ease, -webkit-transform .6s ease, -moz-transform .6s ease, -o-transform .6s ease;
   -webkit-backface-visibility: hidden;
   -moz-backface-visibility: hidden;
   backface-visibility: hidden
 }
 
 @media (prefers-reduced-motion:reduce) {
   .carousel-item {
     -webkit-transition: none;
     -moz-transition: none;
     -o-transition: none;
     transition: none
   }
 }
 
 .carousel-item-next,
 .carousel-item-prev,
 .carousel-item.active {
   display: block
 }
 
 .active.carousel-item-right,
 .carousel-item-next:not(.carousel-item-left) {
   -webkit-transform: translateX(100%);
   -moz-transform: translateX(100%);
   -ms-transform: translateX(100%);
   -o-transform: translateX(100%);
   transform: translateX(100%)
 }
 
 .active.carousel-item-left,
 .carousel-item-prev:not(.carousel-item-right) {
   -webkit-transform: translateX(-100%);
   -moz-transform: translateX(-100%);
   -ms-transform: translateX(-100%);
   -o-transform: translateX(-100%);
   transform: translateX(-100%)
 }
 
 .carousel-fade .carousel-item {
   -webkit-transition-property: opacity;
   -moz-transition-property: opacity;
   -o-transition-property: opacity;
   transition-property: opacity;
   -webkit-transform: none;
   -moz-transform: none;
   -ms-transform: none;
   -o-transform: none;
   transform: none;
   opacity: 0
 }
 
 .carousel-fade .carousel-item-next.carousel-item-left,
 .carousel-fade .carousel-item-prev.carousel-item-right,
 .carousel-fade .carousel-item.active {
   z-index: 1;
   opacity: 1
 }
 
 .carousel-fade .active.carousel-item-left,
 .carousel-fade .active.carousel-item-right {
   z-index: 0;
   -webkit-transition: 0s .6s opacity;
   -moz-transition: 0s .6s opacity;
   -o-transition: 0s .6s opacity;
   transition: 0s .6s opacity;
   opacity: 0
 }
 
 @media (prefers-reduced-motion:reduce) {
 
   .carousel-fade .active.carousel-item-left,
   .carousel-fade .active.carousel-item-right {
     -webkit-transition: none;
     -moz-transition: none;
     -o-transition: none;
     transition: none
   }
 }
 
 .carousel-control-next,
 .carousel-control-prev {
   position: absolute;
   z-index: 1;
   top: 0;
   bottom: 0;
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: -ms-flexbox;
   display: flex;
   width: 15%;
   -webkit-transition: opacity .15s ease;
   -moz-transition: opacity .15s ease;
   -o-transition: opacity .15s ease;
   transition: opacity .15s ease;
   text-align: center;
   opacity: .5;
   color: #fff;
   -webkit-box-align: center;
   -webkit-align-items: center;
   -moz-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: center;
   -webkit-justify-content: center;
   -moz-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center
 }
 
 @media (prefers-reduced-motion:reduce) {
 
   .carousel-control-next,
   .carousel-control-prev {
     -webkit-transition: none;
     -moz-transition: none;
     -o-transition: none;
     transition: none
   }
 }
 
 .carousel-control-next:focus,
 .carousel-control-next:hover,
 .carousel-control-prev:focus,
 .carousel-control-prev:hover {
   text-decoration: none;
   opacity: .9;
   color: #fff;
   outline: 0
 }
 
 .carousel-control-prev {
   left: 0
 }
 
 .carousel-control-next {
   right: 0
 }
 
 .carousel-control-next-icon,
 .carousel-control-prev-icon {
   display: inline-block;
   width: 20px;
   height: 20px;
   background: 50%/100% 100% no-repeat
 }
 
 .carousel-control-prev-icon {
   background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23fff\' viewBox=\'0 0 8 8\'%3E%3Cpath d=\'M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z\'/%3E%3C/svg%3E')
 }
 
 .carousel-control-next-icon {
   background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23fff\' viewBox=\'0 0 8 8\'%3E%3Cpath d=\'M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z\'/%3E%3C/svg%3E')
 }
 
 .carousel-indicators {
   position: absolute;
   z-index: 15;
   right: 0;
   bottom: 0;
   left: 0;
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: -ms-flexbox;
   display: flex;
   margin-right: 15%;
   margin-left: 15%;
   padding-left: 0;
   list-style: none;
   -webkit-box-pack: center;
   -webkit-justify-content: center;
   -moz-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center
 }
 
 .spinner-border,
 .spinner-grow {
   display: inline-block;
   vertical-align: text-bottom
 }
 
 .carousel-indicators li {
   -webkit-box-sizing: content-box;
   -moz-box-sizing: content-box;
   box-sizing: content-box;
   width: 30px;
   height: 3px;
   margin-right: 3px;
   margin-left: 3px;
   cursor: pointer;
   -webkit-transition: opacity .6s ease;
   -moz-transition: opacity .6s ease;
   -o-transition: opacity .6s ease;
   transition: opacity .6s ease;
   text-indent: -999px;
   opacity: .5;
   border-top: 10px solid transparent;
   border-bottom: 10px solid transparent;
   background-color: #fff;
   background-clip: padding-box;
   -webkit-box-flex: 0;
   -webkit-flex: 0 1 auto;
   -moz-box-flex: 0;
   -ms-flex: 0 1 auto;
   flex: 0 1 auto
 }
 
 @media (prefers-reduced-motion:reduce) {
   .carousel-indicators li {
     -webkit-transition: none;
     -moz-transition: none;
     -o-transition: none;
     transition: none
   }
 }
 
 .carousel-indicators .active {
   opacity: 1
 }
 
 .carousel-caption {
   position: absolute;
   z-index: 10;
   right: 15%;
   bottom: 20px;
   left: 15%;
   padding-top: 20px;
   padding-bottom: 20px;
   text-align: center;
   color: #fff
 }
 
 @-webkit-keyframes spinner-border {
   to {
     -webkit-transform: rotate(360deg);
     transform: rotate(360deg)
   }
 }
 
 @-moz-keyframes spinner-border {
   to {
     -moz-transform: rotate(360deg);
     transform: rotate(360deg)
   }
 }
 
 @-o-keyframes spinner-border {
   to {
     -o-transform: rotate(360deg);
     transform: rotate(360deg)
   }
 }
 
 @keyframes spinner-border {
   to {
     -webkit-transform: rotate(360deg);
     -moz-transform: rotate(360deg);
     -o-transform: rotate(360deg);
     transform: rotate(360deg)
   }
 }
 
 .spinner-border {
   width: 2rem;
   height: 2rem;
   -webkit-animation: spinner-border .75s linear infinite;
   -moz-animation: spinner-border .75s linear infinite;
   -o-animation: spinner-border .75s linear infinite;
   animation: spinner-border .75s linear infinite;
   border: .25em solid currentColor;
   border-right-color: transparent;
   border-radius: 50%
 }
 
 .spinner-border-sm {
   width: 1rem;
   height: 1rem;
   border-width: .2em
 }
 
 @-webkit-keyframes spinner-grow {
   0% {
     -webkit-transform: scale(0);
     transform: scale(0)
   }
 
   50% {
     opacity: 1
   }
 }
 
 @-moz-keyframes spinner-grow {
   0% {
     -moz-transform: scale(0);
     transform: scale(0)
   }
 
   50% {
     opacity: 1
   }
 }
 
 @-o-keyframes spinner-grow {
   0% {
     -o-transform: scale(0);
     transform: scale(0)
   }
 
   50% {
     opacity: 1
   }
 }
 
 @keyframes spinner-grow {
   0% {
     -webkit-transform: scale(0);
     -moz-transform: scale(0);
     -o-transform: scale(0);
     transform: scale(0)
   }
 
   50% {
     opacity: 1
   }
 }
 
 .spinner-grow {
   width: 2rem;
   height: 2rem;
   -webkit-animation: spinner-grow .75s linear infinite;
   -moz-animation: spinner-grow .75s linear infinite;
   -o-animation: spinner-grow .75s linear infinite;
   animation: spinner-grow .75s linear infinite;
   opacity: 0;
   border-radius: 50%;
   background-color: currentColor
 }
 
 .spinner-grow-sm {
   width: 1rem;
   height: 1rem
 }
 
 .align-baseline {
   vertical-align: baseline !important
 }
 
 .align-top {
   vertical-align: top !important
 }
 
 .align-middle {
   vertical-align: middle !important
 }
 
 .align-bottom {
   vertical-align: bottom !important
 }
 
 .align-text-bottom {
   vertical-align: text-bottom !important
 }
 
 .align-text-top {
   vertical-align: text-top !important
 }
 
 .bg-primary {
   background-color: #6967ce !important
 }
 
 a.bg-primary:focus,
 a.bg-primary:hover,
 button.bg-primary:focus,
 button.bg-primary:hover {
   background-color: #4340c2 !important
 }
 
 .bg-secondary {
   background-color: #6b6f80 !important
 }
 
 a.bg-secondary:focus,
 a.bg-secondary:hover,
 button.bg-secondary:focus,
 button.bg-secondary:hover {
   background-color: #545764 !important
 }
 
 .bg-success {
   background-color: #5ed84f !important
 }
 
 a.bg-success:focus,
 a.bg-success:hover,
 button.bg-success:focus,
 button.bg-success:hover {
   background-color: #3dc82c !important
 }
 
 .bg-info {
   background-color: #28afd0 !important
 }
 
 a.bg-info:focus,
 a.bg-info:hover,
 button.bg-info:focus,
 button.bg-info:hover {
   background-color: #208ba5 !important
 }
 
 .bg-warning {
   background-color: #fdb901 !important
 }
 
 a.bg-warning:focus,
 a.bg-warning:hover,
 button.bg-warning:focus,
 button.bg-warning:hover {
   background-color: #ca9401 !important
 }
 
 .bg-danger {
   background-color: #fa626b !important
 }
 
 a.bg-danger:focus,
 a.bg-danger:hover,
 button.bg-danger:focus,
 button.bg-danger:hover {
   background-color: #f8313c !important
 }
 
 .bg-light {
   background-color: #babfc7 !important
 }
 
 a.bg-light:focus,
 a.bg-light:hover,
 button.bg-light:focus,
 button.bg-light:hover {
   background-color: #9ea5b0 !important
 }
 
 .bg-dark {
   background-color: #464855 !important
 }
 
 a.bg-dark:focus,
 a.bg-dark:hover,
 button.bg-dark:focus,
 button.bg-dark:hover {
   background-color: #2f3039 !important
 }
 
 .bg-white {
   background-color: #fff !important
 }
 
 .bg-transparent {
   background-color: transparent !important
 }
 
 .border {
   border: 1px solid #626e82 !important
 }
 
 .border-top {
   border-top: 1px solid #626e82 !important
 }
 
 .border-right {
   border-right: 1px solid #626e82 !important
 }
 
 .border-bottom {
   border-bottom: 1px solid #626e82 !important
 }
 
 .border-left {
   border-left: 1px solid #626e82 !important
 }
 
 .border-0 {
   border: 0 !important
 }
 
 .border-top-0 {
   border-top: 0 !important
 }
 
 .border-right-0 {
   border-right: 0 !important
 }
 
 .border-bottom-0 {
   border-bottom: 0 !important
 }
 
 .border-left-0 {
   border-left: 0 !important
 }
 
 .border-primary {
   border-color: #6967ce !important
 }
 
 .border-secondary {
   border-color: #6b6f80 !important
 }
 
 .border-success {
   border-color: #5ed84f !important
 }
 
 .border-info {
   border-color: #28afd0 !important
 }
 
 .border-warning {
   border-color: #fdb901 !important
 }
 
 .border-danger {
   border-color: #fa626b !important
 }
 
 .border-light {
   border-color: #babfc7 !important
 }
 
 .border-dark {
   border-color: #464855 !important
 }
 
 .border-white {
   border-color: #fff !important
 }
 
 .rounded-sm {
   border-radius: .21rem !important
 }
 
 .rounded-right,
 .rounded-top {
   border-top-right-radius: .25rem !important
 }
 
 .rounded-bottom,
 .rounded-right {
   border-bottom-right-radius: .25rem !important
 }
 
 .rounded-left,
 .rounded-top {
   border-top-left-radius: .25rem !important
 }
 
 .rounded-bottom,
 .rounded-left {
   border-bottom-left-radius: .25rem !important
 }
 
 .rounded {
   border-radius: .25rem !important
 }
 
 .rounded-lg {
   border-radius: .35rem !important
 }
 
 .rounded-circle {
   border-radius: 50% !important
 }
 
 .rounded-pill {
   border-radius: 50rem !important
 }
 
 .rounded-0 {
   border-radius: 0 !important
 }
 
 .clearfix::after {
   display: block;
   clear: both
 }
 
 .d-none {
   display: none !important
 }
 
 .d-inline {
   display: inline !important
 }
 
 .d-inline-block {
   display: inline-block !important
 }
 
 .d-block {
   display: block !important
 }
 
 .d-table {
   display: table !important
 }
 
 .d-table-row {
   display: table-row !important
 }
 
 .d-table-cell {
   display: table-cell !important
 }
 
 .d-flex {
   display: -webkit-box !important;
   display: -webkit-flex !important;
   display: -moz-box !important;
   display: -ms-flexbox !important;
   display: flex !important
 }
 
 .d-inline-flex {
   display: -webkit-inline-box !important;
   display: -webkit-inline-flex !important;
   display: -moz-inline-box !important;
   display: -ms-inline-flexbox !important;
   display: inline-flex !important
 }
 
 @media (min-width:576px) {
   .d-sm-none {
     display: none !important
   }
 
   .d-sm-inline {
     display: inline !important
   }
 
   .d-sm-inline-block {
     display: inline-block !important
   }
 
   .d-sm-block {
     display: block !important
   }
 
   .d-sm-table {
     display: table !important
   }
 
   .d-sm-table-row {
     display: table-row !important
   }
 
   .d-sm-table-cell {
     display: table-cell !important
   }
 
   .d-sm-flex {
     display: -webkit-box !important;
     display: -webkit-flex !important;
     display: -moz-box !important;
     display: -ms-flexbox !important;
     display: flex !important
   }
 
   .d-sm-inline-flex {
     display: -webkit-inline-box !important;
     display: -webkit-inline-flex !important;
     display: -moz-inline-box !important;
     display: -ms-inline-flexbox !important;
     display: inline-flex !important
   }
 }
 
 @media (min-width:768px) {
   .d-md-none {
     display: none !important
   }
 
   .d-md-inline {
     display: inline !important
   }
 
   .d-md-inline-block {
     display: inline-block !important
   }
 
   .d-md-block {
     display: block !important
   }
 
   .d-md-table {
     display: table !important
   }
 
   .d-md-table-row {
     display: table-row !important
   }
 
   .d-md-table-cell {
     display: table-cell !important
   }
 
   .d-md-flex {
     display: -webkit-box !important;
     display: -webkit-flex !important;
     display: -moz-box !important;
     display: -ms-flexbox !important;
     display: flex !important
   }
 
   .d-md-inline-flex {
     display: -webkit-inline-box !important;
     display: -webkit-inline-flex !important;
     display: -moz-inline-box !important;
     display: -ms-inline-flexbox !important;
     display: inline-flex !important
   }
 }
 
 @media (min-width:992px) {
   .d-lg-none {
     display: none !important
   }
 
   .d-lg-inline {
     display: inline !important
   }
 
   .d-lg-inline-block {
     display: inline-block !important
   }
 
   .d-lg-block {
     display: block !important
   }
 
   .d-lg-table {
     display: table !important
   }
 
   .d-lg-table-row {
     display: table-row !important
   }
 
   .d-lg-table-cell {
     display: table-cell !important
   }
 
   .d-lg-flex {
     display: -webkit-box !important;
     display: -webkit-flex !important;
     display: -moz-box !important;
     display: -ms-flexbox !important;
     display: flex !important
   }
 
   .d-lg-inline-flex {
     display: -webkit-inline-box !important;
     display: -webkit-inline-flex !important;
     display: -moz-inline-box !important;
     display: -ms-inline-flexbox !important;
     display: inline-flex !important
   }
 }
 
 @media (min-width:1200px) {
   .d-xl-none {
     display: none !important
   }
 
   .d-xl-inline {
     display: inline !important
   }
 
   .d-xl-inline-block {
     display: inline-block !important
   }
 
   .d-xl-block {
     display: block !important
   }
 
   .d-xl-table {
     display: table !important
   }
 
   .d-xl-table-row {
     display: table-row !important
   }
 
   .d-xl-table-cell {
     display: table-cell !important
   }
 
   .d-xl-flex {
     display: -webkit-box !important;
     display: -webkit-flex !important;
     display: -moz-box !important;
     display: -ms-flexbox !important;
     display: flex !important
   }
 
   .d-xl-inline-flex {
     display: -webkit-inline-box !important;
     display: -webkit-inline-flex !important;
     display: -moz-inline-box !important;
     display: -ms-inline-flexbox !important;
     display: inline-flex !important
   }
 }
 
 @media print {
   .d-print-none {
     display: none !important
   }
 
   .d-print-inline {
     display: inline !important
   }
 
   .d-print-inline-block {
     display: inline-block !important
   }
 
   .d-print-block {
     display: block !important
   }
 
   .d-print-table {
     display: table !important
   }
 
   .d-print-table-row {
     display: table-row !important
   }
 
   .d-print-table-cell {
     display: table-cell !important
   }
 
   .d-print-flex {
     display: -webkit-box !important;
     display: -webkit-flex !important;
     display: -moz-box !important;
     display: -ms-flexbox !important;
     display: flex !important
   }
 
   .d-print-inline-flex {
     display: -webkit-inline-box !important;
     display: -webkit-inline-flex !important;
     display: -moz-inline-box !important;
     display: -ms-inline-flexbox !important;
     display: inline-flex !important
   }
 }
 
 .embed-responsive {
   position: relative;
   display: block;
   overflow: hidden;
   width: 100%;
   padding: 0
 }
 
 .embed-responsive::before {
   display: block
 }
 
 .embed-responsive .embed-responsive-item,
 .embed-responsive embed,
 .embed-responsive iframe,
 .embed-responsive object,
 .embed-responsive video {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 100%;
   border: 0
 }
 
 .embed-responsive-21by9::before {
   padding-top: 42.85714%
 }
 
 .embed-responsive-16by9::before {
   padding-top: 56.25%
 }
 
 .embed-responsive-4by3::before {
   padding-top: 75%
 }
 
 .embed-responsive-1by1::before {
   padding-top: 100%
 }
 
 .flex-row {
   flex-direction: row !important;
   -webkit-box-orient: horizontal !important;
   -webkit-flex-direction: row !important;
   -moz-box-orient: horizontal !important;
   -ms-flex-direction: row !important
 }
 
 .flex-column {
   flex-direction: column !important;
   -webkit-box-orient: vertical !important;
   -webkit-flex-direction: column !important;
   -moz-box-orient: vertical !important;
   -ms-flex-direction: column !important
 }
 
 .flex-column-reverse,
 .flex-row-reverse {
   -webkit-box-direction: reverse !important;
   -moz-box-direction: reverse !important
 }
 
 .flex-row-reverse {
   flex-direction: row-reverse !important;
   -webkit-box-orient: horizontal !important;
   -webkit-flex-direction: row-reverse !important;
   -moz-box-orient: horizontal !important;
   -ms-flex-direction: row-reverse !important
 }
 
 .flex-column-reverse {
   flex-direction: column-reverse !important;
   -webkit-box-orient: vertical !important;
   -webkit-flex-direction: column-reverse !important;
   -moz-box-orient: vertical !important;
   -ms-flex-direction: column-reverse !important
 }
 
 .flex-wrap {
   -webkit-flex-wrap: wrap !important;
   -ms-flex-wrap: wrap !important;
   flex-wrap: wrap !important
 }
 
 .flex-nowrap {
   -webkit-flex-wrap: nowrap !important;
   -ms-flex-wrap: nowrap !important;
   flex-wrap: nowrap !important
 }
 
 .flex-wrap-reverse {
   -webkit-flex-wrap: wrap-reverse !important;
   -ms-flex-wrap: wrap-reverse !important;
   flex-wrap: wrap-reverse !important
 }
 
 .flex-fill {
   -webkit-box-flex: 1 !important;
   -webkit-flex: 1 1 auto !important;
   -moz-box-flex: 1 !important;
   -ms-flex: 1 1 auto !important;
   flex: 1 1 auto !important
 }
 
 .flex-grow-0 {
   -webkit-box-flex: 0 !important;
   -webkit-flex-grow: 0 !important;
   -moz-box-flex: 0 !important;
   -ms-flex-positive: 0 !important;
   flex-grow: 0 !important
 }
 
 .flex-grow-1 {
   -webkit-box-flex: 1 !important;
   -webkit-flex-grow: 1 !important;
   -moz-box-flex: 1 !important;
   -ms-flex-positive: 1 !important;
   flex-grow: 1 !important
 }
 
 .flex-shrink-0 {
   -webkit-flex-shrink: 0 !important;
   -ms-flex-negative: 0 !important;
   flex-shrink: 0 !important
 }
 
 .flex-shrink-1 {
   -webkit-flex-shrink: 1 !important;
   -ms-flex-negative: 1 !important;
   flex-shrink: 1 !important
 }
 
 .justify-content-start {
   -webkit-box-pack: start !important;
   -webkit-justify-content: flex-start !important;
   -moz-box-pack: start !important;
   -ms-flex-pack: start !important;
   justify-content: flex-start !important
 }
 
 .justify-content-end {
   -webkit-box-pack: end !important;
   -webkit-justify-content: flex-end !important;
   -moz-box-pack: end !important;
   -ms-flex-pack: end !important;
   justify-content: flex-end !important
 }
 
 .justify-content-center {
   -webkit-box-pack: center !important;
   -webkit-justify-content: center !important;
   -moz-box-pack: center !important;
   -ms-flex-pack: center !important;
   justify-content: center !important
 }
 
 .justify-content-between {
   -webkit-box-pack: justify !important;
   -webkit-justify-content: space-between !important;
   -moz-box-pack: justify !important;
   -ms-flex-pack: justify !important;
   justify-content: space-between !important
 }
 
 .justify-content-around {
   -webkit-justify-content: space-around !important;
   -ms-flex-pack: distribute !important;
   justify-content: space-around !important
 }
 
 .align-items-start {
   -webkit-box-align: start !important;
   -webkit-align-items: flex-start !important;
   -moz-box-align: start !important;
   -ms-flex-align: start !important;
   align-items: flex-start !important
 }
 
 .align-items-end {
   -webkit-box-align: end !important;
   -webkit-align-items: flex-end !important;
   -moz-box-align: end !important;
   -ms-flex-align: end !important;
   align-items: flex-end !important
 }
 
 .align-items-center {
   -webkit-box-align: center !important;
   -webkit-align-items: center !important;
   -moz-box-align: center !important;
   -ms-flex-align: center !important;
   align-items: center !important
 }
 
 .align-items-baseline {
   -webkit-box-align: baseline !important;
   -webkit-align-items: baseline !important;
   -moz-box-align: baseline !important;
   -ms-flex-align: baseline !important;
   align-items: baseline !important
 }
 
 .align-items-stretch {
   -webkit-box-align: stretch !important;
   -webkit-align-items: stretch !important;
   -moz-box-align: stretch !important;
   -ms-flex-align: stretch !important;
   align-items: stretch !important
 }
 
 .align-content-start {
   -webkit-align-content: flex-start !important;
   -ms-flex-line-pack: start !important;
   align-content: flex-start !important
 }
 
 .align-content-end {
   -webkit-align-content: flex-end !important;
   -ms-flex-line-pack: end !important;
   align-content: flex-end !important
 }
 
 .align-content-center {
   -webkit-align-content: center !important;
   -ms-flex-line-pack: center !important;
   align-content: center !important
 }
 
 .align-content-between {
   -webkit-align-content: space-between !important;
   -ms-flex-line-pack: justify !important;
   align-content: space-between !important
 }
 
 .align-content-around {
   -webkit-align-content: space-around !important;
   -ms-flex-line-pack: distribute !important;
   align-content: space-around !important
 }
 
 .align-content-stretch {
   -webkit-align-content: stretch !important;
   -ms-flex-line-pack: stretch !important;
   align-content: stretch !important
 }
 
 .align-self-auto {
   -webkit-align-self: auto !important;
   -ms-flex-item-align: auto !important;
   align-self: auto !important
 }
 
 .align-self-start {
   -webkit-align-self: flex-start !important;
   -ms-flex-item-align: start !important;
   align-self: flex-start !important
 }
 
 .align-self-end {
   -webkit-align-self: flex-end !important;
   -ms-flex-item-align: end !important;
   align-self: flex-end !important
 }
 
 .align-self-center {
   -webkit-align-self: center !important;
   -ms-flex-item-align: center !important;
   align-self: center !important
 }
 
 .align-self-baseline {
   -webkit-align-self: baseline !important;
   -ms-flex-item-align: baseline !important;
   align-self: baseline !important
 }
 
 .align-self-stretch {
   -webkit-align-self: stretch !important;
   -ms-flex-item-align: stretch !important;
   align-self: stretch !important
 }
 
 .float-left {
   float: left !important
 }
 
 .float-right {
   float: right !important
 }
 
 .float-none {
   float: none !important
 }
 
 .overflow-auto {
   overflow: auto !important
 }
 
 .overflow-hidden {
   overflow: hidden !important
 }
 
 .position-static {
   position: static !important
 }
 
 .position-relative {
   position: relative !important
 }
 
 .position-absolute {
   position: absolute !important
 }
 
 .position-fixed {
   position: fixed !important
 }
 
 .position-sticky {
   position: -webkit-sticky !important;
   position: sticky !important
 }
 
 .fixed-bottom,
 .fixed-top {
   position: fixed;
   z-index: 1030;
   right: 0;
   left: 0
 }
 
 .fixed-top {
   top: 0
 }
 
 .fixed-bottom {
   bottom: 0
 }
 
 @supports ((position:-webkit-sticky) or (position:sticky)) {
   .sticky-top {
     position: -webkit-sticky;
     position: sticky;
     z-index: 1020;
     top: 0
   }
 }
 
 .sr-only {
   position: absolute;
   overflow: hidden;
   clip: rect(0, 0, 0, 0);
   width: 1px;
   height: 1px;
   padding: 0;
   white-space: nowrap;
   border: 0
 }
 
 .sr-only-focusable:active,
 .sr-only-focusable:focus {
   position: static;
   overflow: visible;
   clip: auto;
   width: auto;
   height: auto;
   white-space: normal
 }
 
 .shadow-sm {
   -webkit-box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
   box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
 }
 
 .shadow {
   -webkit-box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
   box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
 }
 
 .shadow-lg {
   -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
   box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
 }
 
 .shadow-none {
   -webkit-box-shadow: none !important;
   box-shadow: none !important
 }
 
 .w-25 {
   width: 25% !important
 }
 
 .w-50 {
   width: 50% !important
 }
 
 .w-75 {
   width: 75% !important
 }
 
 .w-100 {
   width: 100% !important
 }
 
 .w-auto {
   width: auto !important
 }
 
 .h-25 {
   height: 25% !important
 }
 
 .h-50 {
   height: 50% !important
 }
 
 .h-75 {
   height: 75% !important
 }
 
 .h-100 {
   height: 100% !important
 }
 
 .h-auto {
   height: auto !important
 }
 
 .mw-100 {
   max-width: 100% !important
 }
 
 .mh-100 {
   max-height: 100% !important
 }
 
 .min-vw-100 {
   min-width: 100vw !important
 }
 
 .min-vh-100 {
   min-height: 100vh !important
 }
 
 .vw-100 {
   width: 100vw !important
 }
 
 .vh-100 {
   height: 100vh !important
 }
 
 .stretched-link::after {
   position: absolute;
   z-index: 1;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   pointer-events: auto;
   background-color: rgba(0, 0, 0, 0)
 }
 
 .m-0 {
   margin: 0 !important
 }
 
 .mt-0,
 .my-0 {
   margin-top: 0 !important
 }
 
 .mr-0,
 .mx-0 {
   margin-right: 0 !important
 }
 
 .mb-0,
 .my-0 {
   margin-bottom: 0 !important
 }
 
 .ml-0,
 .mx-0 {
   margin-left: 0 !important
 }
 
 .m-1 {
   margin: 1rem !important
 }
 
 .mt-1,
 .my-1 {
   margin-top: 1rem !important
 }
 
 .mr-1,
 .mx-1 {
   margin-right: 1rem !important
 }
 
 .mb-1,
 .my-1 {
   margin-bottom: 1rem !important
 }
 
 .ml-1,
 .mx-1 {
   margin-left: 1rem !important
 }
 
 .m-2 {
   margin: 1.5rem !important
 }
 
 .mt-2,
 .my-2 {
   margin-top: 1.5rem !important
 }
 
 .mr-2,
 .mx-2 {
   margin-right: 1.5rem !important
 }
 
 .mb-2,
 .my-2 {
   margin-bottom: 1.5rem !important
 }
 
 .ml-2,
 .mx-2 {
   margin-left: 1.5rem !important
 }
 
 .m-3 {
   margin: 3rem !important
 }
 
 .mt-3,
 .my-3 {
   margin-top: 3rem !important
 }
 
 .mr-3,
 .mx-3 {
   margin-right: 3rem !important
 }
 
 .mb-3,
 .my-3 {
   margin-bottom: 3rem !important
 }
 
 .ml-3,
 .mx-3 {
   margin-left: 3rem !important
 }
 
 .m-4 {
   margin: 3.5rem !important
 }
 
 .mt-4,
 .my-4 {
   margin-top: 3.5rem !important
 }
 
 .mr-4,
 .mx-4 {
   margin-right: 3.5rem !important
 }
 
 .mb-4,
 .my-4 {
   margin-bottom: 3.5rem !important
 }
 
 .ml-4,
 .mx-4 {
   margin-left: 3.5rem !important
 }
 
 .m-5 {
   margin: 4rem !important
 }
 
 .mt-5,
 .my-5 {
   margin-top: 4rem !important
 }
 
 .mr-5,
 .mx-5 {
   margin-right: 4rem !important
 }
 
 .mb-5,
 .my-5 {
   margin-bottom: 4rem !important
 }
 
 .ml-5,
 .mx-5 {
   margin-left: 2rem !important
 }
 
 .p-0 {
   padding: 0 !important
 }
 
 .pt-0,
 .py-0 {
   padding-top: 0 !important
 }
 
 .pr-0,
 .px-0 {
   padding-right: 0 !important
 }
 
 .pb-0,
 .py-0 {
   padding-bottom: 0 !important
 }
 
 .pl-0,
 .px-0 {
   padding-left: 0 !important
 }
 
 .p-1 {
   padding: 0 !important
 }
 
 .pt-1,
 .py-1 {
   padding-top: 0 !important
 }
 
 .pr-1,
 .px-1 {
   padding-right: 0 !important
 }
 
 .pb-1,
 .py-1 {
   padding-bottom: 0 !important
 }
 
 .pl-1,
 .px-1 {
   padding-left: 0 !important
 }
 
 .p-2 {
   padding: 0 !important
 }
 
 .pt-2,
 .py-2 {
   padding-top: 0 !important
 }
 
 .pr-2,
 .px-2 {
   padding-right: 0 !important
 }
 
 .pb-2,
 .py-2 {
   padding-bottom: 0 !important
 }
 
 .pl-2,
 .px-2 {
   padding-left: 0 !important
 }
 
 .p-3 {
   padding: .5rem !important
 }
 
 .pt-3,
 .py-3 {
   padding-top: .5rem !important
 }
 
 .pr-3,
 .px-3 {
   padding-right: .5rem !important
 }
 
 .pb-3,
 .py-3 {
   padding-bottom: .5rem !important
 }
 
 .pl-3,
 .px-3 {
   padding-left: .5rem !important
 }
 
 .p-4 {
   padding: 1rem !important
 }
 
 .pt-4,
 .py-4 {
   padding-top: 1rem !important
 }
 
 .pr-4,
 .px-4 {
   padding-right: 1rem !important
 }
 
 .pb-4,
 .py-4 {
   padding-bottom: 1rem !important
 }
 
 .pl-4,
 .px-4 {
   padding-left: 1rem !important
 }
 
 .p-5 {
   padding: 2rem !important
 }
 
 .pt-5,
 .py-5 {
   padding-top: 2rem !important
 }
 
 .pr-5,
 .px-5 {
   padding-right: 2rem !important
 }
 
 .pb-5,
 .py-5 {
   padding-bottom: 2rem !important
 }
 
 .pl-5,
 .px-5 {
   padding-left: 2rem !important
 }
 
 .m-n1 {
   margin: -1rem !important
 }
 
 .mt-n1,
 .my-n1 {
   margin-top: -1rem !important
 }
 
 .mr-n1,
 .mx-n1 {
   margin-right: -1rem !important
 }
 
 .mb-n1,
 .my-n1 {
   margin-bottom: -1rem !important
 }
 
 .ml-n1,
 .mx-n1 {
   margin-left: -1rem !important
 }
 
 .m-n2 {
   margin: -1.5rem !important
 }
 
 .mt-n2,
 .my-n2 {
   margin-top: -1.5rem !important
 }
 
 .mr-n2,
 .mx-n2 {
   margin-right: -1.5rem !important
 }
 
 .mb-n2,
 .my-n2 {
   margin-bottom: -1.5rem !important
 }
 
 .ml-n2,
 .mx-n2 {
   margin-left: -1.5rem !important
 }
 
 .m-n3 {
   margin: -3rem !important
 }
 
 .mt-n3,
 .my-n3 {
   margin-top: -3rem !important
 }
 
 .mr-n3,
 .mx-n3 {
   margin-right: -3rem !important
 }
 
 .mb-n3,
 .my-n3 {
   margin-bottom: -3rem !important
 }
 
 .ml-n3,
 .mx-n3 {
   margin-left: -3rem !important
 }
 
 .m-n4 {
   margin: -3.5rem !important
 }
 
 .mt-n4,
 .my-n4 {
   margin-top: -3.5rem !important
 }
 
 .mr-n4,
 .mx-n4 {
   margin-right: -3.5rem !important
 }
 
 .mb-n4,
 .my-n4 {
   margin-bottom: -3.5rem !important
 }
 
 .ml-n4,
 .mx-n4 {
   margin-left: -3.5rem !important
 }
 
 .m-n5 {
   margin: -4rem !important
 }
 
 .mt-n5,
 .my-n5 {
   margin-top: -4rem !important
 }
 
 .mr-n5,
 .mx-n5 {
   margin-right: -4rem !important
 }
 
 .mb-n5,
 .my-n5 {
   margin-bottom: -4rem !important
 }
 
 .ml-n5,
 .mx-n5 {
   margin-left: -4rem !important
 }
 
 .m-auto {
   margin: auto !important
 }
 
 .mt-auto,
 .my-auto {
   margin-top: auto !important
 }
 
 .mr-auto,
 .mx-auto {
   margin-right: auto !important
 }
 
 .mb-auto,
 .my-auto {
   margin-bottom: auto !important
 }
 
 .ml-auto,
 .mx-auto {
   margin-left: auto !important
 }
 
 .text-monospace {
   font-family: Comfortaa, cursive, 'Times New Roman', Times, serif !important
 }
 
 .text-justify {
   text-align: justify !important
 }
 
 .text-wrap {
   white-space: normal !important
 }
 
 .text-nowrap {
   white-space: nowrap !important
 }
 
 .text-truncate {
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis
 }
 
 .text-left {
   text-align: left !important
 }
 
 .text-right {
   text-align: right !important
 }
 
 .text-center {
   text-align: center !important
 }
 
 @media (min-width:576px) {
 
   .flex-sm-column,
   .flex-sm-row {
     -webkit-box-direction: normal !important;
     -moz-box-direction: normal !important
   }
 
   .flex-sm-row {
     flex-direction: row !important;
     -webkit-box-orient: horizontal !important;
     -webkit-flex-direction: row !important;
     -moz-box-orient: horizontal !important;
     -ms-flex-direction: row !important
   }
 
   .flex-sm-column {
     flex-direction: column !important;
     -webkit-box-orient: vertical !important;
     -webkit-flex-direction: column !important;
     -moz-box-orient: vertical !important;
     -ms-flex-direction: column !important
   }
 
   .flex-sm-column-reverse,
   .flex-sm-row-reverse {
     -webkit-box-direction: reverse !important;
     -moz-box-direction: reverse !important
   }
 
   .flex-sm-row-reverse {
     flex-direction: row-reverse !important;
     -webkit-box-orient: horizontal !important;
     -webkit-flex-direction: row-reverse !important;
     -moz-box-orient: horizontal !important;
     -ms-flex-direction: row-reverse !important
   }
 
   .flex-sm-column-reverse {
     flex-direction: column-reverse !important;
     -webkit-box-orient: vertical !important;
     -webkit-flex-direction: column-reverse !important;
     -moz-box-orient: vertical !important;
     -ms-flex-direction: column-reverse !important
   }
 
   .flex-sm-wrap {
     -webkit-flex-wrap: wrap !important;
     -ms-flex-wrap: wrap !important;
     flex-wrap: wrap !important
   }
 
   .flex-sm-nowrap {
     -webkit-flex-wrap: nowrap !important;
     -ms-flex-wrap: nowrap !important;
     flex-wrap: nowrap !important
   }
 
   .flex-sm-wrap-reverse {
     -webkit-flex-wrap: wrap-reverse !important;
     -ms-flex-wrap: wrap-reverse !important;
     flex-wrap: wrap-reverse !important
   }
 
   .flex-sm-fill {
     -webkit-box-flex: 1 !important;
     -webkit-flex: 1 1 auto !important;
     -moz-box-flex: 1 !important;
     -ms-flex: 1 1 auto !important;
     flex: 1 1 auto !important
   }
 
   .flex-sm-grow-0 {
     -webkit-box-flex: 0 !important;
     -webkit-flex-grow: 0 !important;
     -moz-box-flex: 0 !important;
     -ms-flex-positive: 0 !important;
     flex-grow: 0 !important
   }
 
   .flex-sm-grow-1 {
     -webkit-box-flex: 1 !important;
     -webkit-flex-grow: 1 !important;
     -moz-box-flex: 1 !important;
     -ms-flex-positive: 1 !important;
     flex-grow: 1 !important
   }
 
   .flex-sm-shrink-0 {
     -webkit-flex-shrink: 0 !important;
     -ms-flex-negative: 0 !important;
     flex-shrink: 0 !important
   }
 
   .flex-sm-shrink-1 {
     -webkit-flex-shrink: 1 !important;
     -ms-flex-negative: 1 !important;
     flex-shrink: 1 !important
   }
 
   .justify-content-sm-start {
     -webkit-box-pack: start !important;
     -webkit-justify-content: flex-start !important;
     -moz-box-pack: start !important;
     -ms-flex-pack: start !important;
     justify-content: flex-start !important
   }
 
   .justify-content-sm-end {
     -webkit-box-pack: end !important;
     -webkit-justify-content: flex-end !important;
     -moz-box-pack: end !important;
     -ms-flex-pack: end !important;
     justify-content: flex-end !important
   }
 
   .justify-content-sm-center {
     -webkit-box-pack: center !important;
     -webkit-justify-content: center !important;
     -moz-box-pack: center !important;
     -ms-flex-pack: center !important;
     justify-content: center !important
   }
 
   .justify-content-sm-between {
     -webkit-box-pack: justify !important;
     -webkit-justify-content: space-between !important;
     -moz-box-pack: justify !important;
     -ms-flex-pack: justify !important;
     justify-content: space-between !important
   }
 
   .justify-content-sm-around {
     -webkit-justify-content: space-around !important;
     -ms-flex-pack: distribute !important;
     justify-content: space-around !important
   }
 
   .align-items-sm-start {
     -webkit-box-align: start !important;
     -webkit-align-items: flex-start !important;
     -moz-box-align: start !important;
     -ms-flex-align: start !important;
     align-items: flex-start !important
   }
 
   .align-items-sm-end {
     -webkit-box-align: end !important;
     -webkit-align-items: flex-end !important;
     -moz-box-align: end !important;
     -ms-flex-align: end !important;
     align-items: flex-end !important
   }
 
   .align-items-sm-center {
     -webkit-box-align: center !important;
     -webkit-align-items: center !important;
     -moz-box-align: center !important;
     -ms-flex-align: center !important;
     align-items: center !important
   }
 
   .align-items-sm-baseline {
     -webkit-box-align: baseline !important;
     -webkit-align-items: baseline !important;
     -moz-box-align: baseline !important;
     -ms-flex-align: baseline !important;
     align-items: baseline !important
   }
 
   .align-items-sm-stretch {
     -webkit-box-align: stretch !important;
     -webkit-align-items: stretch !important;
     -moz-box-align: stretch !important;
     -ms-flex-align: stretch !important;
     align-items: stretch !important
   }
 
   .align-content-sm-start {
     -webkit-align-content: flex-start !important;
     -ms-flex-line-pack: start !important;
     align-content: flex-start !important
   }
 
   .align-content-sm-end {
     -webkit-align-content: flex-end !important;
     -ms-flex-line-pack: end !important;
     align-content: flex-end !important
   }
 
   .align-content-sm-center {
     -webkit-align-content: center !important;
     -ms-flex-line-pack: center !important;
     align-content: center !important
   }
 
   .align-content-sm-between {
     -webkit-align-content: space-between !important;
     -ms-flex-line-pack: justify !important;
     align-content: space-between !important
   }
 
   .align-content-sm-around {
     -webkit-align-content: space-around !important;
     -ms-flex-line-pack: distribute !important;
     align-content: space-around !important
   }
 
   .align-content-sm-stretch {
     -webkit-align-content: stretch !important;
     -ms-flex-line-pack: stretch !important;
     align-content: stretch !important
   }
 
   .align-self-sm-auto {
     -webkit-align-self: auto !important;
     -ms-flex-item-align: auto !important;
     align-self: auto !important
   }
 
   .align-self-sm-start {
     -webkit-align-self: flex-start !important;
     -ms-flex-item-align: start !important;
     align-self: flex-start !important
   }
 
   .align-self-sm-end {
     -webkit-align-self: flex-end !important;
     -ms-flex-item-align: end !important;
     align-self: flex-end !important
   }
 
   .align-self-sm-center {
     -webkit-align-self: center !important;
     -ms-flex-item-align: center !important;
     align-self: center !important
   }
 
   .align-self-sm-baseline {
     -webkit-align-self: baseline !important;
     -ms-flex-item-align: baseline !important;
     align-self: baseline !important
   }
 
   .align-self-sm-stretch {
     -webkit-align-self: stretch !important;
     -ms-flex-item-align: stretch !important;
     align-self: stretch !important
   }
 
   .float-sm-left {
     float: left !important
   }
 
   .float-sm-right {
     float: right !important
   }
 
   .float-sm-none {
     float: none !important
   }
 
   .m-sm-0 {
     margin: 0 !important
   }
 
   .mt-sm-0,
   .my-sm-0 {
     margin-top: 0 !important
   }
 
   .mr-sm-0,
   .mx-sm-0 {
     margin-right: 0 !important
   }
 
   .mb-sm-0,
   .my-sm-0 {
     margin-bottom: 0 !important
   }
 
   .ml-sm-0,
   .mx-sm-0 {
     margin-left: 0 !important
   }
 
   .m-sm-1 {
     margin: 1rem !important
   }
 
   .mt-sm-1,
   .my-sm-1 {
     margin-top: 1rem !important
   }
 
   .mr-sm-1,
   .mx-sm-1 {
     margin-right: 1rem !important
   }
 
   .mb-sm-1,
   .my-sm-1 {
     margin-bottom: 1rem !important
   }
 
   .ml-sm-1,
   .mx-sm-1 {
     margin-left: 1rem !important
   }
 
   .m-sm-2 {
     margin: 1.5rem !important
   }
 
   .mt-sm-2,
   .my-sm-2 {
     margin-top: 1.5rem !important
   }
 
   .mr-sm-2,
   .mx-sm-2 {
     margin-right: 1.5rem !important
   }
 
   .mb-sm-2,
   .my-sm-2 {
     margin-bottom: 1.5rem !important
   }
 
   .ml-sm-2,
   .mx-sm-2 {
     margin-left: 1.5rem !important
   }
 
   .m-sm-3 {
     margin: 3rem !important
   }
 
   .mt-sm-3,
   .my-sm-3 {
     margin-top: 3rem !important
   }
 
   .mr-sm-3,
   .mx-sm-3 {
     margin-right: 3rem !important
   }
 
   .mb-sm-3,
   .my-sm-3 {
     margin-bottom: 3rem !important
   }
 
   .ml-sm-3,
   .mx-sm-3 {
     margin-left: 3rem !important
   }
 
   .m-sm-4 {
     margin: 3.5rem !important
   }
 
   .mt-sm-4,
   .my-sm-4 {
     margin-top: 3.5rem !important
   }
 
   .mr-sm-4,
   .mx-sm-4 {
     margin-right: 3.5rem !important
   }
 
   .mb-sm-4,
   .my-sm-4 {
     margin-bottom: 3.5rem !important
   }
 
   .ml-sm-4,
   .mx-sm-4 {
     margin-left: 3.5rem !important
   }
 
   .m-sm-5 {
     margin: 4rem !important
   }
 
   .mt-sm-5,
   .my-sm-5 {
     margin-top: 4rem !important
   }
 
   .mr-sm-5,
   .mx-sm-5 {
     margin-right: 4rem !important
   }
 
   .mb-sm-5,
   .my-sm-5 {
     margin-bottom: 4rem !important
   }
 
   .ml-sm-5,
   .mx-sm-5 {
     margin-left: 4rem !important
   }
 
   .p-sm-0 {
     padding: 0 !important
   }
 
   .pt-sm-0,
   .py-sm-0 {
     padding-top: 0 !important
   }
 
   .pr-sm-0,
   .px-sm-0 {
     padding-right: 0 !important
   }
 
   .pb-sm-0,
   .py-sm-0 {
     padding-bottom: 0 !important
   }
 
   .pl-sm-0,
   .px-sm-0 {
     padding-left: 0 !important
   }
 
   .p-sm-1 {
     padding: 1rem !important
   }
 
   .pt-sm-1,
   .py-sm-1 {
     padding-top: 1rem !important
   }
 
   .pr-sm-1,
   .px-sm-1 {
     padding-right: 1rem !important
   }
 
   .pb-sm-1,
   .py-sm-1 {
     padding-bottom: 1rem !important
   }
 
   .pl-sm-1,
   .px-sm-1 {
     padding-left: 1rem !important
   }
 
   .p-sm-2 {
     padding: 1.5rem !important
   }
 
   .pt-sm-2,
   .py-sm-2 {
     padding-top: 1.5rem !important
   }
 
   .pr-sm-2,
   .px-sm-2 {
     padding-right: 1.5rem !important
   }
 
   .pb-sm-2,
   .py-sm-2 {
     padding-bottom: 1.5rem !important
   }
 
   .pl-sm-2,
   .px-sm-2 {
     padding-left: 1.5rem !important
   }
 
   .p-sm-3 {
     padding: 3rem !important
   }
 
   .pt-sm-3,
   .py-sm-3 {
     padding-top: 3rem !important
   }
 
   .pr-sm-3,
   .px-sm-3 {
     padding-right: 3rem !important
   }
 
   .pb-sm-3,
   .py-sm-3 {
     padding-bottom: 3rem !important
   }
 
   .pl-sm-3,
   .px-sm-3 {
     padding-left: 3rem !important
   }
 
   .p-sm-4 {
     padding: 3.5rem !important
   }
 
   .pt-sm-4,
   .py-sm-4 {
     padding-top: 3.5rem !important
   }
 
   .pr-sm-4,
   .px-sm-4 {
     padding-right: 3.5rem !important
   }
 
   .pb-sm-4,
   .py-sm-4 {
     padding-bottom: 3.5rem !important
   }
 
   .pl-sm-4,
   .px-sm-4 {
     padding-left: 3.5rem !important
   }
 
   .p-sm-5 {
     padding: 4rem !important
   }
 
   .pt-sm-5,
   .py-sm-5 {
     padding-top: 4rem !important
   }
 
   .pr-sm-5,
   .px-sm-5 {
     padding-right: 4rem !important
   }
 
   .pb-sm-5,
   .py-sm-5 {
     padding-bottom: 4rem !important
   }
 
   .pl-sm-5,
   .px-sm-5 {
     padding-left: 4rem !important
   }
 
   .m-sm-n1 {
     margin: -1rem !important
   }
 
   .mt-sm-n1,
   .my-sm-n1 {
     margin-top: -1rem !important
   }
 
   .mr-sm-n1,
   .mx-sm-n1 {
     margin-right: -1rem !important
   }
 
   .mb-sm-n1,
   .my-sm-n1 {
     margin-bottom: -1rem !important
   }
 
   .ml-sm-n1,
   .mx-sm-n1 {
     margin-left: -1rem !important
   }
 
   .m-sm-n2 {
     margin: -1.5rem !important
   }
 
   .mt-sm-n2,
   .my-sm-n2 {
     margin-top: -1.5rem !important
   }
 
   .mr-sm-n2,
   .mx-sm-n2 {
     margin-right: -1.5rem !important
   }
 
   .mb-sm-n2,
   .my-sm-n2 {
     margin-bottom: -1.5rem !important
   }
 
   .ml-sm-n2,
   .mx-sm-n2 {
     margin-left: -1.5rem !important
   }
 
   .m-sm-n3 {
     margin: -3rem !important
   }
 
   .mt-sm-n3,
   .my-sm-n3 {
     margin-top: -3rem !important
   }
 
   .mr-sm-n3,
   .mx-sm-n3 {
     margin-right: -3rem !important
   }
 
   .mb-sm-n3,
   .my-sm-n3 {
     margin-bottom: -3rem !important
   }
 
   .ml-sm-n3,
   .mx-sm-n3 {
     margin-left: -3rem !important
   }
 
   .m-sm-n4 {
     margin: -3.5rem !important
   }
 
   .mt-sm-n4,
   .my-sm-n4 {
     margin-top: -3.5rem !important
   }
 
   .mr-sm-n4,
   .mx-sm-n4 {
     margin-right: -3.5rem !important
   }
 
   .mb-sm-n4,
   .my-sm-n4 {
     margin-bottom: -3.5rem !important
   }
 
   .ml-sm-n4,
   .mx-sm-n4 {
     margin-left: -3.5rem !important
   }
 
   .m-sm-n5 {
     margin: -4rem !important
   }
 
   .mt-sm-n5,
   .my-sm-n5 {
     margin-top: -4rem !important
   }
 
   .mr-sm-n5,
   .mx-sm-n5 {
     margin-right: -4rem !important
   }
 
   .mb-sm-n5,
   .my-sm-n5 {
     margin-bottom: -4rem !important
   }
 
   .ml-sm-n5,
   .mx-sm-n5 {
     margin-left: -4rem !important
   }
 
   .m-sm-auto {
     margin: auto !important
   }
 
   .mt-sm-auto,
   .my-sm-auto {
     margin-top: auto !important
   }
 
   .mr-sm-auto,
   .mx-sm-auto {
     margin-right: auto !important
   }
 
   .mb-sm-auto,
   .my-sm-auto {
     margin-bottom: auto !important
   }
 
   .ml-sm-auto,
   .mx-sm-auto {
     margin-left: auto !important
   }
 
   .text-sm-left {
     text-align: left !important
   }
 
   .text-sm-right {
     text-align: right !important
   }
 
   .text-sm-center {
     text-align: center !important
   }
 }
 
 @media (min-width:768px) {
 
   .flex-md-column,
   .flex-md-row {
     -webkit-box-direction: normal !important;
     -moz-box-direction: normal !important
   }
 
   .flex-md-row {
     flex-direction: row !important;
     -webkit-box-orient: horizontal !important;
     -webkit-flex-direction: row !important;
     -moz-box-orient: horizontal !important;
     -ms-flex-direction: row !important
   }
 
   .flex-md-column {
     flex-direction: column !important;
     -webkit-box-orient: vertical !important;
     -webkit-flex-direction: column !important;
     -moz-box-orient: vertical !important;
     -ms-flex-direction: column !important
   }
 
   .flex-md-column-reverse,
   .flex-md-row-reverse {
     -webkit-box-direction: reverse !important;
     -moz-box-direction: reverse !important
   }
 
   .flex-md-row-reverse {
     flex-direction: row-reverse !important;
     -webkit-box-orient: horizontal !important;
     -webkit-flex-direction: row-reverse !important;
     -moz-box-orient: horizontal !important;
     -ms-flex-direction: row-reverse !important
   }
 
   .flex-md-column-reverse {
     flex-direction: column-reverse !important;
     -webkit-box-orient: vertical !important;
     -webkit-flex-direction: column-reverse !important;
     -moz-box-orient: vertical !important;
     -ms-flex-direction: column-reverse !important
   }
 
   .flex-md-wrap {
     -webkit-flex-wrap: wrap !important;
     -ms-flex-wrap: wrap !important;
     flex-wrap: wrap !important
   }
 
   .flex-md-nowrap {
     -webkit-flex-wrap: nowrap !important;
     -ms-flex-wrap: nowrap !important;
     flex-wrap: nowrap !important
   }
 
   .flex-md-wrap-reverse {
     -webkit-flex-wrap: wrap-reverse !important;
     -ms-flex-wrap: wrap-reverse !important;
     flex-wrap: wrap-reverse !important
   }
 
   .flex-md-fill {
     -webkit-box-flex: 1 !important;
     -webkit-flex: 1 1 auto !important;
     -moz-box-flex: 1 !important;
     -ms-flex: 1 1 auto !important;
     flex: 1 1 auto !important
   }
 
   .flex-md-grow-0 {
     -webkit-box-flex: 0 !important;
     -webkit-flex-grow: 0 !important;
     -moz-box-flex: 0 !important;
     -ms-flex-positive: 0 !important;
     flex-grow: 0 !important
   }
 
   .flex-md-grow-1 {
     -webkit-box-flex: 1 !important;
     -webkit-flex-grow: 1 !important;
     -moz-box-flex: 1 !important;
     -ms-flex-positive: 1 !important;
     flex-grow: 1 !important
   }
 
   .flex-md-shrink-0 {
     -webkit-flex-shrink: 0 !important;
     -ms-flex-negative: 0 !important;
     flex-shrink: 0 !important
   }
 
   .flex-md-shrink-1 {
     -webkit-flex-shrink: 1 !important;
     -ms-flex-negative: 1 !important;
     flex-shrink: 1 !important
   }
 
   .justify-content-md-start {
     -webkit-box-pack: start !important;
     -webkit-justify-content: flex-start !important;
     -moz-box-pack: start !important;
     -ms-flex-pack: start !important;
     justify-content: flex-start !important
   }
 
   .justify-content-md-end {
     -webkit-box-pack: end !important;
     -webkit-justify-content: flex-end !important;
     -moz-box-pack: end !important;
     -ms-flex-pack: end !important;
     justify-content: flex-end !important
   }
 
   .justify-content-md-center {
     -webkit-box-pack: center !important;
     -webkit-justify-content: center !important;
     -moz-box-pack: center !important;
     -ms-flex-pack: center !important;
     justify-content: center !important
   }
 
   .justify-content-md-between {
     -webkit-box-pack: justify !important;
     -webkit-justify-content: space-between !important;
     -moz-box-pack: justify !important;
     -ms-flex-pack: justify !important;
     justify-content: space-between !important
   }
 
   .justify-content-md-around {
     -webkit-justify-content: space-around !important;
     -ms-flex-pack: distribute !important;
     justify-content: space-around !important
   }
 
   .align-items-md-start {
     -webkit-box-align: start !important;
     -webkit-align-items: flex-start !important;
     -moz-box-align: start !important;
     -ms-flex-align: start !important;
     align-items: flex-start !important
   }
 
   .align-items-md-end {
     -webkit-box-align: end !important;
     -webkit-align-items: flex-end !important;
     -moz-box-align: end !important;
     -ms-flex-align: end !important;
     align-items: flex-end !important
   }
 
   .align-items-md-center {
     -webkit-box-align: center !important;
     -webkit-align-items: center !important;
     -moz-box-align: center !important;
     -ms-flex-align: center !important;
     align-items: center !important
   }
 
   .align-items-md-baseline {
     -webkit-box-align: baseline !important;
     -webkit-align-items: baseline !important;
     -moz-box-align: baseline !important;
     -ms-flex-align: baseline !important;
     align-items: baseline !important
   }
 
   .align-items-md-stretch {
     -webkit-box-align: stretch !important;
     -webkit-align-items: stretch !important;
     -moz-box-align: stretch !important;
     -ms-flex-align: stretch !important;
     align-items: stretch !important
   }
 
   .align-content-md-start {
     -webkit-align-content: flex-start !important;
     -ms-flex-line-pack: start !important;
     align-content: flex-start !important
   }
 
   .align-content-md-end {
     -webkit-align-content: flex-end !important;
     -ms-flex-line-pack: end !important;
     align-content: flex-end !important
   }
 
   .align-content-md-center {
     -webkit-align-content: center !important;
     -ms-flex-line-pack: center !important;
     align-content: center !important
   }
 
   .align-content-md-between {
     -webkit-align-content: space-between !important;
     -ms-flex-line-pack: justify !important;
     align-content: space-between !important
   }
 
   .align-content-md-around {
     -webkit-align-content: space-around !important;
     -ms-flex-line-pack: distribute !important;
     align-content: space-around !important
   }
 
   .align-content-md-stretch {
     -webkit-align-content: stretch !important;
     -ms-flex-line-pack: stretch !important;
     align-content: stretch !important
   }
 
   .align-self-md-auto {
     -webkit-align-self: auto !important;
     -ms-flex-item-align: auto !important;
     align-self: auto !important
   }
 
   .align-self-md-start {
     -webkit-align-self: flex-start !important;
     -ms-flex-item-align: start !important;
     align-self: flex-start !important
   }
 
   .align-self-md-end {
     -webkit-align-self: flex-end !important;
     -ms-flex-item-align: end !important;
     align-self: flex-end !important
   }
 
   .align-self-md-center {
     -webkit-align-self: center !important;
     -ms-flex-item-align: center !important;
     align-self: center !important
   }
 
   .align-self-md-baseline {
     -webkit-align-self: baseline !important;
     -ms-flex-item-align: baseline !important;
     align-self: baseline !important
   }
 
   .align-self-md-stretch {
     -webkit-align-self: stretch !important;
     -ms-flex-item-align: stretch !important;
     align-self: stretch !important
   }
 
   .float-md-left {
     float: left !important
   }
 
   .float-md-right {
     float: right !important
   }
 
   .float-md-none {
     float: none !important
   }
 
   .m-md-0 {
     margin: 0 !important
   }
 
   .mt-md-0,
   .my-md-0 {
     margin-top: 0 !important
   }
 
   .mr-md-0,
   .mx-md-0 {
     margin-right: 0 !important
   }
 
   .mb-md-0,
   .my-md-0 {
     margin-bottom: 0 !important
   }
 
   .ml-md-0,
   .mx-md-0 {
     margin-left: 0 !important
   }
 
   .m-md-1 {
     margin: 1rem !important
   }
 
   .mt-md-1,
   .my-md-1 {
     margin-top: 1rem !important
   }
 
   .mr-md-1,
   .mx-md-1 {
     margin-right: 1rem !important
   }
 
   .mb-md-1,
   .my-md-1 {
     margin-bottom: 1rem !important
   }
 
   .ml-md-1,
   .mx-md-1 {
     margin-left: 1rem !important
   }
 
   .m-md-2 {
     margin: 1.5rem !important
   }
 
   .mt-md-2,
   .my-md-2 {
     margin-top: 1.5rem !important
   }
 
   .mr-md-2,
   .mx-md-2 {
     margin-right: 1.5rem !important
   }
 
   .mb-md-2,
   .my-md-2 {
     margin-bottom: 1.5rem !important
   }
 
   .ml-md-2,
   .mx-md-2 {
     margin-left: 1.5rem !important
   }
 
   .m-md-3 {
     margin: 3rem !important
   }
 
   .mt-md-3,
   .my-md-3 {
     margin-top: 3rem !important
   }
 
   .mr-md-3,
   .mx-md-3 {
     margin-right: 3rem !important
   }
 
   .mb-md-3,
   .my-md-3 {
     margin-bottom: 3rem !important
   }
 
   .ml-md-3,
   .mx-md-3 {
     margin-left: 3rem !important
   }
 
   .m-md-4 {
     margin: 3.5rem !important
   }
 
   .mt-md-4,
   .my-md-4 {
     margin-top: 3.5rem !important
   }
 
   .mr-md-4,
   .mx-md-4 {
     margin-right: 3.5rem !important
   }
 
   .mb-md-4,
   .my-md-4 {
     margin-bottom: 3.5rem !important
   }
 
   .ml-md-4,
   .mx-md-4 {
     margin-left: 3.5rem !important
   }
 
   .m-md-5 {
     margin: 4rem !important
   }
 
   .mt-md-5,
   .my-md-5 {
     margin-top: 4rem !important
   }
 
   .mr-md-5,
   .mx-md-5 {
     margin-right: 4rem !important
   }
 
   .mb-md-5,
   .my-md-5 {
     margin-bottom: 4rem !important
   }
 
   .ml-md-5,
   .mx-md-5 {
     margin-left: 4rem !important
   }
 
   .p-md-0 {
     padding: 0 !important
   }
 
   .pt-md-0,
   .py-md-0 {
     padding-top: 0 !important
   }
 
   .pr-md-0,
   .px-md-0 {
     padding-right: 0 !important
   }
 
   .pb-md-0,
   .py-md-0 {
     padding-bottom: 0 !important
   }
 
   .pl-md-0,
   .px-md-0 {
     padding-left: 0 !important
   }
 
   .p-md-1 {
     padding: 1rem !important
   }
 
   .pt-md-1,
   .py-md-1 {
     padding-top: 1rem !important
   }
 
   .pr-md-1,
   .px-md-1 {
     padding-right: 1rem !important
   }
 
   .pb-md-1,
   .py-md-1 {
     padding-bottom: 1rem !important
   }
 
   .pl-md-1,
   .px-md-1 {
     padding-left: 1rem !important
   }
 
   .p-md-2 {
     padding: 1.5rem !important
   }
 
   .pt-md-2,
   .py-md-2 {
     padding-top: 1.5rem !important
   }
 
   .pr-md-2,
   .px-md-2 {
     padding-right: 1.5rem !important
   }
 
   .pb-md-2,
   .py-md-2 {
     padding-bottom: 1.5rem !important
   }
 
   .pl-md-2,
   .px-md-2 {
     padding-left: 1.5rem !important
   }
 
   .p-md-3 {
     padding: 3rem !important
   }
 
   .pt-md-3,
   .py-md-3 {
     padding-top: 3rem !important
   }
 
   .pr-md-3,
   .px-md-3 {
     padding-right: 3rem !important
   }
 
   .pb-md-3,
   .py-md-3 {
     padding-bottom: 3rem !important
   }
 
   .pl-md-3,
   .px-md-3 {
     padding-left: 3rem !important
   }
 
   .p-md-4 {
     padding: 3.5rem !important
   }
 
   .pt-md-4,
   .py-md-4 {
     padding-top: 3.5rem !important
   }
 
   .pr-md-4,
   .px-md-4 {
     padding-right: 3.5rem !important
   }
 
   .pb-md-4,
   .py-md-4 {
     padding-bottom: 3.5rem !important
   }
 
   .pl-md-4,
   .px-md-4 {
     padding-left: 3.5rem !important
   }
 
   .p-md-5 {
     padding: 4rem !important
   }
 
   .pt-md-5,
   .py-md-5 {
     padding-top: 4rem !important
   }
 
   .pr-md-5,
   .px-md-5 {
     padding-right: 4rem !important
   }
 
   .pb-md-5,
   .py-md-5 {
     padding-bottom: 4rem !important
   }
 
   .pl-md-5,
   .px-md-5 {
     padding-left: 4rem !important
   }
 
   .m-md-n1 {
     margin: -1rem !important
   }
 
   .mt-md-n1,
   .my-md-n1 {
     margin-top: -1rem !important
   }
 
   .mr-md-n1,
   .mx-md-n1 {
     margin-right: -1rem !important
   }
 
   .mb-md-n1,
   .my-md-n1 {
     margin-bottom: -1rem !important
   }
 
   .ml-md-n1,
   .mx-md-n1 {
     margin-left: -1rem !important
   }
 
   .m-md-n2 {
     margin: -1.5rem !important
   }
 
   .mt-md-n2,
   .my-md-n2 {
     margin-top: -1.5rem !important
   }
 
   .mr-md-n2,
   .mx-md-n2 {
     margin-right: -1.5rem !important
   }
 
   .mb-md-n2,
   .my-md-n2 {
     margin-bottom: -1.5rem !important
   }
 
   .ml-md-n2,
   .mx-md-n2 {
     margin-left: -1.5rem !important
   }
 
   .m-md-n3 {
     margin: -3rem !important
   }
 
   .mt-md-n3,
   .my-md-n3 {
     margin-top: -3rem !important
   }
 
   .mr-md-n3,
   .mx-md-n3 {
     margin-right: -3rem !important
   }
 
   .mb-md-n3,
   .my-md-n3 {
     margin-bottom: -3rem !important
   }
 
   .ml-md-n3,
   .mx-md-n3 {
     margin-left: -3rem !important
   }
 
   .m-md-n4 {
     margin: -3.5rem !important
   }
 
   .mt-md-n4,
   .my-md-n4 {
     margin-top: -3.5rem !important
   }
 
   .mr-md-n4,
   .mx-md-n4 {
     margin-right: -3.5rem !important
   }
 
   .mb-md-n4,
   .my-md-n4 {
     margin-bottom: -3.5rem !important
   }
 
   .ml-md-n4,
   .mx-md-n4 {
     margin-left: -3.5rem !important
   }
 
   .m-md-n5 {
     margin: -4rem !important
   }
 
   .mt-md-n5,
   .my-md-n5 {
     margin-top: -4rem !important
   }
 
   .mr-md-n5,
   .mx-md-n5 {
     margin-right: -4rem !important
   }
 
   .mb-md-n5,
   .my-md-n5 {
     margin-bottom: -4rem !important
   }
 
   .ml-md-n5,
   .mx-md-n5 {
     margin-left: -4rem !important
   }
 
   .m-md-auto {
     margin: auto !important
   }
 
   .mt-md-auto,
   .my-md-auto {
     margin-top: auto !important
   }
 
   .mr-md-auto,
   .mx-md-auto {
     margin-right: auto !important
   }
 
   .mb-md-auto,
   .my-md-auto {
     margin-bottom: auto !important
   }
 
   .ml-md-auto,
   .mx-md-auto {
     margin-left: auto !important
   }
 
   .text-md-left {
     text-align: left !important
   }
 
   .text-md-right {
     text-align: right !important
   }
 
   .text-md-center {
     text-align: center !important
   }
 }
 
 @media (min-width:992px) {
 
   .flex-lg-column,
   .flex-lg-row {
     -webkit-box-direction: normal !important;
     -moz-box-direction: normal !important
   }
 
   .flex-lg-row {
     flex-direction: row !important;
     -webkit-box-orient: horizontal !important;
     -webkit-flex-direction: row !important;
     -moz-box-orient: horizontal !important;
     -ms-flex-direction: row !important
   }
 
   .flex-lg-column {
     flex-direction: column !important;
     -webkit-box-orient: vertical !important;
     -webkit-flex-direction: column !important;
     -moz-box-orient: vertical !important;
     -ms-flex-direction: column !important
   }
 
   .flex-lg-column-reverse,
   .flex-lg-row-reverse {
     -webkit-box-direction: reverse !important;
     -moz-box-direction: reverse !important
   }
 
   .flex-lg-row-reverse {
     flex-direction: row-reverse !important;
     -webkit-box-orient: horizontal !important;
     -webkit-flex-direction: row-reverse !important;
     -moz-box-orient: horizontal !important;
     -ms-flex-direction: row-reverse !important
   }
 
   .flex-lg-column-reverse {
     flex-direction: column-reverse !important;
     -webkit-box-orient: vertical !important;
     -webkit-flex-direction: column-reverse !important;
     -moz-box-orient: vertical !important;
     -ms-flex-direction: column-reverse !important
   }
 
   .flex-lg-wrap {
     -webkit-flex-wrap: wrap !important;
     -ms-flex-wrap: wrap !important;
     flex-wrap: wrap !important
   }
 
   .flex-lg-nowrap {
     -webkit-flex-wrap: nowrap !important;
     -ms-flex-wrap: nowrap !important;
     flex-wrap: nowrap !important
   }
 
   .flex-lg-wrap-reverse {
     -webkit-flex-wrap: wrap-reverse !important;
     -ms-flex-wrap: wrap-reverse !important;
     flex-wrap: wrap-reverse !important
   }
 
   .flex-lg-fill {
     -webkit-box-flex: 1 !important;
     -webkit-flex: 1 1 auto !important;
     -moz-box-flex: 1 !important;
     -ms-flex: 1 1 auto !important;
     flex: 1 1 auto !important
   }
 
   .flex-lg-grow-0 {
     -webkit-box-flex: 0 !important;
     -webkit-flex-grow: 0 !important;
     -moz-box-flex: 0 !important;
     -ms-flex-positive: 0 !important;
     flex-grow: 0 !important
   }
 
   .flex-lg-grow-1 {
     -webkit-box-flex: 1 !important;
     -webkit-flex-grow: 1 !important;
     -moz-box-flex: 1 !important;
     -ms-flex-positive: 1 !important;
     flex-grow: 1 !important
   }
 
   .flex-lg-shrink-0 {
     -webkit-flex-shrink: 0 !important;
     -ms-flex-negative: 0 !important;
     flex-shrink: 0 !important
   }
 
   .flex-lg-shrink-1 {
     -webkit-flex-shrink: 1 !important;
     -ms-flex-negative: 1 !important;
     flex-shrink: 1 !important
   }
 
   .justify-content-lg-start {
     -webkit-box-pack: start !important;
     -webkit-justify-content: flex-start !important;
     -moz-box-pack: start !important;
     -ms-flex-pack: start !important;
     justify-content: flex-start !important
   }
 
   .justify-content-lg-end {
     -webkit-box-pack: end !important;
     -webkit-justify-content: flex-end !important;
     -moz-box-pack: end !important;
     -ms-flex-pack: end !important;
     justify-content: flex-end !important
   }
 
   .justify-content-lg-center {
     -webkit-box-pack: center !important;
     -webkit-justify-content: center !important;
     -moz-box-pack: center !important;
     -ms-flex-pack: center !important;
     justify-content: center !important
   }
 
   .justify-content-lg-between {
     -webkit-box-pack: justify !important;
     -webkit-justify-content: space-between !important;
     -moz-box-pack: justify !important;
     -ms-flex-pack: justify !important;
     justify-content: space-between !important
   }
 
   .justify-content-lg-around {
     -webkit-justify-content: space-around !important;
     -ms-flex-pack: distribute !important;
     justify-content: space-around !important
   }
 
   .align-items-lg-start {
     -webkit-box-align: start !important;
     -webkit-align-items: flex-start !important;
     -moz-box-align: start !important;
     -ms-flex-align: start !important;
     align-items: flex-start !important
   }
 
   .align-items-lg-end {
     -webkit-box-align: end !important;
     -webkit-align-items: flex-end !important;
     -moz-box-align: end !important;
     -ms-flex-align: end !important;
     align-items: flex-end !important
   }
 
   .align-items-lg-center {
     -webkit-box-align: center !important;
     -webkit-align-items: center !important;
     -moz-box-align: center !important;
     -ms-flex-align: center !important;
     align-items: center !important
   }
 
   .align-items-lg-baseline {
     -webkit-box-align: baseline !important;
     -webkit-align-items: baseline !important;
     -moz-box-align: baseline !important;
     -ms-flex-align: baseline !important;
     align-items: baseline !important
   }
 
   .align-items-lg-stretch {
     -webkit-box-align: stretch !important;
     -webkit-align-items: stretch !important;
     -moz-box-align: stretch !important;
     -ms-flex-align: stretch !important;
     align-items: stretch !important
   }
 
   .align-content-lg-start {
     -webkit-align-content: flex-start !important;
     -ms-flex-line-pack: start !important;
     align-content: flex-start !important
   }
 
   .align-content-lg-end {
     -webkit-align-content: flex-end !important;
     -ms-flex-line-pack: end !important;
     align-content: flex-end !important
   }
 
   .align-content-lg-center {
     -webkit-align-content: center !important;
     -ms-flex-line-pack: center !important;
     align-content: center !important
   }
 
   .align-content-lg-between {
     -webkit-align-content: space-between !important;
     -ms-flex-line-pack: justify !important;
     align-content: space-between !important
   }
 
   .align-content-lg-around {
     -webkit-align-content: space-around !important;
     -ms-flex-line-pack: distribute !important;
     align-content: space-around !important
   }
 
   .align-content-lg-stretch {
     -webkit-align-content: stretch !important;
     -ms-flex-line-pack: stretch !important;
     align-content: stretch !important
   }
 
   .align-self-lg-auto {
     -webkit-align-self: auto !important;
     -ms-flex-item-align: auto !important;
     align-self: auto !important
   }
 
   .align-self-lg-start {
     -webkit-align-self: flex-start !important;
     -ms-flex-item-align: start !important;
     align-self: flex-start !important
   }
 
   .align-self-lg-end {
     -webkit-align-self: flex-end !important;
     -ms-flex-item-align: end !important;
     align-self: flex-end !important
   }
 
   .align-self-lg-center {
     -webkit-align-self: center !important;
     -ms-flex-item-align: center !important;
     align-self: center !important
   }
 
   .align-self-lg-baseline {
     -webkit-align-self: baseline !important;
     -ms-flex-item-align: baseline !important;
     align-self: baseline !important
   }
 
   .align-self-lg-stretch {
     -webkit-align-self: stretch !important;
     -ms-flex-item-align: stretch !important;
     align-self: stretch !important
   }
 
   .float-lg-left {
     float: left !important
   }
 
   .float-lg-right {
     float: right !important
   }
 
   .float-lg-none {
     float: none !important
   }
 
   .m-lg-0 {
     margin: 0 !important
   }
 
   .mt-lg-0,
   .my-lg-0 {
     margin-top: 0 !important
   }
 
   .mr-lg-0,
   .mx-lg-0 {
     margin-right: 0 !important
   }
 
   .mb-lg-0,
   .my-lg-0 {
     margin-bottom: 0 !important
   }
 
   .ml-lg-0,
   .mx-lg-0 {
     margin-left: 0 !important
   }
 
   .m-lg-1 {
     margin: 1rem !important
   }
 
   .mt-lg-1,
   .my-lg-1 {
     margin-top: 1rem !important
   }
 
   .mr-lg-1,
   .mx-lg-1 {
     margin-right: 1rem !important
   }
 
   .mb-lg-1,
   .my-lg-1 {
     margin-bottom: 1rem !important
   }
 
   .ml-lg-1,
   .mx-lg-1 {
     margin-left: 1rem !important
   }
 
   .m-lg-2 {
     margin: 1.5rem !important
   }
 
   .mt-lg-2,
   .my-lg-2 {
     margin-top: 1.5rem !important
   }
 
   .mr-lg-2,
   .mx-lg-2 {
     margin-right: 1.5rem !important
   }
 
   .mb-lg-2,
   .my-lg-2 {
     margin-bottom: 1.5rem !important
   }
 
   .ml-lg-2,
   .mx-lg-2 {
     margin-left: 1.5rem !important
   }
 
   .m-lg-3 {
     margin: 3rem !important
   }
 
   .mt-lg-3,
   .my-lg-3 {
     margin-top: 3rem !important
   }
 
   .mr-lg-3,
   .mx-lg-3 {
     margin-right: 3rem !important
   }
 
   .mb-lg-3,
   .my-lg-3 {
     margin-bottom: 3rem !important
   }
 
   .ml-lg-3,
   .mx-lg-3 {
     margin-left: 3rem !important
   }
 
   .m-lg-4 {
     margin: 3.5rem !important
   }
 
   .mt-lg-4,
   .my-lg-4 {
     margin-top: 3.5rem !important
   }
 
   .mr-lg-4,
   .mx-lg-4 {
     margin-right: 3.5rem !important
   }
 
   .mb-lg-4,
   .my-lg-4 {
     margin-bottom: 3.5rem !important
   }
 
   .ml-lg-4,
   .mx-lg-4 {
     margin-left: 3.5rem !important
   }
 
   .m-lg-5 {
     margin: 4rem !important
   }
 
   .mt-lg-5,
   .my-lg-5 {
     margin-top: 4rem !important
   }
 
   .mr-lg-5,
   .mx-lg-5 {
     margin-right: 4rem !important
   }
 
   .mb-lg-5,
   .my-lg-5 {
     margin-bottom: 4rem !important
   }
 
   .ml-lg-5,
   .mx-lg-5 {
     margin-left: 4rem !important
   }
 
   .p-lg-0 {
     padding: 0 !important
   }
 
   .pt-lg-0,
   .py-lg-0 {
     padding-top: 0 !important
   }
 
   .pr-lg-0,
   .px-lg-0 {
     padding-right: 0 !important
   }
 
   .pb-lg-0,
   .py-lg-0 {
     padding-bottom: 0 !important
   }
 
   .pl-lg-0,
   .px-lg-0 {
     padding-left: 0 !important
   }
 
   .p-lg-1 {
     padding: 1rem !important
   }
 
   .pt-lg-1,
   .py-lg-1 {
     padding-top: 1rem !important
   }
 
   .pr-lg-1,
   .px-lg-1 {
     padding-right: 1rem !important
   }
 
   .pb-lg-1,
   .py-lg-1 {
     padding-bottom: 1rem !important
   }
 
   .pl-lg-1,
   .px-lg-1 {
     padding-left: 1rem !important
   }
 
   .p-lg-2 {
     padding: 1.5rem !important
   }
 
   .pt-lg-2,
   .py-lg-2 {
     padding-top: 1.5rem !important
   }
 
   .pr-lg-2,
   .px-lg-2 {
     padding-right: 1.5rem !important
   }
 
   .pb-lg-2,
   .py-lg-2 {
     padding-bottom: 1.5rem !important
   }
 
   .pl-lg-2,
   .px-lg-2 {
     padding-left: 1.5rem !important
   }
 
   .p-lg-3 {
     padding: 3rem !important
   }
 
   .pt-lg-3,
   .py-lg-3 {
     padding-top: 3rem !important
   }
 
   .pr-lg-3,
   .px-lg-3 {
     padding-right: 3rem !important
   }
 
   .pb-lg-3,
   .py-lg-3 {
     padding-bottom: 3rem !important
   }
 
   .pl-lg-3,
   .px-lg-3 {
     padding-left: 3rem !important
   }
 
   .p-lg-4 {
     padding: 3.5rem !important
   }
 
   .pt-lg-4,
   .py-lg-4 {
     padding-top: 3.5rem !important
   }
 
   .pr-lg-4,
   .px-lg-4 {
     padding-right: 3.5rem !important
   }
 
   .pb-lg-4,
   .py-lg-4 {
     padding-bottom: 3.5rem !important
   }
 
   .pl-lg-4,
   .px-lg-4 {
     padding-left: 3.5rem !important
   }
 
   .p-lg-5 {
     padding: 4rem !important
   }
 
   .pt-lg-5,
   .py-lg-5 {
     padding-top: 4rem !important
   }
 
   .pr-lg-5,
   .px-lg-5 {
     padding-right: 4rem !important
   }
 
   .pb-lg-5,
   .py-lg-5 {
     padding-bottom: 4rem !important
   }
 
   .pl-lg-5,
   .px-lg-5 {
     padding-left: 4rem !important
   }
 
   .m-lg-n1 {
     margin: -1rem !important
   }
 
   .mt-lg-n1,
   .my-lg-n1 {
     margin-top: -1rem !important
   }
 
   .mr-lg-n1,
   .mx-lg-n1 {
     margin-right: -1rem !important
   }
 
   .mb-lg-n1,
   .my-lg-n1 {
     margin-bottom: -1rem !important
   }
 
   .ml-lg-n1,
   .mx-lg-n1 {
     margin-left: -1rem !important
   }
 
   .m-lg-n2 {
     margin: -1.5rem !important
   }
 
   .mt-lg-n2,
   .my-lg-n2 {
     margin-top: -1.5rem !important
   }
 
   .mr-lg-n2,
   .mx-lg-n2 {
     margin-right: -1.5rem !important
   }
 
   .mb-lg-n2,
   .my-lg-n2 {
     margin-bottom: -1.5rem !important
   }
 
   .ml-lg-n2,
   .mx-lg-n2 {
     margin-left: -1.5rem !important
   }
 
   .m-lg-n3 {
     margin: -3rem !important
   }
 
   .mt-lg-n3,
   .my-lg-n3 {
     margin-top: -3rem !important
   }
 
   .mr-lg-n3,
   .mx-lg-n3 {
     margin-right: -3rem !important
   }
 
   .mb-lg-n3,
   .my-lg-n3 {
     margin-bottom: -3rem !important
   }
 
   .ml-lg-n3,
   .mx-lg-n3 {
     margin-left: -3rem !important
   }
 
   .m-lg-n4 {
     margin: -3.5rem !important
   }
 
   .mt-lg-n4,
   .my-lg-n4 {
     margin-top: -3.5rem !important
   }
 
   .mr-lg-n4,
   .mx-lg-n4 {
     margin-right: -3.5rem !important
   }
 
   .mb-lg-n4,
   .my-lg-n4 {
     margin-bottom: -3.5rem !important
   }
 
   .ml-lg-n4,
   .mx-lg-n4 {
     margin-left: -3.5rem !important
   }
 
   .m-lg-n5 {
     margin: -4rem !important
   }
 
   .mt-lg-n5,
   .my-lg-n5 {
     margin-top: -4rem !important
   }
 
   .mr-lg-n5,
   .mx-lg-n5 {
     margin-right: -4rem !important
   }
 
   .mb-lg-n5,
   .my-lg-n5 {
     margin-bottom: -4rem !important
   }
 
   .ml-lg-n5,
   .mx-lg-n5 {
     margin-left: -4rem !important
   }
 
   .m-lg-auto {
     margin: auto !important
   }
 
   .mt-lg-auto,
   .my-lg-auto {
     margin-top: auto !important
   }
 
   .mr-lg-auto,
   .mx-lg-auto {
     margin-right: auto !important
   }
 
   .mb-lg-auto,
   .my-lg-auto {
     margin-bottom: auto !important
   }
 
   .ml-lg-auto,
   .mx-lg-auto {
     margin-left: auto !important
   }
 
   .text-lg-left {
     text-align: left !important
   }
 
   .text-lg-right {
     text-align: right !important
   }
 
   .text-lg-center {
     text-align: center !important
   }
 }
 
 @media (min-width:1200px) {
 
   .flex-xl-column,
   .flex-xl-row {
     -webkit-box-direction: normal !important;
     -moz-box-direction: normal !important
   }
 
   .flex-xl-row {
     flex-direction: row !important;
     -webkit-box-orient: horizontal !important;
     -webkit-flex-direction: row !important;
     -moz-box-orient: horizontal !important;
     -ms-flex-direction: row !important
   }
 
   .flex-xl-column {
     flex-direction: column !important;
     -webkit-box-orient: vertical !important;
     -webkit-flex-direction: column !important;
     -moz-box-orient: vertical !important;
     -ms-flex-direction: column !important
   }
 
   .flex-xl-column-reverse,
   .flex-xl-row-reverse {
     -webkit-box-direction: reverse !important;
     -moz-box-direction: reverse !important
   }
 
   .flex-xl-row-reverse {
     flex-direction: row-reverse !important;
     -webkit-box-orient: horizontal !important;
     -webkit-flex-direction: row-reverse !important;
     -moz-box-orient: horizontal !important;
     -ms-flex-direction: row-reverse !important
   }
 
   .flex-xl-column-reverse {
     flex-direction: column-reverse !important;
     -webkit-box-orient: vertical !important;
     -webkit-flex-direction: column-reverse !important;
     -moz-box-orient: vertical !important;
     -ms-flex-direction: column-reverse !important
   }
 
   .flex-xl-wrap {
     -webkit-flex-wrap: wrap !important;
     -ms-flex-wrap: wrap !important;
     flex-wrap: wrap !important
   }
 
   .flex-xl-nowrap {
     -webkit-flex-wrap: nowrap !important;
     -ms-flex-wrap: nowrap !important;
     flex-wrap: nowrap !important
   }
 
   .flex-xl-wrap-reverse {
     -webkit-flex-wrap: wrap-reverse !important;
     -ms-flex-wrap: wrap-reverse !important;
     flex-wrap: wrap-reverse !important
   }
 
   .flex-xl-fill {
     -webkit-box-flex: 1 !important;
     -webkit-flex: 1 1 auto !important;
     -moz-box-flex: 1 !important;
     -ms-flex: 1 1 auto !important;
     flex: 1 1 auto !important
   }
 
   .flex-xl-grow-0 {
     -webkit-box-flex: 0 !important;
     -webkit-flex-grow: 0 !important;
     -moz-box-flex: 0 !important;
     -ms-flex-positive: 0 !important;
     flex-grow: 0 !important
   }
 
   .flex-xl-grow-1 {
     -webkit-box-flex: 1 !important;
     -webkit-flex-grow: 1 !important;
     -moz-box-flex: 1 !important;
     -ms-flex-positive: 1 !important;
     flex-grow: 1 !important
   }
 
   .flex-xl-shrink-0 {
     -webkit-flex-shrink: 0 !important;
     -ms-flex-negative: 0 !important;
     flex-shrink: 0 !important
   }
 
   .flex-xl-shrink-1 {
     -webkit-flex-shrink: 1 !important;
     -ms-flex-negative: 1 !important;
     flex-shrink: 1 !important
   }
 
   .justify-content-xl-start {
     -webkit-box-pack: start !important;
     -webkit-justify-content: flex-start !important;
     -moz-box-pack: start !important;
     -ms-flex-pack: start !important;
     justify-content: flex-start !important
   }
 
   .justify-content-xl-end {
     -webkit-box-pack: end !important;
     -webkit-justify-content: flex-end !important;
     -moz-box-pack: end !important;
     -ms-flex-pack: end !important;
     justify-content: flex-end !important
   }
 
   .justify-content-xl-center {
     -webkit-box-pack: center !important;
     -webkit-justify-content: center !important;
     -moz-box-pack: center !important;
     -ms-flex-pack: center !important;
     justify-content: center !important
   }
 
   .justify-content-xl-between {
     -webkit-box-pack: justify !important;
     -webkit-justify-content: space-between !important;
     -moz-box-pack: justify !important;
     -ms-flex-pack: justify !important;
     justify-content: space-between !important
   }
 
   .justify-content-xl-around {
     -webkit-justify-content: space-around !important;
     -ms-flex-pack: distribute !important;
     justify-content: space-around !important
   }
 
   .align-items-xl-start {
     -webkit-box-align: start !important;
     -webkit-align-items: flex-start !important;
     -moz-box-align: start !important;
     -ms-flex-align: start !important;
     align-items: flex-start !important
   }
 
   .align-items-xl-end {
     -webkit-box-align: end !important;
     -webkit-align-items: flex-end !important;
     -moz-box-align: end !important;
     -ms-flex-align: end !important;
     align-items: flex-end !important
   }
 
   .align-items-xl-center {
     -webkit-box-align: center !important;
     -webkit-align-items: center !important;
     -moz-box-align: center !important;
     -ms-flex-align: center !important;
     align-items: center !important
   }
 
   .align-items-xl-baseline {
     -webkit-box-align: baseline !important;
     -webkit-align-items: baseline !important;
     -moz-box-align: baseline !important;
     -ms-flex-align: baseline !important;
     align-items: baseline !important
   }
 
   .align-items-xl-stretch {
     -webkit-box-align: stretch !important;
     -webkit-align-items: stretch !important;
     -moz-box-align: stretch !important;
     -ms-flex-align: stretch !important;
     align-items: stretch !important
   }
 
   .align-content-xl-start {
     -webkit-align-content: flex-start !important;
     -ms-flex-line-pack: start !important;
     align-content: flex-start !important
   }
 
   .align-content-xl-end {
     -webkit-align-content: flex-end !important;
     -ms-flex-line-pack: end !important;
     align-content: flex-end !important
   }
 
   .align-content-xl-center {
     -webkit-align-content: center !important;
     -ms-flex-line-pack: center !important;
     align-content: center !important
   }
 
   .align-content-xl-between {
     -webkit-align-content: space-between !important;
     -ms-flex-line-pack: justify !important;
     align-content: space-between !important
   }
 
   .align-content-xl-around {
     -webkit-align-content: space-around !important;
     -ms-flex-line-pack: distribute !important;
     align-content: space-around !important
   }
 
   .align-content-xl-stretch {
     -webkit-align-content: stretch !important;
     -ms-flex-line-pack: stretch !important;
     align-content: stretch !important
   }
 
   .align-self-xl-auto {
     -webkit-align-self: auto !important;
     -ms-flex-item-align: auto !important;
     align-self: auto !important
   }
 
   .align-self-xl-start {
     -webkit-align-self: flex-start !important;
     -ms-flex-item-align: start !important;
     align-self: flex-start !important
   }
 
   .align-self-xl-end {
     -webkit-align-self: flex-end !important;
     -ms-flex-item-align: end !important;
     align-self: flex-end !important
   }
 
   .align-self-xl-center {
     -webkit-align-self: center !important;
     -ms-flex-item-align: center !important;
     align-self: center !important
   }
 
   .align-self-xl-baseline {
     -webkit-align-self: baseline !important;
     -ms-flex-item-align: baseline !important;
     align-self: baseline !important
   }
 
   .align-self-xl-stretch {
     -webkit-align-self: stretch !important;
     -ms-flex-item-align: stretch !important;
     align-self: stretch !important
   }
 
   .float-xl-left {
     float: left !important
   }
 
   .float-xl-right {
     float: right !important
   }
 
   .float-xl-none {
     float: none !important
   }
 
   .m-xl-0 {
     margin: 0 !important
   }
 
   .mt-xl-0,
   .my-xl-0 {
     margin-top: 0 !important
   }
 
   .mr-xl-0,
   .mx-xl-0 {
     margin-right: 0 !important
   }
 
   .mb-xl-0,
   .my-xl-0 {
     margin-bottom: 0 !important
   }
 
   .ml-xl-0,
   .mx-xl-0 {
     margin-left: 0 !important
   }
 
   .m-xl-1 {
     margin: 1rem !important
   }
 
   .mt-xl-1,
   .my-xl-1 {
     margin-top: 1rem !important
   }
 
   .mr-xl-1,
   .mx-xl-1 {
     margin-right: 1rem !important
   }
 
   .mb-xl-1,
   .my-xl-1 {
     margin-bottom: 1rem !important
   }
 
   .ml-xl-1,
   .mx-xl-1 {
     margin-left: 1rem !important
   }
 
   .m-xl-2 {
     margin: 1.5rem !important
   }
 
   .mt-xl-2,
   .my-xl-2 {
     margin-top: 1.5rem !important
   }
 
   .mr-xl-2,
   .mx-xl-2 {
     margin-right: 1.5rem !important
   }
 
   .mb-xl-2,
   .my-xl-2 {
     margin-bottom: 1.5rem !important
   }
 
   .ml-xl-2,
   .mx-xl-2 {
     margin-left: 1.5rem !important
   }
 
   .m-xl-3 {
     margin: 3rem !important
   }
 
   .mt-xl-3,
   .my-xl-3 {
     margin-top: 3rem !important
   }
 
   .mr-xl-3,
   .mx-xl-3 {
     margin-right: 3rem !important
   }
 
   .mb-xl-3,
   .my-xl-3 {
     margin-bottom: 3rem !important
   }
 
   .ml-xl-3,
   .mx-xl-3 {
     margin-left: 3rem !important
   }
 
   .m-xl-4 {
     margin: 3.5rem !important
   }
 
   .mt-xl-4,
   .my-xl-4 {
     margin-top: 3.5rem !important
   }
 
   .mr-xl-4,
   .mx-xl-4 {
     margin-right: 3.5rem !important
   }
 
   .mb-xl-4,
   .my-xl-4 {
     margin-bottom: 3.5rem !important
   }
 
   .ml-xl-4,
   .mx-xl-4 {
     margin-left: 3.5rem !important
   }
 
   .m-xl-5 {
     margin: 4rem !important
   }
 
   .mt-xl-5,
   .my-xl-5 {
     margin-top: 4rem !important
   }
 
   .mr-xl-5,
   .mx-xl-5 {
     margin-right: 4rem !important
   }
 
   .mb-xl-5,
   .my-xl-5 {
     margin-bottom: 4rem !important
   }
 
   .ml-xl-5,
   .mx-xl-5 {
     margin-left: 4rem !important
   }
 
   .p-xl-0 {
     padding: 0 !important
   }
 
   .pt-xl-0,
   .py-xl-0 {
     padding-top: 0 !important
   }
 
   .pr-xl-0,
   .px-xl-0 {
     padding-right: 0 !important
   }
 
   .pb-xl-0,
   .py-xl-0 {
     padding-bottom: 0 !important
   }
 
   .pl-xl-0,
   .px-xl-0 {
     padding-left: 0 !important
   }
 
   .p-xl-1 {
     padding: 1rem !important
   }
 
   .pt-xl-1,
   .py-xl-1 {
     padding-top: 1rem !important
   }
 
   .pr-xl-1,
   .px-xl-1 {
     padding-right: 1rem !important
   }
 
   .pb-xl-1,
   .py-xl-1 {
     padding-bottom: 1rem !important
   }
 
   .pl-xl-1,
   .px-xl-1 {
     padding-left: 1rem !important
   }
 
   .p-xl-2 {
     padding: 1.5rem !important
   }
 
   .pt-xl-2,
   .py-xl-2 {
     padding-top: 1.5rem !important
   }
 
   .pr-xl-2,
   .px-xl-2 {
     padding-right: 1.5rem !important
   }
 
   .pb-xl-2,
   .py-xl-2 {
     padding-bottom: 1.5rem !important
   }
 
   .pl-xl-2,
   .px-xl-2 {
     padding-left: 1.5rem !important
   }
 
   .p-xl-3 {
     padding: 3rem !important
   }
 
   .pt-xl-3,
   .py-xl-3 {
     padding-top: 3rem !important
   }
 
   .pr-xl-3,
   .px-xl-3 {
     padding-right: 3rem !important
   }
 
   .pb-xl-3,
   .py-xl-3 {
     padding-bottom: 3rem !important
   }
 
   .pl-xl-3,
   .px-xl-3 {
     padding-left: 3rem !important
   }
 
   .p-xl-4 {
     padding: 3.5rem !important
   }
 
   .pt-xl-4,
   .py-xl-4 {
     padding-top: 3.5rem !important
   }
 
   .pr-xl-4,
   .px-xl-4 {
     padding-right: 3.5rem !important
   }
 
   .pb-xl-4,
   .py-xl-4 {
     padding-bottom: 3.5rem !important
   }
 
   .pl-xl-4,
   .px-xl-4 {
     padding-left: 3.5rem !important
   }
 
   .p-xl-5 {
     padding: 4rem !important
   }
 
   .pt-xl-5,
   .py-xl-5 {
     padding-top: 4rem !important
   }
 
   .pr-xl-5,
   .px-xl-5 {
     padding-right: 4rem !important
   }
 
   .pb-xl-5,
   .py-xl-5 {
     padding-bottom: 4rem !important
   }
 
   .pl-xl-5,
   .px-xl-5 {
     padding-left: 4rem !important
   }
 
   .m-xl-n1 {
     margin: -1rem !important
   }
 
   .mt-xl-n1,
   .my-xl-n1 {
     margin-top: -1rem !important
   }
 
   .mr-xl-n1,
   .mx-xl-n1 {
     margin-right: -1rem !important
   }
 
   .mb-xl-n1,
   .my-xl-n1 {
     margin-bottom: -1rem !important
   }
 
   .ml-xl-n1,
   .mx-xl-n1 {
     margin-left: -1rem !important
   }
 
   .m-xl-n2 {
     margin: -1.5rem !important
   }
 
   .mt-xl-n2,
   .my-xl-n2 {
     margin-top: -1.5rem !important
   }
 
   .mr-xl-n2,
   .mx-xl-n2 {
     margin-right: -1.5rem !important
   }
 
   .mb-xl-n2,
   .my-xl-n2 {
     margin-bottom: -1.5rem !important
   }
 
   .ml-xl-n2,
   .mx-xl-n2 {
     margin-left: -1.5rem !important
   }
 
   .m-xl-n3 {
     margin: -3rem !important
   }
 
   .mt-xl-n3,
   .my-xl-n3 {
     margin-top: -3rem !important
   }
 
   .mr-xl-n3,
   .mx-xl-n3 {
     margin-right: -3rem !important
   }
 
   .mb-xl-n3,
   .my-xl-n3 {
     margin-bottom: -3rem !important
   }
 
   .ml-xl-n3,
   .mx-xl-n3 {
     margin-left: -3rem !important
   }
 
   .m-xl-n4 {
     margin: -3.5rem !important
   }
 
   .mt-xl-n4,
   .my-xl-n4 {
     margin-top: -3.5rem !important
   }
 
   .mr-xl-n4,
   .mx-xl-n4 {
     margin-right: -3.5rem !important
   }
 
   .mb-xl-n4,
   .my-xl-n4 {
     margin-bottom: -3.5rem !important
   }
 
   .ml-xl-n4,
   .mx-xl-n4 {
     margin-left: -3.5rem !important
   }
 
   .m-xl-n5 {
     margin: -4rem !important
   }
 
   .mt-xl-n5,
   .my-xl-n5 {
     margin-top: -4rem !important
   }
 
   .mr-xl-n5,
   .mx-xl-n5 {
     margin-right: -4rem !important
   }
 
   .mb-xl-n5,
   .my-xl-n5 {
     margin-bottom: -4rem !important
   }
 
   .ml-xl-n5,
   .mx-xl-n5 {
     margin-left: -4rem !important
   }
 
   .m-xl-auto {
     margin: auto !important
   }
 
   .mt-xl-auto,
   .my-xl-auto {
     margin-top: auto !important
   }
 
   .mr-xl-auto,
   .mx-xl-auto {
     margin-right: auto !important
   }
 
   .mb-xl-auto,
   .my-xl-auto {
     margin-bottom: auto !important
   }
 
   .ml-xl-auto,
   .mx-xl-auto {
     margin-left: auto !important
   }
 
   .text-xl-left {
     text-align: left !important
   }
 
   .text-xl-right {
     text-align: right !important
   }
 
   .text-xl-center {
     text-align: center !important
   }
 }
 
 .text-lowercase {
   text-transform: lowercase !important
 }
 
 .text-uppercase {
   text-transform: uppercase !important
 }
 
 .text-capitalize {
   text-transform: capitalize !important
 }
 
 .font-weight-light {
   font-weight: 300 !important
 }
 
 .font-weight-lighter {
   font-weight: lighter !important
 }
 
 .font-weight-normal {
   font-weight: 400 !important
 }
 
 .font-weight-bold {
   font-weight: 700 !important
 }
 
 .font-weight-bolder {
   font-weight: bolder !important
 }
 
 .font-italic {
   font-style: italic !important
 }
 
 .text-white {
   color: #fff !important
 }
 
 .text-primary {
   color: #6967ce !important
 }
 
 a.text-primary:focus,
 a.text-primary:hover {
   color: #3b39b0 !important
 }
 
 .text-secondary {
   color: #6b6f80 !important
 }
 
 a.text-secondary:focus,
 a.text-secondary:hover {
   color: #484b56 !important
 }
 
 .text-success {
   color: #5ed84f !important
 }
 
 a.text-success:focus,
 a.text-success:hover {
   color: #37b328 !important
 }
 
 .text-info {
   color: #28afd0 !important
 }
 
 a.text-info:focus,
 a.text-info:hover {
   color: #1c7990 !important
 }
 
 .text-warning {
   color: #fdb901 !important
 }
 
 a.text-warning:focus,
 a.text-warning:hover {
   color: #b18101 !important
 }
 
 .text-danger {
   color: #fa626b !important
 }
 
 a.text-danger:focus,
 a.text-danger:hover {
   color: #f81825 !important
 }
 
 .text-light {
   color: #babfc7 !important
 }
 
 a.text-light:focus,
 a.text-light:hover {
   color: #9098a5 !important
 }
 
 .text-dark {
   color: #464855 !important
 }
 
 a.text-dark:focus,
 a.text-dark:hover {
   color: #23242b !important
 }
 
 .text-body,
 .text-muted {
   color: #6b6f80 !important
 }
 
 .text-black-50 {
   color: rgba(0, 0, 0, .5) !important
 }
 
 .text-white-50 {
   color: rgba(255, 255, 255, .5) !important
 }
 
 .text-hide {
   font: 0/0 a;
   color: transparent;
   border: 0;
   background-color: transparent
 }
 
 .text-decoration-none {
   text-decoration: none !important
 }
 
 .text-break {
   word-break: break-word !important;
   overflow-wrap: break-word !important
 }
 
 .text-reset {
   color: inherit !important
 }
 
 .visible {
   visibility: visible !important
 }
 
 .invisible {
   visibility: hidden !important
 }
 
 @media print {
 
   blockquote,
   img,
   pre,
   tr {
     page-break-inside: avoid
   }
 
   *,
   ::after,
   ::before {
     -webkit-box-shadow: none !important;
     box-shadow: none !important;
     text-shadow: none !important
   }
 
   a:not(.btn) {
     text-decoration: underline
   }
 
   abbr[title]::after {
     content: ' (' attr(title) ')'
   }
 
   pre {
     white-space: pre-wrap !important
   }
 
   blockquote,
   pre {
     border: 1px solid #adb5bd
   }
 
   thead {
     display: table-header-group
   }
 
   h2,
   h3,
   p {
     orphans: 3;
     widows: 3
   }
 
   h2,
   h3 {
     page-break-after: avoid
   }
 
   @page {
     size: a3
   }
 
   .container,
   body {
     min-width: 992px !important
   }
 
   .navbar {
     display: none
   }
 
   .badge {
     border: 1px solid #000
   }
 
   .table {
     border-collapse: collapse !important
   }
 
   .table td,
   .table th {
     background-color: #fff !important
   }
 
   .table-bordered td,
   .table-bordered th {
     border: 1px solid #626e82 !important
   }
 
   .table-dark {
     color: inherit
   }
 
   .table-dark tbody+tbody,
   .table-dark td,
   .table-dark th,
   .table-dark thead th {
     border-color: #626e82
   }
 
   .table .thead-dark th {
     color: inherit;
     border-color: #626e82
   }
 }