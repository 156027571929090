.table-container.sticky-header {
    overflow: auto;
    position: relative;
}

.table.sticky-header thead {
    position: sticky;
    top: 0;
    z-index: -500;
}
.search-calender{

}

/* style={{display: "flex", flexDirection: "column", alignItems: "center"}} */