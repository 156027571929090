@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/comfortaa/v40/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4TbMDrMfJQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/comfortaa/v40/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4WjMDrMfJQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/comfortaa/v40/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4Y_LDrMfJQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/muli/v28/7Aujp_0qiz-afTfcIyoiGtm2P0wG089z4eqVxg.ttf) format('truetype');
}
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/muli/v28/7Aujp_0qiz-afTfcIyoiGtm2P0wG05Fz4eqVxg.ttf) format('truetype');
}
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/muli/v28/7Aujp_0qiz-afTfcIyoiGtm2P0wG00904eqVxg.ttf) format('truetype');
}
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/muli/v28/7Aujp_0qiz-afTfcIyoiGtm2P0wG03Z04eqVxg.ttf) format('truetype');
}
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/muli/v28/7Aulp_0qiz-aVz7u3PJLcUMYOFmQkEk30e0.ttf) format('truetype');
}
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/muli/v28/7Aulp_0qiz-aVz7u3PJLcUMYOFnOkEk30e0.ttf) format('truetype');
}
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/muli/v28/7Aulp_0qiz-aVz7u3PJLcUMYOFkQl0k30e0.ttf) format('truetype');
}
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/muli/v28/7Aulp_0qiz-aVz7u3PJLcUMYOFkpl0k30e0.ttf) format('truetype');
}
