.input-black-placeholder::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1;
  /* Firefox */
}

.status {
  color: #fff;
  background-color: #5ed84f;
}

.status:hover {
  color: #fff;
}

.checkBox {
  margin-left: 10px;
}

.button-right {
  float: right;
}

.scroll-95 {
  max-height: 95vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-85 {
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-80 {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-75 {
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-70 {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-65 {
  max-height: 65vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-60 {
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: 'Select some files';
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}

.custom-file-input:hover::before {
  border-color: black;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.medical-table {
  width: 100%;
}

.medical-table td,
.medical-table th {
  border: 1px solid #000000;
  padding: 10px;
}

.width-1-per {
  width: 1%;
}

.width-2-per {
  width: 2%;
}

.width-3-per {
  width: 3%;
}

.width-4-per {
  width: 4%;
}

.bg-bee-pattern-blue {
  background-image: url("/src/assets/images/backgrounds/medical-healthcare-blue-color.webp");
  min-height: 94vh;
}

.bg-search-report-background {
  background-image: url("/src/assets/images/backgrounds/GCC-ERP-by-Multipixel-Technology.jpg");
  min-height: 94vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.btn-xss {
  max-height: 23px;
  min-width: 53px;
  font-size: 12px;
  padding: 1px 5px;
  margin: 1px 1px;
}

/* Treat all as divs */
.table-container {
  overflow: auto;
  max-height: 400px;
}

.table-container .table {
  border-collapse: collapse;
}

.table-container thead {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 0 !important;
}

.table-container tfoot {
  background-color: #f2f2f2;
  position: sticky;

  bottom: 0;
}

.table-container th,
td {
  padding: 2px 2px;
  text-align: left;
}

#invoice-template,
.invoice {
  color: black;
}

.invoice tr td {
  border: 0;
  padding-top: 2px;
  padding-bottom: 2px;
}

.bill-total tr td {
  border: 0;
  color: black;
}

.bill-footer tr td {
  border: 0;
  text-align: center;
}

.nav-link {
  font-family: Arial, serif;
  font-weight: bold;
}

.datatable-download table {
  width: 100%;
  margin: 5px;
  display: none;
  font-size: 9px;
}

.datatable-download table tbody tr td {
  padding: 1px 5px;
  border: 1px solid black;
  border-collapse: collapse;
}

.datatable-download table thead tr th {
  border: 1px solid black;
  border-collapse: collapse;
}

.scroll-report {
  overflow: auto;
  width: 100%;
}

#patient-report-print-content table tbody tr td {
  padding: 1px 5px;
  border: 1px solid black;
  border-collapse: collapse;
}

#patient-report-print-content thead tbody tr td {
  padding: 1px 5px;
  border: 1px solid black;
  border-collapse: collapse;
}

#patient-medical-report-print-content table tbody tr td {
  padding: 1px 5px;
  border: 1px solid black;
  border-collapse: collapse;
}

#patient-medical-report-print-content thead tbody tr td {
  padding: 1px 5px;
  border: 1px solid black;
  border-collapse: collapse;
}

@media print {


  .printable {
    margin: 2px;
  }

  .printable table {
    width: 99%;
    margin: 5px 5px;
  }

  .printable table tr td {
    padding: 0 5px;
  }

  .report-header table tr td {
    width: 20%;
    border: 0;
    padding: 2px 5px;
  }

  .datatable-download table {
    width: 100%;
    margin: 5px;
    display: block;
    font-size: 9px;
  }

  .datatable-download table tr td {
    padding: 1px 5px;
    border: 1px solid black;
    border-collapse: collapse;
  }

  .datatable-download table tr th {
    border: 1px solid black;
    border-collapse: collapse;
  }

  #invoice-template,
  .invoice {
    color: black;
  }

  .invoice tr td {
    border: 0;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .bill-total tr td {
    border: 0;
    color: black;
  }

  .bill-footer tr td {
    border: 0;
    text-align: center;
  }
}


/* table */
.mr-05 {
  padding-right: 4px;
}

.medicalInput:focus {
  outline: none;
  border: none !important;
}

@media (max-width: 1700px) {
  .responsiveTable {
    overflow-x: auto;
  }


  .responsiveTable .table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }



}

/* user role */
.rolegap {
  margin-right: 5px;
}


/* medical report */
.text-black-deep {
  color: #000000 !important;
}

.text-bolder {
  font-weight: bolder !important;
}

.text-fs-small {
  font-size: 13px !important;
}

.text-fs-medium {
  font-size: 14px !important;
  font-weight: 700;
}

.w-auto {
  width: auto;
}

/* Combined Report */
.lab-report {
  margin: 5px;
}

.lab-report>table {
  width: 100%;
  border-collapse: collapse;
}

.lab-report>table>thead>tr {
  /* font-weight: bold; */
  color: rgb(231, 209, 209);
}

.lab-report>table>thead>tr>th {
  border: 1px solid black;
  text-align: center;
}

.lab-report>table>tbody>tr {
  text-align: center;
  color: #1d1b1b;
}

.lab-report>table>tbody>tr>td {
  border: 1px solid black;
  text-align: center;
}


.signature-container {
  margin-top: 90px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}

.custom-hr {
  color: black;
  background-color: black;
  border-color: black;
}

.search-box {
  display: flex;
  align-items: center;
}

.search-input {
  flex: 1;
  font-size: 1rem;
  line-height: 1.25;
  height: calc(2.75rem + 2px);
  padding: 0.75rem 1.5rem;
  border: 1px solid #babfc7;
  border-radius: 0.25rem;
  background-color: #fff;
  background-clip: padding-box;
  margin-right: 10px;
}

.search-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 0.25rem;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.search-button:hover {
  background-color: #0056b3;
}

.pdf-content {
  height: 100%;
}
.col-md-10-per{
  width: 10%;
  margin-right: 5px;
}
.col-md-15-per{
  width: 15%;
  margin-right: 5px;
}